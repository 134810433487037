import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faKey, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import Submenu from '../../components/submenu.js';

const localization = {
    ua: {
        title: 'Loyalty - Settings',
        submenu: {
            dashboard: 'Dashboard',
            leads: 'Leads',
            settings: 'Settings',
        },
    },
    ru: {
        title: 'Loyalty - Settings',
        submenu: {
            dashboard: 'Dashboard',
            leads: 'Leads',
            settings: 'Settings',
        },
    },
    en: {
        title: 'Loyalty - Settings',
        submenu: {
            dashboard: 'Dashboard',
            leads: 'Leads',
            settings: 'Settings',
        },
    },
}

class Settings extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            redirect: null,

            user: null
        }
    }

    componentDidMount() {
        const {lang, setNavigationTitle, unitID} = this.context;
        setNavigationTitle(localization[lang].title);

        this.setState({isLoading: false, user: "User - " + unitID})
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                
                <Submenu>
                    <div style={{backgroundColor: '#fff'}}>
                        <div className="d-flex align-items-center justify-content-between" style={{minHeight: '50px'}}>
                            <div>
                                <NavLink to={"/crm/loyalty/dashboard"} className="nav-agis-light-outline ml-4 mr-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.dashboard}</NavLink>
                                <NavLink to={"/crm/loyalty/leads"} className="nav-agis-light-outline ml-4 mr-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.leads}</NavLink>
                                <NavLink to={"/crm/loyalty/settings"} className="nav-agis-light-outline ml-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.settings}</NavLink>
                            </div>
                        </div>
                    </div>
                </Submenu>

                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-4 offset-1">
                            <div className="card-agis pt-2 pb-2">
                                <h6 className="text-muted font-weight-light mb-1">Відповідальні особи</h6>
                                <div className="row mb-2">
                                    <div className="col-7">
                                        <small class="form-text text-muted pl-2">П.І.Б. / Представник</small>
                                        <input type="text" class="question-input" value={this.state.user} onChange={this.changeRepresentative} />
                                    </div>
                                    <div className="col-5">
                                        <small class="form-text text-muted pl-1">Телефон</small>
                                        <input type="text" class="question-input" value={this.state.phone} onChange={this.changePhone} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <a href={"/public/crm/maintenance"}><small className="">Додати особу</small></a>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button className="question-button" style={{width: "100px"}}>Зберегти</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Settings;
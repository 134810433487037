import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faCog, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';

const localization = {
    ua: {
        title: 'User settings',
    },
    ru: {
        title: 'User settings',
    },
    en: {
        title: 'User settings',
    },
}

class Index extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
        }
    }

    componentDidMount() {
        const {lang, setNavigationTitle} = this.context;
        setNavigationTitle(localization[lang].title);

        this.init();
    }

    init() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/iam/units/list', {}, {
                headers: { Authorization: `Bearer ${token}` }
            }).then((res) => {
                this.setState({
                    isLoading: false,
                    units: res.data,
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                units: [{unitID: 1, name: "SCANIA Kiev"}],
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />

                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-5 offset-1">
                            <div className="card-agis pt-2 pb-2 mb-4">
                                <h6 className="text-muted font-weight-light mb-1">Інформація про користувача</h6>
                                <div className="row mb-2">
                                    <div className="col-7">
                                        <small class="form-text text-muted pl-2">П.І.Б. / Представник</small>
                                        <input type="text" class="question-input" value={this.state.user} onChange={this.changeRepresentative} />
                                    </div>
                                    <div className="col-5">
                                        <small class="form-text text-muted pl-1">Телефон</small>
                                        <input type="text" class="question-input" value={this.state.phone} onChange={this.changePhone} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <a href={"/public/crm/maintenance"}><small className="">Додати особу</small></a>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button className="question-button" style={{width: "100px"}}>Зберегти</button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-agis pt-2 pb-2">
                                <h6 className="text-muted font-weight-light mb-1">Зміна паролю</h6>
                                <div className="row mb-2">
                                    <div className="col-6">
                                        <small class="form-text text-muted pl-2">Старий пароль</small>
                                        <input type="password" class="question-input" value={this.state.user} onChange={this.changeRepresentative} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-6">
                                        <small class="form-text text-muted pl-2">Новий пароль</small>
                                        <input type="password" class="question-input" value={this.state.user} onChange={this.changeRepresentative} />
                                    </div>
                                    <div className="col-6">
                                        <small class="form-text text-muted pl-2">Новий пароль ще раз</small>
                                        <input type="password" class="question-input" value={this.state.user} onChange={this.changeRepresentative} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-right">
                                        <button className="question-button" style={{width: "100px"}}>Зберегти</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 offset-1">
                            <div className="card-agis pt-2 pb-2">
                                <h6 className="text-muted font-weight-light mb-1">Права доступу</h6>
                                <div className="row mb-2">
                                    <div className="col-7">
                                        <small class="form-text text-muted pl-2">П.І.Б. / Представник</small>
                                        <input type="text" class="question-input" value={this.state.user} onChange={this.changeRepresentative} />
                                    </div>
                                    <div className="col-5">
                                        <small class="form-text text-muted pl-1">Телефон</small>
                                        <input type="text" class="question-input" value={this.state.phone} onChange={this.changePhone} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <a href={"/public/crm/maintenance"}><small className="">Додати особу</small></a>
                                    </div>
                                    <div className="col-6 text-right">
                                        <button className="question-button" style={{width: "100px"}}>Зберегти</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Index;
import React from 'react';
import { Link, NavLink } from 'react-router-dom'
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCog, faCopy, faUpload, faDownload, faFileMedical, faChevronDown, faBuilding, faSuitcase, faGamepad } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext';

const localization = {
    ua: {
        title: "Логістика грузової техніки",
        myActs: "Мої акти",
        registry: "Реєстр",
        settings: "Налаштування",
    },
    ru: {
        title: "Логистика грузовой техники",
        myActs: "Мои акты",
        registry: "Реестр",
        settings: "Настройки",
    },
    pl: {
        title: "Logistyka samochodów ciężarowych",
        myActs: "Moje akty",
        registry: "Rejestr",
        settings: "Ustawienia",
    },
    en: {
        title: "Trucks logistics",
        myActs: "My acts",
        registry: "Registry",
        settings: "Settings",
    },
}

class Navigation extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: false,
        }
    }

    componentDidMount() {
    }

    render() {
        const {lang, username, scale, navigationTitle} = this.context;

        if (this.state.isLoading) {
            return <div></div>
        }

        return (
            <div style={{height: "100%"}}>

                <div className="d-flex justify-content-start" style={{height: '50px', lineHeight: '50px', backgroundColor: '#fff', borderTop: "1px solid rgba(0,0,0,.0)"}}>
                        <Link to={"/"} className="pl-3 pr-3" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            cursor: "pointer",
                            fontSize: "14px",
                            lineHeight: "48px",
                            color: "#5f6368",
                            backgroundColor: "white", 
                        }}>
                            <svg focusable="false" viewBox="0 0 24 24" style={{height:"26px", color: "#5f6368"}}><path fill="currentColor" d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path></svg>
                        </Link>
                        <div className="d-flex justify-content-center" style={{fontSize: "16px", fontWeight: "400"}}>
                            {navigationTitle}
                        </div>
                        {/* <Link to={"/"} className="pl-3 pr-3" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            cursor: "pointer",
                            fontSize: "14px",
                            lineHeight: "48px",
                            color: "#5f6368",
                            backgroundColor: "white", 
                        }}>
                            <svg focusable="false" viewBox="0 0 24 24" style={{height:"26px", color: "#5f6368"}}><path fill="currentColor" d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path></svg>
                        </Link> */}
                </div>

                <div style={{
                    height: "10px",
                }}></div>

                {this.props.children}

                <div style={{
                    height: "30px",
                }}></div>

                {/* <div style={{position: "fixed", bottom: "20px", right: "5px", height: '50px', width: "50px", backgroundColor: '#fff'}}>
                    <div className="d-flex align-items-center justify-content-center">
                        <Link to={"/"} className="" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            cursor: "pointer",
                            fontSize: "14px",
                            lineHeight: "48px",
                            color: "#5f6368",
                            backgroundColor: "white", 
                        }}>
                            <svg focusable="false" viewBox="0 0 24 24" style={{height:"26px", color: "#5f6368"}}><path fill="currentColor" d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path></svg>
                        </Link>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default Navigation;
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBox, faCar, faChevronLeft, faChevronRight, faClock, faCog, faComment, faEnvelope, faHammer, faMinus, faPhone, faPlus, faSearch, faThumbsDown, faThumbsUp, faUserEdit, faWheelchair, faWrench } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext.js';

import { intToDateTimeFormat, intToISODate } from '../../utils/datetime.js';

import CounterpartyPicker from '../components/counterpartyPicker.js';
import VehiclePicker from '../components/vehiclePicker.js';

import Preview from '../components/preview.js';

const localization = {
    ua: {
        title: 'Вхідний дзвінок',
    },
    ru: {
        title: 'Входящий звонок',
    },
    en: {
        title: 'Incoming call',
    },
}

class Index extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            isCounterpartyLoading: true,
            isVehicleLoading: true,
            isWorkOrdersLoading: true,
            isWorkOrderLoading: true,

            /*
                Lead
            */
            leadID:             null,
            externalID:         null,
            unitID:             null,
            created:            null,
            typeID:             null,
            typeName:           null,
            offerURL:           null,
            description:        null,
            counterpartyID:     null,
            name:               null,
            phone:              null,
            vehicleID:          null,
            manufacturer:       null,
            model:              null,
            registrationNumber: null,
            smsSent:            null,
            emailSent:          null,
            closed:             null,
            resultID:           null,

            /*
                Counterparty
            */
            company:               null,
            name:                  null,
            nameFull:              null,
            stateRegistrationCode: null,
            phone:                 null,
            phoneAlternative:      null,
            email:                 null,
            address:               null,

            /*
                Vehicle
            */
            vin:                null,
            serialNumber:       null,
            manufacturer:       null,
            model:              null,
            year:               null,
            mileage:            null,
            registrationNumber: null,

            /*
                History
            */
            loyalty: [],
            recommendations: [],
            workOrders: [],
            calls: [],

            /*
                Work order
            */
            workOrderID: null,
            workOrderPlannedDate: Math.floor(Date.now() / 1000),
            workOrderJobs: [],

            /*
                Call
            */
            isCreateCallOpened: false,
            callDate:           Math.floor(Date.now() / 1000),
            callRemark:         "",

            /*
                Lead results
            */
            results: [],
        }
    }

    componentDidMount() {
        const {lang, setNavigationTitle} = this.context;
        setNavigationTitle(localization[lang].title);

        this.init();
    }

    init() {
        const {lang, token, addNotification, removeNotification} = this.context;

        this.setState({
            isLoading: false,

            // Lead
            leadID: 1,
            externalID: "cc7788de-c93e-11eb-becc-6e6b2cbec74b",
            unitID: 2,
            created: 1,

            typeID: 1,
            typeName: "Нагадування про ТО",
            offerURL: "https://agis.yume-honda.com.ua/public/service/lead/" + this.state.externalID,
            description: null,

            counterpartyID: 1,
            name: "Бутенко Евгений Олегович",
            phone: "380508673516",

            vehicleID: 1,
            manufacturer: "Honda",
            model: "Accord",
            registrationNumber: "AA2231KA",

            smsSent: 1624277372,
            emailSent: null,
            closed: 1,
            resultID: null,

            workOrderID: null,
            workOrderPlannedDate: null,

            // Calls
            calls: [
                {
                    callID: 1,
                    created: 1638352800,
                    planned: 1638352800,
                    done: 1,
                    remark: "Какой-то не уверенный клиент. Скорее всего не запишется.",
                },
                {
                    callID: 2,
                    created: 1638871200,
                    planned: 1638871200,
                    done: null,
                    remark: null,
                },
            ],

            results: [
                {
                    resultID: 100,
                    item: 1,
                    name: "Запис на сервіс",
                    active: true,
                },
                {
                    resultID: 200,
                    item: 1,
                    name: "Продав авто",
                    active: true,
                },
                {
                    resultID: 201,
                    item: 2,
                    name: "Неприйнятлива ціна",
                    active: true,
                },
                {
                    resultID: 202,
                    item: 3,
                    name: "Клієнт іншого дилера",
                    active: true,
                },
                {
                    resultID: 203,
                    item: 4,
                    name: "Причина невідома",
                    active: true,
                },
                {
                    resultID: 204,
                    item: 5,
                    name: "Клієнт відмовив онлайн",
                    active: true,
                },
                {
                    resultID: 300,
                    item: 1,
                    name: "Зателефонувати пізніше",
                    active: true,
                },
            ],

            // Counterparty
            company: false,
            name: "Бутенко Евгений Олегович",
            nameFull: "Бутенко Евгений Олегович",
            stateRegistrationCode: null,
            phone: "380508673516",
            phoneAlternative: null,
            email: "evgeniybutenko@gmail.com",
            address: "м.Київ, вул. Луценка Дмитра, 15, блок 17, кім. 15",

            // Vehicle
            vin: "12345678901234567",
            serialNumber: "FG45120",
            manufacturer: "Honda",
            model: "Accord",
            year: 2018,
            mileage: 59750,
            registrationNumber: "AA2231KA",

            // Recommendations
            recommendations: [
                {
                    id: 1,
                    name: "ТЕСТ. Швидко закінчився кондиціонер",
                    description: "Підчас попередньої заправки кондиціонера, разом с фріоном, була заправлена маркерна фарба для виявлення пошкоджень в системі кондиціювання. Необхідно дослідити систему на факт протікання.",
                    files: ["https://howcarworks.ru/sites/default/files/field/image/2015-04-06_222959.jpg"]
                },
            ],

            // Work orders
            workOrders: [
                {
                    unitID: 1,
                    workOrderID: 499,
                    created: 1632155107,
                    plannedDate: 1598360100,
                    leadID: null,
                    counterpartyID: 32,
                    vehicleID: 1020,
                    jobs: [
                        {
                            workOrderJobID: 1,
                            name: "ТО 45000",
                            labours: [
                                {
                                    workOrderLabourID: 12168,
                                    code: "YM000240",
                                    name: "Мастило ДВЗ. Заміна",
                                    duration: 30,
                                },
                            ],
                            parts: [
                                {
                                    workOrderPartID: 11370,
                                    code: "08232-P99C4LHE",
                                    name: "ОЛИВА МОТОРНА HONDA HFS-E 5W-30, 4Л",
                                    quantity: 4,
                                },
                            ],
                        },
                        {
                            workOrderJobID: 2,
                            name: "Заправка кондиціонера",
                            labours: [
                                {
                                    workOrderLabourID: 12168,
                                    code: "YM000240",
                                    name: "Мастило ДВЗ. Заміна",
                                    duration: 30,
                                },
                            ],
                            parts: [
                                {
                                    workOrderPartID: 11370,
                                    code: "08232-P99C4LHE",
                                    name: "ОЛИВА МОТОРНА HONDA HFS-E 5W-30, 4Л",
                                    quantity: 4,
                                },
                            ],
                        },
                    ]
                },
                {
                    unitID: 1,
                    workOrderID: 499,
                    created: 1632155107,
                    plannedDate: 1598360100,
                    leadID: null,
                    counterpartyID: 32,
                    vehicleID: 1020,
                    jobs: [
                        {
                            workOrderJobID: 1,
                            name: "ТО 45000",
                            parts: [
                                {
                                    workOrderPartID: 11370,
                                    code: "08232-P99C4LHE",
                                    name: "ОЛИВА МОТОРНА HONDA HFS-E 5W-30, 4Л",
                                    quantity: 4,
                                },
                            ],
                            labours: [
                                {
                                    workOrderLabourID: 12168,
                                    code: "YM000240",
                                    name: "Мастило ДВЗ. Заміна",
                                    duration: 30,
                                },
                            ]
                        },
                        {
                            workOrderJobID: 2,
                            name: "Заправка кондиціонера",
                        },
                    ]
                },
            ],

            loyalty: [
                {
                    id: 1,
                    nps: 3,
                    csi: 2,
                    remark: "Дуже гірка кава",
                }
            ]
        });
    }

    /*
        Recommendations
    */
    renderRecommendations() {
        const {lang} = this.context;

        return this.state.recommendations.map((item, i) => {
            return (
                <div className="row mb-1" key={"row_" + item.id}>
                    <div className="col">
                        <div className="d-flex justify-content-between">
                            <span><b>{item.name}</b></span>
                            <small className="text-muted">20.05.2021</small>
                        </div>
                        <span>{item.description}</span>
                        <div className="row">
                            {item.files.map((file, i) => {
                                return (
                                    <div className="col-3">
                                        <Preview src={file} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            );
        });
    }

    renderWorkOrders() {
        const {lang, datetime} = this.context;

        return this.state.workOrders.map((item) => {
            return (
                <div className="row mb-3" key={"row_" + item.id}>
                    <div className="col">
                        <div className="d-flex justify-content-between">
                            <div>
                                {item.jobs && item.jobs.map((job) => {
                                    return (
                                        <div>
                                            <p className="mb-0" key={"job_"+job.workOrderJobID}><b>{job.name}</b></p>
                                            {job.labours && job.labours.map((labour) => {
                                                return (
                                                    <p className="mb-0" key={"labour_"+labour.workOrderLabourID}><FontAwesomeIcon icon={faWrench} style={{color: "#adb5bd"}} /> {labour.name}</p>
                                                )
                                            })}
                                            {job.parts && job.parts.map((part) => {
                                                return (
                                                    <p className="mb-0" key={"part_"+part.workOrderPartID}><FontAwesomeIcon icon={faCog} style={{color: "#adb5bd"}} /> {part.name + " (" + part.code + ")"}</p>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                            <small className="text-muted">{intToDateTimeFormat(item.plannedDate, datetime).split(' ')[0]}</small>
                        </div>
                    </div>
                </div>
            );
        });
    }

    /*
        Loyalty
    */
    renderLoyalty() {
        const {lang} = this.context;

        return this.state.loyalty.map((item, i) => {
            return (
                <div className="row mb-1" key={"row_" + item.id}>
                    <div className="col">
                        <div className="d-flex justify-content-between">
                            <span className="w-100">NPS - <b>{item.nps}</b>, CSI - <b>{item.csi}</b> <span className="ml-3">{item.remark}</span></span>
                            <small className="text-muted">20.05.2021</small>
                        </div>
                    </div>
                </div>
            );
        });
    }

    /*
        Calls
    */
    renderCalls() {
        const {lang, datetime} = this.context;

        return this.state.calls.sort((a, b) => {
            return (b.done != null ? b.done : b.planned) - (a.done != null ? a.done : a.planned);
        }).reverse().map((item, i) => {
            return <Call
                key={"call_id_" + item.callID}
                data={item}
                results={this.state.results}

                callDate={this.state.callDate}
                callRemark={this.state.callRemark}
                callDateChange={this.callDateChange}
                callDateSet={this.callDateSet}
                callRemarkChange={this.callRemarkChange}
                callCreate={this.callCreate}

                leadClose={this.leadClose}
            />
        });
    }

    render() {
        const {lang, datetime} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />

                <div className="container-fluid mb-4">
                    <div className="row mb-3">
                        <div className="col-md-5 offset-md-1">
                            <div class="">
                                 <h1 style={{fontSize: "2rem", color: "#868e96"}}>Вхідний дзвінок</h1>
                                 <input type="text" autoComplete={false} className="transparent-input text-muted mt-2" style={{width: "100%", fontSize: "1rem"}} placeholder="Від +380 (50) 867 35 16" disabled={true} />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-5 offset-md-1">
                            <div className="card-agis pt-2 pb-2">
                                <div className="d-flex align-items-top justify-content-between mb-2">
                                    <h5 className="text-muted font-weight-light">Контрагент</h5>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-6">
                                        <small class="form-text text-muted pl-2">Назва компанії / П.І.Б.</small>
                                        <input type="text" autocomplete="off" class="question-input" value={this.state.name} disabled={true} />
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-12">
                                        <small class="form-text text-muted pl-2">Адреса</small>
                                        <textarea rows="1" autocomplete="off" class="question-input" style={{resize: "none"}} value={this.state.address} disabled={true} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-3">
                                        <small class="form-text text-muted pl-1">Телефон</small>
                                        <input type="text" autocomplete="off" class="question-input" value={this.state.phone} onChange={this.changePhone} disabled={true} />
                                    </div>
                                    <div className="col-3">
                                        <small class="form-text text-muted pl-1">Телефон</small>
                                        <input type="text" autocomplete="off" class="question-input" value={this.state.phoneAlternative} onChange={this.changePhoneAlternative} disabled={true} />
                                    </div>
                                    <div className="col-6">
                                        <small class="form-text text-muted pl-1">Електронна адреса</small>
                                        <input type="text" autocomplete="off" class="question-input" value={this.state.email} onChange={this.changeEmail} disabled={true} />
                                    </div>
                                </div>

                                {this.state.loyalty.length !== 0 && (
                                    <div>
                                        <hr className="w-100 mt-3 mb-2"/>
                                        <h6 className="text-muted font-weight-light mb-1">Попередні відгуки</h6>
                                        {this.renderLoyalty()}
                                    </div>
                                )}
                            </div>

                            <br/>

                            <div className="card-agis pt-2 pb-2">
                                <div className="d-flex align-items-top justify-content-between mb-2">
                                    <h5 className="text-muted font-weight-light">Авто</h5>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-3">
                                        <small class="form-text text-muted pl-1">Реєстраційний номер</small>
                                        <input type="test" class="question-input" value={this.state.registrationNumber} disabled={true} />
                                    </div>
                                    <div className="col-3">
                                        <small class="form-text text-muted pl-1">Марка</small>
                                        <input type="text" class="question-input" value={this.state.manufacturer} disabled={true} />
                                    </div>
                                    <div className="col-3">
                                        <small class="form-text text-muted pl-1">Модель</small>
                                        <input type="text" class="question-input" value={this.state.model} disabled={true} />
                                    </div>
                                    <div className="col-3">
                                        <small class="form-text text-muted pl-1">Рік випуску</small>
                                        <input type="number" step="1" min="1980" class="question-input" value={this.state.year} disabled={true} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <small class="form-text text-muted pl-1">Пробіг</small>
                                        <input type="number" step="1" min="0" class="question-input" value={this.state.mileage} onChange={this.vehicleMileageChange} onBlur={this.vehicleMileageBlur} onKeyPress={this.vehicleMileageKeyPress} disabled={true} />
                                    </div>
                                    <div className="col-6">
                                        <small class="form-text text-muted pl-1">VIN</small>
                                        <input type="text" class="question-input" value={this.state.vin} disabled={true} />
                                    </div>
                                    <div className="col-3">
                                        <small class="form-text text-muted pl-1">Серійний номер</small>
                                        <input type="text" class="question-input" value={this.state.serialNumber} disabled={true} />
                                    </div>
                                </div>
                                
                                {this.state.recommendations.length !== 0 && (
                                    <div>
                                        <hr className="w-100 mt-3 mb-2"/>
                                        <h6 className="text-muted font-weight-light mb-1">Рекомендації по авто</h6>
                                        {this.renderRecommendations()}
                                    </div>
                                )}

                                {this.state.workOrders.length !== 0 && (
                                    <div>
                                        <hr className="w-100 mt-3 mb-2"/>
                                        <h6 className="text-muted font-weight-light mb-1">Попередні візити авто</h6>
                                        {this.renderWorkOrders()}
                                    </div>
                                )}
                            </div>
                        </div>
                    
                        <div className="col-md-4 offset-md-1">
                            <div className="card-agis pt-2 pb-2 mb-4">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="text-muted font-weight-light mb-1">Вілкриті ліди</h5>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div style={{width: "38px"}}>
                                        <FontAwesomeIcon icon={faWrench} style={{fontSize: "24px", color: "#c1c1c1"}} />
                                    </div>
                                    <div className="w-100">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span><b>Нагадування про ТО</b></span>
                                            <small className="">{intToDateTimeFormat(1640083228, datetime)}</small>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>Honda Accord 2018, AA2231KA</span>
                                            <a href={"/public/service/lead/?id=" + this.state.leadID} target="_blank"><small className="">Перейти</small></a>
                                        </div>
                                    </div>
                                </div>

                                <hr className="w-100 mt-2 mb-2"/>

                                <div className="d-flex align-items-center justify-content-between">
                                    <div style={{width: "38px"}}>
                                        <FontAwesomeIcon icon={faCog} style={{fontSize: "24px", color: "#c1c1c1"}} />
                                    </div>
                                    <div className="w-100">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span><b>Купівля запчастин</b></span>
                                            <small className="">{intToDateTimeFormat(1640083228, datetime)}</small>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>4 одиниці, 6789 грн</span>
                                            <a href={"/public/service/lead/?id=" + this.state.leadID} target="_blank"><small className="">Перейти</small></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br/>
                             
                            <div className="card-agis pt-2 pb-2">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="text-muted font-weight-light mb-1">Новий лід</h5>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div style={{width: "38px"}}>
                                        <FontAwesomeIcon icon={faWrench} style={{fontSize: "24px", color: "#c1c1c1"}} />
                                    </div>
                                    <div className="w-100">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span><b>Нагадування про ТО</b></span>
                                            <a href={"/public/service/lead/?id=" + this.state.leadID} target="_blank"><small className="">Створити</small></a>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>. . .</span>
                                        </div>
                                    </div>
                                </div>

                                <hr className="w-100 mt-2 mb-2"/>

                                <div className="d-flex align-items-center justify-content-between">
                                    <div style={{width: "38px"}}>
                                        <FontAwesomeIcon icon={faCog} style={{fontSize: "24px", color: "#c1c1c1"}} />
                                    </div>
                                    <div className="w-100">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span><b>Купівля запчастин</b></span>
                                            <a href={"/public/service/lead/?id=" + this.state.leadID} target="_blank"><small className="">Створити</small></a>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>. . .</span>
                                        </div>
                                    </div>
                                </div>

                                <hr className="w-100 mt-2 mb-2"/>

                                <div className="d-flex align-items-center justify-content-between">
                                    <div style={{width: "38px"}}>
                                        <FontAwesomeIcon icon={faCar} style={{fontSize: "24px", color: "#c1c1c1"}} />
                                    </div>
                                    <div className="w-100">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span><b>Тест драйв</b></span>
                                            <a href={"/public/service/lead/?id=" + this.state.leadID} target="_blank"><small className="">Створити</small></a>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span>. . .</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br/>
                            
                            {/* {this.renderCalls()} */}

                            {/* {!this.state.resultID && (
                                !this.state.isCreateCallOpened ? (
                                    <div className="w-100">
                                        <button className="question-button w-100" style={{height: "40px", maxWidth: "100%", fontSize: "18px", fontWeight: "300", backgroundColor: "#f3f3f3"}} onClick={() => this.setState({isCreateCallOpened: true})}>
                                            <svg style={{marginRight: "5px", fontSize: "18px", color: "#a0a0a0"}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-phone-plus fa-w-16 fa-2x"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4zM16 144h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16h-64V16c0-8.8-7.2-16-16-16H96c-8.8 0-16 7.2-16 16v64H16C7.2 80 0 87.2 0 96v32c0 8.8 7.2 16 16 16z" class=""></path></svg>
                                            Запланувати дзвінок
                                        </button>
                                    </div>
                                ) : (
                                <div className="card-agis pt-2 pb-2 mb-4">
                                    <div className="d-flex align-items-start justify-content-between">
                                        <div style={{width: "38px"}}>
                                            <svg style={{fontSize: "24px", color: "#f5bd79"}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-phone-plus fa-w-16 fa-2x"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4zM16 144h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16h-64V16c0-8.8-7.2-16-16-16H96c-8.8 0-16 7.2-16 16v64H16C7.2 80 0 87.2 0 96v32c0 8.8 7.2 16 16 16z" class=""></path></svg>
                                        </div>
                                        <div className="w-100">
                                            <span><b>Запланувати дзвінок</b></span>
                                            <div className="row mb-2">
                                                <div className="col-6">
                                                    <small class="form-text text-muted pl-1">Планована дата дзвінку</small>
                                                    <input type="datetime-local" className="input-agis-block w-100" style={{fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={intToDateTimeFormat(this.state.callDate, "year-month-dayThour:minute")} onChange={this.callDateChange} />
                                                </div>
                                                <div className="col-6">
                                                    <small class="form-text text-muted pl-1">Коментар</small>
                                                    <input type="text" class="question-input" value={this.state.callRemark} onChange={this.callRemarkChange} />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="w-75">
                                                    <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.callDateSet(900)}>За 15 хв</button>
                                                    <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.callDateSet(3600)}>За 1 год</button>
                                                    <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.callDateSet(86400)}>Завтра</button>
                                                    <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.callDateSet(259200)}>За 3 дні</button>
                                                    <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.callDateSet(604800)}>За тиждень</button>
                                                    <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.callDateSet(1209600)}>За 2 тижні</button>
                                                    <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.callDateSet(2419200)}>За місяць</button>
                                                </div>
                                                <button className="question-button" onClick={() => this.callCreate(this.state.callDate, this.state.callRemark)}>Створти</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))} */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class Call extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            resultView: null,
            resultSubView: null,

            callID: this.props.data.callID,
            created: this.props.data.created,
            planned: this.props.data.planned,
            done: this.props.data.done,
            remark: this.props.data.remark,
        }

        this.callUpdate = this.callUpdate.bind(this);
    }

    callUpdate(resultID, callback) {
        const {lang, token, addNotification, removeNotification} = this.context;

        var remark = (resultID < 200 ? "" : resultID >= 200 && resultID < 300 ? "Невдача. " : "Без результату. ") + this.props.results.find(x => x.resultID == resultID).name + ".";

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/service/lead/call/update', { 
                callID: this.state.callID,
                remark: remark,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then((res) => {
                this.setState({
                    done: Math.floor(Date.now() / 1000),
                    remark: remark,
                }, () => {
                    callback(resultID);
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                done: Math.floor(Date.now() / 1000),
                remark: remark,
            }, () => {
                callback(resultID);
            });
        }
    }

    render() {
        const {lang, datetime} = this.context;

        if (this.state.done) {
            return (
                <div className="card-agis pt-2 pb-2 mb-4">
                    <div className="d-flex align-items-start justify-content-between">
                        <div style={{width: "38px"}}>
                            <FontAwesomeIcon icon={faPhone} style={{fontSize: "24px", color: "#c1c1c1"}} />
                        </div>
                        <div className="w-100">
                            <div className="d-flex align-items-center justify-content-between">
                                <span><b>Проведений дзвінок</b></span>
                                <small className="">{intToDateTimeFormat(this.state.done, datetime)}</small>
                            </div>
                            {this.state.remark && (
                                <span>{this.state.remark}</span>
                            )}
                        </div>
                    </div>
                </div>
            );
        } 

        return (
            <div className="card-agis pt-2 pb-2 mb-4">
                <div className="d-flex align-items-start justify-content-between">
                    <div style={{width: "38px"}}>
                        <FontAwesomeIcon icon={faClock} style={{fontSize: "24px", color: "#f5bd79"}} />
                    </div>
                    <div className="w-100">
                        <div className="d-flex justify-content-between">
                            <span><b>Запланований дзвінок</b></span>
                            <small className="">{intToDateTimeFormat(this.state.planned, datetime)}</small>
                        </div>
                        {this.state.remark && (
                            <span>{this.state.remark}</span>
                        )}

                        <small class="form-text text-muted">Результат дзвінку</small>
                        <button className="question-button w-25 mr-2" style={this.state.resultView == "success" ? {backgroundColor: "#dcdcdc"} : {}} onClick={() => this.setState({resultView: "success", resultSubView: null})}>Успіх</button>
                        <button className="question-button w-25 mr-2" style={this.state.resultView == "fail" ? {backgroundColor: "#dcdcdc"} : {}} onClick={() => this.setState({resultView: "fail", resultSubView: null})}>Невдача</button>
                        <button className="question-button w-25" style={this.state.resultView == "delay" ? {backgroundColor: "#dcdcdc"} : {}} onClick={() => this.setState({resultView: "delay", resultSubView: null})}>Без результату</button>

                        {this.state.resultView == "success" && (
                            <div className="mt-2">
                                <div className="row">
                                    <div className="col">
                                        <small class="form-text text-muted pl-1">Підтверддення</small>
                                        <button className="question-button mr-2" style={{maxWidth: "400px"}} onClick={() => this.callUpdate(100, () => this.props.leadClose(100))}>Підтвердити та закрити лід</button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.resultView == "fail" && (
                            <div className="mt-2">
                                <div className="row">
                                    <div className="col">
                                        <small class="form-text text-muted pl-1">Причина відмови</small>
                                        <button className="question-button mr-2 mb-2" onClick={() => this.callUpdate(200, () => this.props.leadClose(200))}>Клієнт продав авто</button>
                                        <button className="question-button mr-2" onClick={() => this.callUpdate(201, () => this.props.leadClose(201))}>Неприйнятлива ціна</button>
                                        <button className="question-button mr-2" onClick={() => this.callUpdate(202, () => this.props.leadClose(202))}>Клієнт іншого дилера</button>
                                        <button className="question-button mr-2" onClick={() => this.callUpdate(203, () => this.props.leadClose(203))}>Причина невідома</button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.resultView == "delay" && (
                            <div className="mt-2">
                                <div className="row">
                                    <div className="col">
                                        <small class="form-text text-muted pl-1">Причина</small>
                                        <button className="question-button mr-2" style={this.state.resultSubView == "delayCallLater" ? {backgroundColor: "#dcdcdc"} : {}} onClick={() => this.setState({resultView: "delay", resultSubView: "delayCallLater"})}>Зателефонувати пізніше</button>
                                        <button className="question-button mr-2" onClick={() => this.callUpdate(301, () => {})}>Відмінити нагадування</button>
                                    </div>
                                </div>
                                {this.state.resultSubView == "delayCallLater" && (
                                    <div>
                                        <div className="row mt-1 mb-2">
                                            <div className="col-6">
                                                <small class="form-text text-muted pl-1">Планована дата дзвінку</small>
                                                <input type="datetime-local" className="input-agis-block w-100" style={{fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={intToDateTimeFormat(this.props.callDate, "year-month-dayThour:minute")} onChange={this.props.callDateChange} />
                                            </div>
                                            <div className="col-6">
                                                <small class="form-text text-muted pl-1">Коментар</small>
                                                <input type="text" class="question-input" value={this.props.callRemark} onChange={this.props.callRemarkChange} />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div className="w-75">
                                                <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.props.callDateSet(900)}>За 15 хв</button>
                                                <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.props.callDateSet(3600)}>За 1 год</button>
                                                <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.props.callDateSet(86400)}>Завтра</button>
                                                <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.props.callDateSet(259200)}>За 3 дні</button>
                                                <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.props.callDateSet(604800)}>За тиждень</button>
                                                <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.props.callDateSet(1209600)}>За 2 тижні</button>
                                                <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.props.callDateSet(2419200)}>За місяць</button>
                                            </div>
                                            <button className="question-button" onClick={() => this.callUpdate(300, () => this.props.callCreate(this.props.callDate, this.props.callRemark))}>Відкласти</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
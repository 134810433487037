import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../../AppContext.js';
import { intToDateTimeFormat } from '../../../../utils/datetime.js';

const localization = {
    ua: {
        filters: {
            unit: 'Юніт',
            requests: 'Запити',
            all: 'Усі',
            paid: 'Платні',
            notpaid: 'Не платні',
        },
        table: {
            unit: 'Юніт',
            user: 'Коистувач',
            method: 'Запит',
            edrpou: 'ЄДРПОУ',
            moment: 'Дата',
            requested: 'Платний запит',
        },
        methods: {
            sanctions: 'Наявність компанії в санкційних списках (4)',
            vat: 'Дані реєстру платників податку ПДВ (9)',
            singleTax: 'Дані реєстру платників єдиного податку (11)',
            usr: 'Єдиний державний реєстр юр. та фіз. осіб (12)',
            secou: 'Відомості про справи про банкрутство (14)',
        },
        requested: {
            yes: 'Так',
            no: '',
        },
    },
    ru: {
        filters: {
            unit: 'Юнит',
            requests: 'Запросы',
            all: 'Все',
            paid: 'Платные',
            notpaid: 'Не платные',
        },
        table: {
            unit: 'Юнит',
            user: 'Пользователь',
            method: 'Запрос',
            edrpou: 'ЕДРПОУ',
            moment: 'Дата',
            requested: 'Платный запрос',
        },
        methods: {
            sanctions: 'Наличие компании в санкционных списках (4)',
            vat: 'Данные реестра плательщиков НДС (9)',
            singleTax: 'Данные реестра плательщиков единого налога (11)',
            usr: 'Единый государственный реестр юр. и физ. лиц (12)',
            secou: 'Сведения о делах о банкротстве (14)',
        },
        requested: {
            yes: 'Да',
            no: '',
        },
    },
    en: {
        filters: {
            unit: 'Unit',
            requests: 'Requests',
            all: 'All',
            paid: 'Paid',
            notpaid: 'Not paid',
        },
        table: {
            unit: 'Unit',
            user: 'User',
            method: 'Request',
            edrpou: 'EDRPOU',
            moment: 'Date',
            requested: 'Paid request',
        },
        methods: {
            sanctions: 'The presence of the company in the sanctions lists (4)',
            vat: 'Data from the register of VAT payers (9)',
            singleTax: 'Data from the register of single tax payers (11)',
            usr: 'Unified state register of Legal entities and physical persons (12)',
            secou: 'Bankruptcy information (14)',
        },
        requested: {
            yes: 'Yes',
            no: '',
        },
    },
}

class Requests extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            redirect: null,

            filterUnit: null,
            filterRequested: null,

            list: [],
        }

        this.filterUnitChange = this.filterUnitChange.bind(this);
        this.filterRequestedChange = this.filterRequestedChange.bind(this);

        this.exportToCSV = this.exportToCSV.bind(this);
    }

    componentDidMount() {
        this.setState({
            filterUnit: Number(this.props.match.params.unitID), // null or int
            filterRequested: null,
        }, () => {
            this.list();
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.from !== prevProps.from || this.props.to !== prevProps.to || this.props.unitID !== prevProps.unitID) {
            this.setState({
                filterUnit: Number(this.props.unitID), // null or int
                filterRequested: null,
            }, () => {
                this.list();
            });
            //this.list();
        }
    }

    filterUnitChange(event) {
        this.setState({filterUnit: Number(event.target.value)}, () => {
            this.list();
        });
    }

    filterRequestedChange(event) {
        this.setState({filterRequested: event.target.value == "true" ? true : event.target.value == "false" ? false : null}, () => {
            this.list();
        });
    }

    list() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/crm/youcontrol/requests', {
                from: this.props.from,
                to: this.props.to,
                unitID: this.state.filterUnit,
                requested: this.state.filterRequested,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then((res) => {
                this.setState({
                    isLoading: false,
                    list: res.data
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                list: [
                    {
                        recordID: 1,
                        unitID: 1,
                        unitName: "Scania Kyiv Makariv",
                        userID: 1,
                        userName: "Черненко Світлана Володимирівна",
                        method: "usr",
                        edrpou: "12345678",
                        moment: 1613543104,
                        requested: true,
                    },
                    {
                        recordID: 2,
                        unitID: 1,
                        unitName: "Scania Kyiv Makariv",
                        userID: 1,
                        userName: "Черненко Світлана Володимирівна",
                        method: "usr",
                        edrpou: "12345678",
                        moment: 1613543104,
                        requested: false,
                    },
                ]
            });
        }
    }

    exportToCSV() {
        const {lang, datetime} = this.context;
        
        var s = ";"
        var encodedUri = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(localization[lang].table.unit + s + localization[lang].table.user + s + localization[lang].table.method + s + localization[lang].table.edrpou + s + localization[lang].table.moment + s + localization[lang].table.requested + s +  "\n" + this.state.list.map(i => i.unitName + s + i.userName + s + localization[lang].methods[i.method] + s + i.edrpou + s + intToDateTimeFormat(i.moment, datetime) + s + (i.requested ? localization[lang].requested.yes : localization[lang].requested.no) + s).join("\n"));

        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "requests.csv");
        link.click();
    }

    renderTable() {
        const {lang, datetime} = this.context;

        if (this.state.list.length === 0) {
            return (
                <tr>
                    <td colSpan="6">{localization[lang].noData}</td>
                </tr>
            );
        } else {
            return this.state.list.map((item, i) => {
                return (
                    <tr style={{height: "46px"}} key={"row_" + item.recordID}>
                        <td class="align-middle pt-1 pb-1">{item.unitName}</td>
                        <td class="align-middle pt-1 pb-1">{item.userName}</td>
                        <td class="align-middle pt-1 pb-1">{localization[lang].methods[item.method]}</td>
                        <td class="align-middle text-center pt-1 pb-1">{item.edrpou}</td>
                        <td class="align-middle text-center pt-1 pb-1">{intToDateTimeFormat(item.moment, datetime)}</td>
                        <td class="align-middle text-center pt-1 pb-1">{item.requested ? (<b>{localization[lang].requested.yes}</b>) : (<span>{localization[lang].requested.no}</span>)}</td>
                    </tr>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-10 offset-1">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <div>
                                    <span className="mr-2" style={{fontSize: "12px", color: "#6b6b6b"}}>{localization[lang].filters.unit}</span>
                                    <select className="input-agis-block mr-4" style={{width: "300px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.filterUnit ? this.state.filterUnit.toString() : ""} onChange={this.filterUnitChange}>
                                        <option value={""}>{localization[lang].filters.all}</option>
                                        <option value={"15"}>Scania Ukraine</option>
                                        <option value={"1"}>Scania Dnipro</option>
                                        <option value={"2"}>Scania Donbass - Kramatorsk</option>
                                        <option value={"3"}>Scania Donbass - Kriviy Rig</option>
                                        <option value={"4"}>Scania Kharkiv</option>
                                        <option value={"5"}>Scania Kropynytskyi - Kropynytskyi</option>
                                        <option value={"6"}>Scania Kropynytskyi - Ulyanivka</option>
                                        <option value={"7"}>Scania Kyiv - Makariv</option>
                                        <option value={"8"}>Scania Kyiv - Brovary</option>
                                        <option value={"9"}>Scania Lviv - Ryasna-Ruska</option>
                                        <option value={"10"}>Scania Lviv - Mukachevo</option>
                                        <option value={"11"}>Scania Lviv - Lutsk</option>
                                        <option value={"12"}>Scania Odesa - Odesa</option>
                                        <option value={"13"}>Scania Poltava</option>
                                        <option value={"14"}>Scania Vinnytsia</option>
                                    </select>

                                    <span className="mr-2" style={{fontSize: "12px", color: "#6b6b6b"}}>{localization[lang].filters.requests}</span>
                                    <select className="input-agis-block mr-4" style={{width: "150px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.filterRequested != null ? this.state.filterRequested.toString() : ""} onChange={this.filterRequestedChange}>
                                        <option value={""}>{localization[lang].filters.all}</option>
                                        <option value={"true"}>{localization[lang].filters.paid}</option>
                                        <option value={"false"}>{localization[lang].filters.notpaid}</option>
                                    </select>
                                </div>

                                <div className="text-right">
                                    <button onClick={this.exportToCSV} className="ml-4" style={{
                                        userSelect: "none",
                                        border: "1px solid transparent",
                                        cursor: "pointer",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        maxWidth: "200px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        textAlign: "center",
                                        textDecoration: "none",
                                        touchAction: "manipulation",
                                        whiteSpace: "nowrap",

                                        height: "32px",
                                        borderRadius: "3px",
                                        color: "#666",
                                        backgroundColor: "#ebebeb",
                                    }}>Export to CSV</button>
                                </div>
                            </div>

                            <table className="table table-bordered table-hover" style={{fontSize: "12px"}}>
                                <thead>
                                    <tr style={{height: "30px", backgroundColor: "#f4f4f4"}}>
                                        <th class="align-middle text-center" style={{width: "300px"}}>{localization[lang].table.unit}</th>
                                        <th class="align-middle text-center" style={{width: "300px"}}>{localization[lang].table.user}</th>
                                        <th class="align-middle text-center" >{localization[lang].table.method}</th>
                                        <th class="align-middle text-center" style={{width: "150px"}}>{localization[lang].table.edrpou}</th>
                                        <th class="align-middle text-center" style={{width: "200px"}}>{localization[lang].table.moment}</th>
                                        <th class="align-middle text-center" style={{width: "150px"}}>{localization[lang].table.requested}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderTable()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Requests;
import React from 'react'
import { Link } from 'react-router-dom'
import DocumentMeta from 'react-document-meta';
import axios from 'axios';

import AppContext from '../../AppContext';

const localization = {
    ua: {
        title: 'Профіль',
        loading: "Завантаження...",
        interface: {
            title: "Налаштування iнтерфейсу",
            language: "Мова iнтерфейсу",
        },
        session: {
            title: "Налаштування сесії",
            exitButtonText: "Вийти",
        }
    },
    ru: {
        title: 'Профиль',
        loading: "Загрузка...",
        interface: {
            title: "Настройки интерфейса",
            language: "Язык интерфейса",
        },
        session: {
            title: "Настройки сессии",
            exitButtonText: "Выйти",
        }
    },
    pl: {
        title: 'Profil',
        loading: "Ładowanie...",
        interface: {
            title: "Ustawienia aplikacji",
            language: "Język",
        },
        session: {
            title: "Ustawienia sesji",
            exitButtonText: "Wyloguj",
        }
    },
    en: {
        title: 'Profil',
        loading: "Loading...",
        interface: {
            title: "Interface settings",
            language: "Language",
        },
        session: {
            title: "Session settings",
            exitButtonText: "Logout",
        }
    },
}

class Profile extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
        }

        this.languageChange = this.languageChange.bind(this);
    }

    componentDidMount() {
        const {lang, setNavigationTitle} = this.context;
        setNavigationTitle(localization[lang].title);
    }

    languageChange(lang) {
        const {addNotification, removeNotification, token, settingsLang, setNavigationTitle} = this.context;

        var u = addNotification(localization[lang].loading);
        axios.post('/api/settings/language', { token: token, lang: lang }).then((res) => {
            settingsLang(lang);
            setNavigationTitle(localization[lang].title);
        })
        .catch((error) => {
            if (error.response.status == 401) {
                addNotification(error.response.data, 2);
            } else {
                addNotification(error.response.data, 2);
            }
        }).finally(function () {
            removeNotification(u);
        });
    }

    render() {
        const {lang, username, authLogout, firebaseToken} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />
                {/* <Submenu /> */}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">

                                <div className="row mb-2">
                                    <div className="col-12 col-md-4">
                                        <div className="card agis-card pt-2 pb-2" style={{ borderRadius: "5px" }}>
                                            <div className="row">
                                                <div className="col-12 mb-2">
                                                    <h6><b>Notification</b></h6>
                                                </div>
                                                <div className="col-12">
                                                    <small>Token: {firebaseToken}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="card agis-card pt-2 pb-2" style={{ borderRadius: "5px" }}>
                                            <div className="row">
                                                <div className="col-12 mb-2">
                                                    <h6><b>{localization[lang].interface.language}</b></h6>
                                                </div>
                                                <div className="col-12 d-flex justify-content-between">
                                                    <button className={lang == "ua" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: '32%', minWidth: "0px"}} onClick={(e) => {this.languageChange("ua")}}> UA</button>
                                                    <button className={lang == "ru" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: '32%', minWidth: "0px"}} onClick={(e) => {this.languageChange("ru")}}> RU</button>
                                                    <button className={lang == "en" ? 'btn-agis-primary-outline' : 'btn-agis-light-outline'} style={{height: "30px", width: '32%', minWidth: "0px"}} onClick={(e) => {this.languageChange("en")}}> EN</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 ">
                                        <div className="card agis-card pt-2 pb-2" style={{ borderRadius: "5px" }}>
                                            <div className="row">
                                                {/* <div className="col-12">
                                                    <h6><b>Language</b></h6>
                                                </div> */}
                                                <div className="col-12 mb-2">
                                                    <h6><b>{localization[lang].session.title}</b></h6>
                                                </div>
                                                <div className="col-12 text-right">
                                                    <button className="btn-agis-danger" style={{height: "30px"}} onClick={() => {authLogout()}}>{localization[lang].session.exitButtonText}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Profile;
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import AppContext from '../../../AppContext.js';
import Submenu from '../../components/submenu.js';
import DatePicker from '../../components/datePicker.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faKey, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

import { intToDateTimeFormat, intToISODate } from '../../../utils/datetime.js';

const localization = {
    ua: {
        title: 'Recall campaigns',
        table: {
            name: 'Name',
            details: 'Details',
        }
    },
    ru: {
        title: 'Recall campaigns',
        table: {
            name: 'Name',
            details: 'Details',
        }
    },
    en: {
        title: 'Recall campaigns',
        table: {
            name: 'Name',
            details: 'Details',
        }
    },
}

class Campaigns extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            redirect: null,

            campaigns: [],
        }
    }

    componentDidMount() {
        const {lang, setNavigationTitle, token, addNotification, removeNotification, units, unitID} = this.context;
        setNavigationTitle(localization[lang].title);

        this.init();
    }

    init() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/service/recall/campaigns', {
                
            }, { headers: { Authorization: `Bearer ${token}` }}).then((res) => {
                this.setState({
                    isLoading: false,
                    campaigns: res.data
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,

                campaigns: [
                    {
                        campaignID: 1,
                        name: "Driver feedback",
                    },
                    {
                        campaignID: 2,
                        name: "Creditor feedback",
                    },
                ]
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                
                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-10 offset-1">
                            <table className="table table-bordered table-hover" style={{fontSize: "12px"}}>
                                <thead>
                                    <tr style={{height: "30px", backgroundColor: "#f4f4f4"}}>
                                        <th class="align-middle text-center">{localization[lang].table.name}</th>
                                        <th style={{width: "100px"}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.campaigns.map((item) => {
                                        return (
                                            <tr style={{height: "46px"}} key={"row_" + item.campaignID}>
                                                <td class="align-middle pt-1 pb-1">{item.name}</td>
                                                <td class="align-middle text-center pt-1 pb-1"><Link to={"/service/recall/campaign/" + item.campaignID + "/registry"}>{localization[lang].table.details}</Link></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Campaigns;
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faCog, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';

const localization = {
    ua: {
        title: 'Автомобілі',
        table: {
            vin: 'VIN',
            serialNumber: 'Серійний номер',
            manufacturer: 'Виробник',
            model: 'Модель',
            year: 'Рік',
            mileage: 'Пробіг',
            registrationNumber: 'Реєстраційний номер',
            details: 'Детальніше',
        },
    },
    ru: {
        title: 'Автомобили',
        table: {
            vin: 'VIN',
            serialNumber: 'Серийный номер',
            manufacturer: 'Производтель',
            model: 'Модель',
            year: 'Год',
            mileage: 'Пробег',
            registrationNumber: 'Регистрационный номер',
            details: 'Детальнее',
        },
    },
    en: {
        title: 'Vehicles',
        table: {
            vin: 'VIN',
            serialNumber: 'Serial number',
            manufacturer: 'Manufacturer',
            model: 'Model',
            year: 'Year',
            mileage: 'Mileage',
            registrationNumber: 'Registration number',
            details: 'Details',
        },
    },
}

class Index extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            
            vehicles: []
        }
    }

    componentDidMount() {
        const {lang, setNavigationTitle} = this.context;
        setNavigationTitle(localization[lang].title);

        this.init();
    }

    init() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/service/vehicles/list', {}, {
                headers: { Authorization: `Bearer ${token}` }
            }).then((res) => {
                this.setState({
                    isLoading: false,
                    vehicles: res.data,
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,

                vehicles: [
                    {
                        vehicleID: 1, 
                        vin: "ABCABCA1234567890",
                        serialNumber: "4567890",
                        manufacturer: "Toyota",
                        model: "Corolla",
                        year: 2010,
                        mileage: 165000,
                        registrationNumber: "AA2231KA",
                    },
                    {
                        vehicleID: 2, 
                        vin: "ABCABCA1234567890",
                        serialNumber: "4567890",
                        manufacturer: "SEAT",
                        model: "Altea",
                        year: null,
                        mileage: null,
                        registrationNumber: null,
                    },
                ],
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />

                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-10 offset-1">
                            <table className="table table-bordered table-hover" style={{fontSize: "12px"}}>
                                <thead>
                                    <tr style={{height: "30px", backgroundColor: "#f4f4f4"}}>
                                        <th class="align-middle text-center">{localization[lang].table.vin}</th>
                                        <th class="align-middle text-center" style={{width: "160px"}}>{localization[lang].table.serialNumber}</th>
                                        <th class="align-middle text-center" style={{width: "160px"}}>{localization[lang].table.manufacturer}</th>
                                        <th class="align-middle text-center" style={{width: "160px"}}>{localization[lang].table.model}</th>
                                        <th class="align-middle text-center" style={{width: "160px"}}>{localization[lang].table.year}</th>
                                        <th class="align-middle text-center" style={{width: "160px"}}>{localization[lang].table.mileage}</th>
                                        <th class="align-middle text-center" style={{width: "160px"}}>{localization[lang].table.registrationNumber}</th>
                                        <th style={{width: "100px"}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.vehicles.map((item) => {
                                        return (
                                            <tr style={{height: "46px"}} key={"row_" + item.vehicleID}>
                                                <td class="align-middle pt-1 pb-1">{item.vin}</td>
                                                <td class="align-middle pt-1 pb-1">{item.serialNumber}</td>
                                                <td class="align-middle pt-1 pb-1">{item.manufacturer}</td>
                                                <td class="align-middle pt-1 pb-1">{item.model}</td>
                                                <td class="align-middle pt-1 pb-1">{item.year}</td>
                                                <td class="align-middle pt-1 pb-1">{item.mileage}</td>
                                                <td class="align-middle pt-1 pb-1">{item.registrationNumber}</td>
                                                {/* <td class="align-middle text-center pt-1 pb-1"><Link to={"/service/vehicle/" + item.vehicleID + "/card"}>{localization[lang].table.details}</Link></td> */}
                                                <td class="align-middle text-center pt-1 pb-1">{localization[lang].table.details}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Index;
import React from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBasket, faHome, faPhone, faThList, faBriefcase } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext.js';
import Preview from '../components/preview.js';

const localization = {
    ua: {
        placeholder: 'Пошук контрагента...',
        isPrompt: 'Введіть номер телефону',
        isSearching: 'Пошук...',
        isNotFound: 'Контрагент не знайдений. ',
    },
    ru: {
        placeholder: 'Пошук контрагента...',
        isPrompt: 'Введіть номер телефону',
        isSearching: 'Пошук...',
        isNotFound: 'Контрагент не знайдений. ',
    },
    en: {
        placeholder: 'Пошук контрагента...',
        isPrompt: 'Введіть номер телефону',
        isSearching: 'Пошук...',
        isNotFound: 'Контрагент не знайдений. ',
    },
}

var debounce = (fn, delay) => {
    var timer = null;
    return function () {
        var context = this,
            args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
}

class GoodsPicker extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            
            isSearching: false,
            searchExpand: false,
            searchText: "",
            searchResults: [],
        }

        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.searchInput = React.createRef();
        this.searchExpand = React.createRef();

        this.onSearchFocus = this.onSearchFocus.bind(this);
        this.onSearchKeyDown = this.onSearchKeyDown.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        
        this.search = debounce(this.search.bind(this),1000);
        this.searchDismiss = this.searchDismiss.bind(this);
    }

    componentDidMount() {
        //this.init();
        window.addEventListener('click', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.searchExpand.current && this.searchInput.current) {
            if ((this.searchExpand && !this.searchExpand.current.contains(event.target)) && (this.searchInput && !this.searchInput.current.contains(event.target))) {
                //console.log('You clicked outside of me!');
                this.setState({ searchExpand: false });
            }
        }
    }

    onSearchFocus() {
        let coords = this.searchInput.current.getBoundingClientRect();

        var w = this.searchInput.current.offsetWidth;

        this.searchExpand.current.style.width = w > 400 ? w : 400 + "px";
        this.searchExpand.current.style.left = coords.left + "px";
        this.searchExpand.current.style.top = coords.bottom + "px";

        this.setState({ searchExpand: true });
    }

    onSearchKeyDown() {
        this.setState({ searchExpand: true });
    }

    onSearchChange(event) {
        if (event.target.value.length < 3) {
            this.setState({searchText: event.target.value});
        } else {
            this.setState({searchText: event.target.value, isSearching: true, searchResults: []}, () => {
                this.search();
            });
        }
    }

    search() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("");

            axios.post('/api/catalog/goods/search', {
                searchText: this.state.searchText
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then((res) => {
                this.setState({
                    isSearching: false,
                    searchResults: res.data,
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            setTimeout(() => {
                this.setState({
                    isSearching: false,

                    searchResults: [
                        {
                            counterpartyID: 1,
                            company: false,
                            name: "Бутенко Евгений Олегович",
                            nameFull: "Бутенко Евгений Олегович",
                            stateRegistrationCode: null,
                            phone: "380508673516",
                            phoneAlternative: null,
                            email: "evgeniybutenko@gmail.com",
                            address: "м.Київ, вул. Луценка Дмитра, 15, блок 17, кім. 15",
                        },
                    ]
                });
            }, 1000);
        }
    }

    searchDismiss() {
        this.setState({
            isSearching: false,
            searchExpand: false,
            searchText: "",
            searchResults: [],
        });
    }

    renderSearchResults() {
        const {lang} = this.context;

        if (this.state.isSearching) {
            return (
                <div className="card-agis pt-2 pb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="w-100">
                            <span className="text-muted">{localization[lang].isSearching}</span>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.searchText.length < 3) {
            return (
                <div className="card-agis pt-2 pb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="w-100">
                            <span className="text-muted">{localization[lang].isPrompt}</span>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.searchResults.length == 0) {
            return (
                <div className="card-agis pt-2 pb-2">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="w-100">
                            <span className="text-muted">{localization[lang].isNotFound}</span>
                            {/* <Link to={"/"}>Створити</Link> */}
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.searchResults.map((item, i) => {
                return (
                    <a onClick={() => { this.props.action(item); this.searchDismiss(); }} className="card-agis noselect clickable">
                        <div className="row">
                            {/* <div className="col-3 col-md-2 d-flex align-items-center">
                                <Preview src={"https://honda-parts.com.ua/hashed/08P11-TLA-610_b95a1be7ae5d10fdb390663d7aa949a427fff702_md.jpg"} />
                            </div> */}
                            <div className="col-12 d-flex align-items-center">
                                <div className="w-100">
                                    <span><b>{item.name}</b></span><br/>
                                    <span className="">{item.phone}</span>
                                    {/* {item.total && (
                                        item.total != 0 ? (
                                            <big className="mt-1 mb-1">В наявності <b>{item.total} шт</b></big>
                                        ) : (
                                            <big className="mt-1 mb-1">Немає в наявності</big>
                                        )
                                    )} */}
                                </div>
                            </div>
                        </div>
                    </a>
                );
            });
        }
    }

    render() {
        const { lang } = this.context;

        if (this.state.isLoading) {
            return (
                <div></div>
            );
        }

        var searchInputStyle = {
            ...this.props.style
        }

        if (this.state.searchExpand) {
            searchInputStyle = {...this.props.style, borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px"}
        }

        return (
            <div>
                <div ref={this.searchExpand} style={{
                    zIndex: 2000,
                    display: this.state.searchExpand ? "block" : "none",
                    position: "fixed",
                    width: "600px",
                    backgroundColor: "#ffffff"
                }}>
                    {this.renderSearchResults()}
                </div>

                <input ref={this.searchInput} type="text" className={this.props.className} style={searchInputStyle} placeholder={this.props.placeholder ? this.props.placeholder : localization[lang].placeholder} value={this.state.searchText} onChange={this.onSearchChange} onFocus={this.onSearchFocus} onKeyDown={this.onSearchKeyDown} />
            </div>
        )
    }
}

export default GoodsPicker;
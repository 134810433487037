import React from 'react'
import { Link } from 'react-router-dom'
import DocumentMeta from 'react-document-meta';
import axios from 'axios';

import AppContext from '../AppContext';
import Submenu from './components/submenu.js';

const localization = {
    ua: {
        title: 'Меню',
        catalog: {
            title: "Каталог",
            parts: {
                title: "Запчастини",
                search: "Пошук",
            },
        },
    },
    ru: {
        title: 'Меню',
        catalog: {
            title: "Каталог",
            parts: {
                title: "Запчасти",
                search: "Поиск",
            },
        },
    },
    en: {
        title: 'Menu',
        catalog: {
            title: "Catalog",
            parts: {
                title: "Parts",
                search: "Search",
            },
        },
    },
}

class Menu extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
        }
    }

    componentDidMount() {
        const {lang, setNavigationTitle} = this.context;
        setNavigationTitle(localization[lang].title);
    }

    render() {
        const {lang, scale, unitID} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />

                <div className="container-fluid">
                    {/* <div className="row mb-4">
                        <div className="col d-flex justify-content-between">
                            <button className={'btn-agis-primary-outline'} style={{height: "30px", width: '48%', minWidth: "0px"}}>Service</button>
                            <button className={'btn-agis-light-outline'} style={{height: "30px", width: '48%', minWidth: "0px"}}>CRM</button>
                            <button className={'btn-agis-light-outline'} style={{height: "30px", width: '48%', minWidth: "0px"}}>IAM</button>
                        </div>
                    </div> */}

                    <div className="row">
                        <div className="col">
                            <span className="display-4 text-muted" style={{fontSize: "1.4rem"}}>Service</span><br/>
                            {/* <Link to={"/service/vehicles"}><big>Vehicles</big></Link><br/>
                            <Link to={"/crm/counterparties"}><big>Counterparties</big></Link><br/>
                            <Link to={"/iam/users"}><big>Users</big></Link><br/> */}

                            <Link to={"/service/check-lists"}><big>Check lists</big></Link><br/>

                            <Link to={"/service/field-maintenance"}><big>Field maintenance</big></Link><br/>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Menu;
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faKey, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import Submenu from '../../components/submenu.js';

const localization = {
    ua: {
        title: 'Сервісні ліди',
        submenu: {
            leads: 'Ліди',
            analytics : 'Аналітика',
            settings: 'Налаштування',
        },
    },
    ru: {
        title: 'Сервисные лиды',
        submenu: {
            leads: 'Лиды',
            analytics : 'Аналитика',
            settings: 'Настройки',
        },
    },
    en: {
        title: 'Service leads',
        submenu: {
            leads: 'Leads',
            analytics : 'Analytics',
            settings: 'Settings',
        },
    },
}

class Settings extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            redirect: null,

            types: [],
            responsibles: []
        }
    }

    componentDidMount() {
        const {lang, setNavigationTitle, unitID} = this.context;
        setNavigationTitle(localization[lang].title);

        //this.setState({isLoading: false, user: "User - " + unitID})
        this.init();
    }

    init() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            var typesPromise        = axios.post('/api/service/lead/types',        {}, { headers: { Authorization: `Bearer ${token}` }});
            var responsiblesPromise = axios.post('/api/service/lead/responsibles', {}, { headers: { Authorization: `Bearer ${token}` }});

            axios.all([typesPromise, responsiblesPromise]).then(axios.spread((types, responsibles) => {
                this.setState({
                    isLoading: false,
                    types: types.data,
                    responsibles: responsibles.data
                });
            })).catch(error => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });   
        } else {
            this.setState({
                isLoading: false,

                types: [
                    {
                        typeID: 1,
                        name: "Нагадування про ТО"
                    },
                    {
                        typeID: 2,
                        name: "Відкличні кампанії"
                    },
                ],

                responsibles: [
                    {
                        userID: 1,
                        name: "Хоменко Олександр Іванович",
                        typeID: 1
                    },
                    {
                        userID: 2,
                        name: "Бутенко Євгеній Олегович",
                        typeID: 2
                    },
                    {
                        userID: 2,
                        name: "Бутенко Ангеліна Олегівна",
                        typeID: 2
                    },
                ]
            });
        }
    }

    renderTypes() {
        const {lang, datetime} = this.context;

        if (this.state.types.length === 0) {
            return (
                <div className="card-agis pt-2 pb-2">
                    <h6 className="text-muted font-weight-light mb-1">Відсутні</h6>
                </div>
            );
        } else {
            return this.state.types.map((type, i) => {
                return (
                    <div className="card-agis pt-2 pb-2 mb-4">
                        <h6 className="text-muted font-weight-light mb-1">{type.name}</h6>

                        <div className="row">
                            <div className="col">
                                <small class="form-text text-muted pl-1">Відповідальні особи</small>

                                {this.state.responsibles.filter(x => x.typeID == type.typeID).map((responsible, i) => {
                                    return (
                                        <input type="text" class="question-input mb-2" value={responsible.name} disabled={true} />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                
                {/* <Submenu>
                    <div style={{backgroundColor: '#fff'}}>
                        <div className="d-flex align-items-center justify-content-between" style={{minHeight: '50px'}}>
                            <div>
                                <NavLink to={"/service/leads/registry"} className="nav-agis-light-outline ml-4 mr-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.leads}</NavLink>
                                <NavLink to={"/service/leads/analytics"} className="nav-agis-light-outline ml-4 mr-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.analytics}</NavLink>
                                <NavLink to={"/service/leads/settings"} className="nav-agis-light-outline ml-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.settings}</NavLink>
                            </div>
                        </div>
                    </div>
                </Submenu> */}

                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-4 offset-1">
                            {this.renderTypes()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Settings;
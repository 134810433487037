/*
    Integer to date
*/
// -> 2021-03-15
export function intToISODate(date) {
    var d = new Date(date * 1000);

    var year = d.getFullYear();
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

// -> 2021-03-15T20:00:00
export function intToISODateTime(date) {
    var d = new Date(date * 1000);

    var year = d.getFullYear();
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    
    var hour = '' + d.getHours()
    var minute = '' + d.getMinutes()
    var second = '' + d.getSeconds()

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hour.length < 2) hour = '0' + hour;
    if (minute.length < 2) minute = '0' + minute;
    if (second.length < 2) second = '0' + second;

    return [year, month, day].join('-') + "T" + [hour, minute, second].join(':');
}

// -> format
export function intToDateTimeFormat(date, format) {
    var d = new Date(date * 1000);

    var year = d.getFullYear();
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();

    var hour = '' + d.getHours()
    var minute = '' + d.getMinutes()
    var second = '' + d.getSeconds()

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hour.length < 2) hour = '0' + hour;
    if (minute.length < 2) minute = '0' + minute;
    if (second.length < 2) second = '0' + second;

    return format.replace('year', year).replace('month', month).replace('day', day).replace('hour', hour).replace('minute', minute).replace('second', second);
}
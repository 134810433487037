import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faKey, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import Submenu from '../../components/submenu.js';

import { intToDateTimeFormat, intToISODate } from '../../../utils/datetime.js';

const localization = {
    ua: {
        title: 'Feedback - Dashboard',
        submenu: {
            dashboard: 'Dashboard',
            leads: 'Leads',
            settings: 'Settings',
        },
    },
    ru: {
        title: 'Feedback - Dashboard',
        submenu: {
            dashboard: 'Dashboard',
            leads: 'Leads',
            settings: 'Settings',
        },
    },
    en: {
        title: 'Feedback - Dashboard',
        submenu: {
            dashboard: 'Dashboard',
            leads: 'Leads',
            settings: 'Settings',
        },
    },
}

class Summary extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            redirect: null,

            units: null,
            unitID: null,

            operationalFrom: null,
            operationalTo: null,

            commitedFrom: null,
            commitedTo: null,
        }

        this.unitChange = this.unitChange.bind(this);

        this.fromChange = this.fromChange.bind(this);
        this.toChange   = this.toChange.bind(this);

        this.fromCommit = this.fromCommit.bind(this);
        this.toCommit   = this.toCommit.bind(this);

        this.fromKeyPress = this.fromKeyPress.bind(this);
        this.toKeyPress   = this.toKeyPress.bind(this);

        this.prevMonth = this.prevMonth.bind(this);
        this.nextMonth = this.nextMonth.bind(this);
    }

    componentDidMount() {
        const {lang, setNavigationTitle, token, addNotification, removeNotification, units, unitID} = this.context;
        setNavigationTitle(localization[lang].title);

        var date = new Date(), y = date.getFullYear(), m = date.getMonth();

        //var from = Math.floor(new Date(y, m - 1, 1).getTime() / 1000);  //first day of prev month
        //var to = Math.floor(new Date(y, m, 0).getTime() / 1000);  //last day of prev month

        var from = Math.floor(new Date(y, m, 1, 0, 0, 0).getTime() / 1000);  //first day of current month
        var to = Math.floor(new Date(y, m+1, 0, 23, 59, 59).getTime() / 1000);  //last day of current month

        this.setState({
            isLoading: false,

            units: units,
            unitID: 0,

            operationalFrom: intToISODate(from),
            operationalTo: intToISODate(to),

            commitedFrom: from,
            commitedTo: to,
        }, () => {
            this.year();
            this.month();
        });
    }

    year() {
        const {addNotification, removeNotification, token} = this.context;
        
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();

        var from = Math.floor(new Date(y, m - 12, 1).getTime() / 1000);  //first day of prev year
        var to = Math.floor(new Date(y, m+1, 0, 23, 59, 59).getTime() / 1000);  //last day of current month
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            axios.post('/api/crm/loyalty/period', {
                unitID: this.state.unitID,
                from: from,
                to: to,
            }, { headers: { Authorization: `Bearer ${token}` }}).then((res) => {
                if (res.data.length > 0) {
                    this.setState({
                        yearTotal: res.data[0].total,
                        yearNPS: res.data[0].nps,
                        yearCSI: res.data[0].csi,
                    });
                } else {
                    this.setState({
                        yearTotal: "-",
                        yearNPS: "-",
                        yearCSI: "-",
                    });
                }
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                yearTotal: 504,
                yearNPS: 73,
                yearCSI: 4.33,
            });
        }
    }

    month() {
        const {addNotification, removeNotification, token} = this.context;
        
        if (process.env.NODE_ENV === 'production') {
            var u = addNotification("Завантаження...");

            axios.post('/api/crm/loyalty/period', {
                unitID: this.state.unitID,
                from: this.state.commitedFrom,
                to: this.state.commitedTo,
            }, { headers: { Authorization: `Bearer ${token}` }}).then((res) => {
                if (res.data.length > 0) {
                    this.setState({
                        monthTotal: res.data[0].total,
                        monthNPS: res.data[0].nps,
                        monthCSI: res.data[0].csi,
                    });
                } else {
                    this.setState({
                        monthTotal: "-",
                        monthNPS: "-",
                        monthCSI: "-",
                    });
                }
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                monthTotal: 680,
                monthNPS: 81,
                monthCSI: 4.81,
            });
        }
    }

    unitChange(event) {
        this.setState({unitID: Number(event.target.value)}, () => { this.year(); this.month(); });
    }

    fromChange(event) {
        this.setState({operationalFrom: event.target.value});
    }

    toChange(event) {
        this.setState({operationalTo: event.target.value});
    }

    fromKeyPress(e) {
        if (e.key === 'Enter') {
            this.fromCommit();
        }
    }

    toKeyPress(e) {
        if (e.key === 'Enter') {
            this.toCommit();
        }
    }

    fromCommit() {
        this.setState({commitedFrom: Math.floor(new Date(this.state.operationalFrom).getTime() / 1000)}, () => this.month());
    }

    toCommit() {
        this.setState({commitedTo: Math.floor(new Date(this.state.operationalTo).getTime() / 1000)}, () => this.month());
    }

    prevMonth() {
        var date = new Date(this.state.operationalFrom), y = date.getFullYear(), m = date.getMonth();

        var from = Math.floor(new Date(y, m - 1, 1, 0, 0, 0).getTime() / 1000);  //first day of prev month
        var to = Math.floor(new Date(y, m, 0, 23, 59, 59).getTime() / 1000);  //last day of prev month

        this.setState({
            operationalFrom: intToISODate(from),
            operationalTo: intToISODate(to),

            commitedFrom: from,
            commitedTo: to,
        }, () => this.month());
    }

    nextMonth() {
        var date = new Date(this.state.operationalTo), y = date.getFullYear(), m = date.getMonth();

        var from = Math.floor(new Date(y, m + 1, 1, 0, 0, 0).getTime() / 1000);  //first day of next month
        var to = Math.floor(new Date(y, m + 2, 0, 23, 59, 59).getTime() / 1000);  //last day of next month

        this.setState({
            operationalFrom: intToISODate(from),
            operationalTo: intToISODate(to),

            commitedFrom: from,
            commitedTo: to,
        }, () => this.month());
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                
                <Submenu>
                    <div style={{backgroundColor: '#fff'}}>
                        <div className="d-flex align-items-center justify-content-between" style={{minHeight: '50px'}}>
                            <div>
                                <NavLink to={"/crm/feedback/campaign/1/dashboard"} className="nav-agis-light-outline ml-4 mr-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.dashboard}</NavLink>
                                <NavLink to={"/crm/feedback/campaign/1/leads"} className="nav-agis-light-outline ml-4 mr-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.leads}</NavLink>
                                <NavLink to={"/crm/feedback/campaign/1/settings"} className="nav-agis-light-outline ml-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.settings}</NavLink>
                            </div>
                        </div>
                    </div>
                </Submenu>

                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-10 offset-1">
                            <div className="d-flex align-items-center justify-content-between mb-4" style={{minHeight: '50px'}}>
                                <div>
                                    <select className="input-agis-block mr-2" style={{width: "200px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.unitID} onChange={this.unitChange}>
                                        <option value="0">All units</option>
                                        {this.state.units.map((item) => {
                                            return (
                                                <option value={item.unitID}>{item.name}</option>
                                            )
                                        })}
                                    </select>

                                    <FontAwesomeIcon icon={faChevronLeft} className="ml-4 mr-3" style={{fontSize: "14px", color: "#6b6b6b"}} onClick={this.prevMonth} />
                                    <input type="date" className="input-agis-block mr-2" style={{width: "140px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.operationalFrom} onChange={this.fromChange} onBlur={this.fromCommit} onKeyPress={this.fromKeyPress} />
                                    <input type="date" className="input-agis-block" style={{width: "140px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.operationalTo} onChange={this.toChange} onBlur={this.toCommit} onKeyPress={this.toKeyPress} />
                                    <FontAwesomeIcon icon={faChevronRight} className="ml-3 mr-4" style={{fontSize: "14px", color: "#6b6b6b"}} onClick={this.nextMonth} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Summary;
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import AppContext from '../../../AppContext.js';
import Submenu from '../../components/submenu.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faKey, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

import { intToDateTimeFormat, intToISODate } from '../../../utils/datetime.js';

const localization = {
    ua: {
        title: 'Сервісні ліди',
        submenu: {
            leads: 'Ліди',
            analytics : 'Аналітика',
            settings: 'Налаштування',
        },
        search: {
            units: "Усі юніти",
        },
        table: {
            status: 'Статус',
            open: "Відкритий",
            closed: "Закритий",
            created: 'Створений',
            responsible: 'Відповідальний',
            title: 'Тема',
            description: 'Опис',
            representative: 'Клієнт',
            vehicle: 'Автообіль',
            details: "Детальніше",
            empty: "Немає результатів",
        }
    },
    ru: {
        title: 'Сервисные лиды',
        submenu: {
            leads: 'Лиды',
            analytics : 'Аналитика',
            settings: 'Настройки',
        },
        search: {
            units: "Все юниты",
        },
        table: {
            status: 'Статус',
            open: "Открытый",
            closed: "Закрытый",
            created: 'Создан',
            responsible: 'Ответственный',
            title: 'Тема',
            description: 'Описание',
            representative: 'Клиент',
            vehicle: 'Автомобиль',
            details: "Детальнее",
            empty: "Нет результатов",
        }
    },
    en: {
        title: 'Service leads',
        submenu: {
            leads: 'Leads',
            analytics : 'Analytics',
            settings: 'Settings',
        },
        search: {
            units: "All units",
        },
        table: {
            status: 'Status',
            open: "Open",
            closed: "Closed",
            created: 'Сreated',
            responsible: 'Responsible',
            title: 'Title',
            description: 'Description',
            representative: 'Client',
            vehicle: 'Vehicle',
            details: "Details",
            empty: "No result",
        }
    },
}

class Leads extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            redirect: null,

            units: null,
            unitID: null,

            filterIsOpen: true,
            filterIsMine: true,

            operationalFrom: null,
            operationalTo: null,

            commitedFrom: null,
            commitedTo: null,

            types: [],
        }

        this.unitChange = this.unitChange.bind(this);

        this.fromChange = this.fromChange.bind(this);
        this.toChange   = this.toChange.bind(this);

        this.fromCommit = this.fromCommit.bind(this);
        this.toCommit   = this.toCommit.bind(this);

        this.fromKeyPress = this.fromKeyPress.bind(this);
        this.toKeyPress   = this.toKeyPress.bind(this);

        this.prevMonth = this.prevMonth.bind(this);
        this.nextMonth = this.nextMonth.bind(this);
    }

    componentDidMount() {
        const {lang, setNavigationTitle, token, addNotification, removeNotification, units, unitID} = this.context;
        setNavigationTitle(localization[lang].title);

        var date = new Date(), y = date.getFullYear(), m = date.getMonth();

        //var from = Math.floor(new Date(y, m - 1, 1).getTime() / 1000);  //first day of prev month
        //var to = Math.floor(new Date(y, m, 0).getTime() / 1000);  //last day of prev month

        var from = Math.floor(new Date(y, m, 1, 0, 0, 0).getTime() / 1000);  //first day of current month
        var to = Math.floor(new Date(y, m+1, 0, 23, 59, 59).getTime() / 1000);  //last day of current month

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            var typesPromise = axios.post('/api/service/lead/types', {}, { headers: { Authorization: `Bearer ${token}` }});
            
            axios.all([typesPromise]).then(axios.spread((types) => {
                this.setState({
                    isLoading: false,

                    units: units,
                    unitID: 0,

                    operationalFrom: intToISODate(from),
                    operationalTo: intToISODate(to),

                    commitedFrom: from,
                    commitedTo: to,

                    types: types.data,
                });
            })).catch(error => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });   
        } else {
            this.setState({
                isLoading: false,

                units: units,
                unitID: 0,

                operationalFrom: intToISODate(from),
                operationalTo: intToISODate(to),

                commitedFrom: from,
                commitedTo: to,

                types: [
                    {
                        typeID: 1,
                        name: "Нагадування про ТО",
                    },
                    {
                        typeID: 2,
                        name: "Відкличні кампанії",
                    },
                ],
            });
        }
    }

    unitChange(event) {
        this.setState({unitID: Number(event.target.value)});
    }

    fromChange(event) {
        this.setState({operationalFrom: event.target.value});
    }

    toChange(event) {
        this.setState({operationalTo: event.target.value});
    }

    fromKeyPress(e) {
        if (e.key === 'Enter') {
            this.fromCommit();
        }
    }

    toKeyPress(e) {
        if (e.key === 'Enter') {
            this.toCommit();
        }
    }

    fromCommit() {
        this.setState({commitedFrom: Math.floor(new Date(this.state.operationalFrom).getTime() / 1000)});
    }

    toCommit() {
        this.setState({commitedTo: Math.floor(new Date(this.state.operationalTo).getTime() / 1000)});
    }

    prevMonth() {
        var date = new Date(this.state.operationalFrom), y = date.getFullYear(), m = date.getMonth();

        var from = Math.floor(new Date(y, m - 1, 1, 0, 0, 0).getTime() / 1000);  //first day of prev month
        var to = Math.floor(new Date(y, m, 0, 23, 59, 59).getTime() / 1000);  //last day of prev month

        this.setState({
            operationalFrom: intToISODate(from),
            operationalTo: intToISODate(to),

            commitedFrom: from,
            commitedTo: to,
        });
    }

    nextMonth() {
        var date = new Date(this.state.operationalTo), y = date.getFullYear(), m = date.getMonth();

        var from = Math.floor(new Date(y, m + 1, 1, 0, 0, 0).getTime() / 1000);  //first day of next month
        var to = Math.floor(new Date(y, m + 2, 0, 23, 59, 59).getTime() / 1000);  //last day of next month

        this.setState({
            operationalFrom: intToISODate(from),
            operationalTo: intToISODate(to),

            commitedFrom: from,
            commitedTo: to,
        });
    }

    renderTypes() {
        const {lang, datetime} = this.context;

        if (this.state.types.length === 0) {
            return (
                <div className="card-agis pt-2 pb-2">
                    <h6 className="text-muted font-weight-light mb-1">Відсутні</h6>
                </div>
            );
        } else {
            return this.state.types.map((type, i) => {
                return <Type typeID={type.typeID} name={type.name} unitID={this.state.unitID} from={this.state.commitedFrom} to={this.state.commitedTo} />
            })
        }
    }

    render() {
        const {lang} = this.context;

        if (this.state.redirect != null) {
            return <Redirect push to={this.state.redirect} />
        }

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                
                {/* <Submenu>
                    <div style={{backgroundColor: '#fff'}}>
                        <div className="d-flex align-items-center justify-content-between" style={{minHeight: '50px'}}>
                            <div>
                                <NavLink to={"/service/leads/registry"} className="nav-agis-light-outline ml-4 mr-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.leads}</NavLink>
                                <NavLink to={"/service/leads/analytics"} className="nav-agis-light-outline ml-4 mr-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.analytics}</NavLink>
                                <NavLink to={"/service/leads/settings"} className="nav-agis-light-outline ml-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.settings}</NavLink>
                            </div>
                        </div>
                    </div>
                </Submenu> */}

                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-10 offset-1">
                            <div className="d-flex align-items-center justify-content-between" style={{minHeight: '50px'}}>
                                <div>
                                    <select className="input-agis-block mr-2" style={{width: "200px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.unitID} onChange={this.unitChange}>
                                        <option value="0">{localization[lang].search.units}</option>
                                        {this.state.units.map((item) => {
                                            return (
                                                <option value={item.unitID}>{item.name}</option>
                                            )
                                        })}
                                    </select>

                                    <FontAwesomeIcon icon={faChevronLeft} className="ml-4 mr-3" style={{fontSize: "14px", color: "#6b6b6b"}} onClick={this.prevMonth} />
                                    <input type="date" className="input-agis-block mr-2" style={{width: "140px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.operationalFrom} onChange={this.fromChange} onBlur={this.fromCommit} onKeyPress={this.fromKeyPress} />
                                    <input type="date" className="input-agis-block" style={{width: "140px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.operationalTo} onChange={this.toChange} onBlur={this.toCommit} onKeyPress={this.toKeyPress} />
                                    <FontAwesomeIcon icon={faChevronRight} className="ml-3 mr-4" style={{fontSize: "14px", color: "#6b6b6b"}} onClick={this.nextMonth} />
                                </div>
                                <div>
                                    {/* <button onClick={() => this.setState({redirect: "/service/lead/create"})} className="question-button w-100" style={{height: "35px", maxWidth: "100%", fontSize: "14px", fontWeight: "300", backgroundColor: "#f3f3f3"}}>
                                        <FontAwesomeIcon icon={faPlus} className="ml-2 mr-2" style={{fontSize: "14px", color: "#a0a0a0"}} />
                                        <span className="mr-2">Створити лід</span>
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-6 offset-1">
                            {this.renderTypes()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class Type extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,

            statistics: [],
            results: []
        }
    }

    componentDidMount() {
        const {lang, setNavigationTitle, unitID} = this.context;

        this.init();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.unitID !== this.props.unitID || prevProps.from !== this.props.from || prevProps.to !== this.props.to) {
            this.init();
        }
    }

    init() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            var statisticsPromise = axios.post('/api/service/leads/analytics/statistics', { typeID: this.props.typeID, unitID: this.props.unitID, from: this.props.from, to: this.props.to }, { headers: { Authorization: `Bearer ${token}` }});
            var failsPromise      = axios.post('/api/service/leads/analytics/fails',      { typeID: this.props.typeID, unitID: this.props.unitID, from: this.props.from, to: this.props.to }, { headers: { Authorization: `Bearer ${token}` }});

            axios.all([statisticsPromise, failsPromise]).then(axios.spread((statistics, fails) => {
                this.setState({
                    isLoading: false,

                    statistics: statistics.data,
                    fails: fails.data,
                });
            })).catch(error => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });   
        } else {
            this.setState({
                isLoading: false,
                
                statistics: [
                    {
                        userID: 1,
                        name: "Yevhenii Butenko",
                        leads: 100,
                        success: 77,
                        fail: 23,
                        contacts: 2,
                        calls: 2,
                        reminders: 2,
                        done: 2,
                    },
                    {
                        userID: 2,
                        name: "Svitlana Butenko",
                        leads: 100,
                        success: 55,
                        fail: 45,
                        contacts: 2,
                        calls: 2,
                        reminders: 2,
                        done: 2,
                    },
                ],

                fails: [
                    {
                        resultID: 202,
                        name: "Клієнт іншого дилера",
                        amount: 50,
                    },
                    {
                        resultID: 203,
                        name: "Причина невідома",
                        amount: 18,
                    }
                ],
            });
        }
    }

    render() {
        const {lang} = this.context;

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        var sum = this.state.statistics.map(i => i.leads).reduce((prev, curr) => prev + curr, 0);
        var s = this.state.statistics.map(i => i.success).reduce((prev, curr) => prev + curr, 0);
        var f = this.state.statistics.map(i => i.fail).reduce((prev, curr) => prev + curr, 0);
        var p = Math.round(100 / sum * (s + f));

        return (
            <div className="card-agis pt-2 pb-2 mb-4">
                <div className='row'>
                    <div className='col'>
                        <h5 className="text-muted font-weight-light mb-1">{this.props.name}</h5>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12'>
                        <div className="row">
                            <div className="col-3 text-center">
                                <small class="form-text text-muted">Усього лідів</small>
                                <h5 className="text-muted"><b>{sum}</b></h5>
                            </div>
                            <div className="col-3 text-center">
                                <small class="form-text text-muted">Успішних</small>
                                <h5 className="text-muted"><b>{s}</b></h5>
                            </div>
                            <div className="col-3 text-center">
                                <small class="form-text text-muted">Невдач</small>
                                <h5 className="text-muted"><b>{f}</b></h5>
                            </div>
                            <div className="col-3 text-center">
                                <small class="form-text text-muted">Закрито лідів</small>
                                {(sum > 0 && (s + f) > 0) ? (
                                    <h4 className="text-muted"><b>{p}%</b></h4>
                                ) : (
                                    <h4 className="text-muted"><b>-</b></h4>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-6 pr-4'>
                        <small class="form-text text-muted">{this.state.statistics.length > 0 ? "Виконання дзвінків по лідам" : "Дзвінків не зафіксовано"}</small>
                        {this.state.statistics.map((item, i) => {
                            var progressPercentage = Math.round(100 / item.leads * item.contacts)

                            var progressColor = "#fb7b72";

                            if (progressPercentage >= 50) {
                                progressColor = "#f1ce6d"
                            } 
                            
                            if (progressPercentage >= 75) {
                                progressColor = "#28a745"
                            } 
                            
                            return (
                                <div className="row mt-2" key={"progress_"+item.userID}>
                                    <div className="col">
                                        <div className="d-flex align-items-center justify-content-between mb-1">
                                            <div className="w-75">
                                                <span className="">{item.name}</span>
                                            </div>
                                            <div className="w-25 text-right">
                                                <span className="text-muted" style={{fontSize: "10px"}}>{item.contacts + " / " + item.leads}</span>
                                            </div>
                                        </div>
                                        <div class="progress w-100" style={{height: "5px"}}>
                                            <div class="progress-bar" role="progressbar" style={{width: progressPercentage+'%', backgroundColor: progressColor}} aria-valuenow={progressPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className='col-6 pl-4'>
                        {f > 0 && (
                            <>
                                <small class="form-text text-muted">Причини відмови</small>
                                    {this.state.fails.map((item, i) => {
                                        var progressPercentage = Math.round(100 / f * item.amount)
                                        var progressColor = "#4f81bc";

                                        return (
                                            <div className="row mt-2" key={"progress_"+item.userID}>
                                                <div className="col">
                                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                                        <div className="w-75">
                                                            <span className="">{item.name}</span>
                                                        </div>
                                                        <div className="w-25 text-right">
                                                            <span className="text-muted" style={{fontSize: "10px"}}>{item.amount + " / " + f}</span>
                                                        </div>
                                                    </div>
                                                    <div class="progress w-100" style={{height: "5px"}}>
                                                        <div class="progress-bar" role="progressbar" style={{width: progressPercentage+'%', backgroundColor: progressColor}} aria-valuenow={progressPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default Leads;
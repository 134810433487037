import React from 'react'
import { Link } from 'react-router-dom'
import DocumentMeta from 'react-document-meta';
import axios from 'axios';

import AppContext from '../AppContext';
import Submenu from './components/submenu.js';

const localization = {
    ua: {
        title: 'Меню',

        catalog: "Каталог",
        catalogManufacturers: "Виробники",
        catalogParts: "Запчастини",
        catalogLabours: "Роботи",
        catalogCheckLists: "Чек листи",

        stock: "Склад",
        stockLeads: "Ліди",
        stockBalance: "На складі",
        stockReceipts: "Приходи",
        stockInventorization: "Інвентаризація",
        stockMovements: "Рух по складу",
        stockLabelPrinting: "Друк етикеток",

        ecommerce: "E-commerce",
        ecommerceDashboard: "Dashboard",
        ecommerceVehicles: "Автомобілі",
        ecommerceParts: "Запчастини",
        ecommerceUsers: "Користувачі",

        service: "Сервіс",
        serviceLeads: "Ліди",
        serviceRecallCampaigns: "Відкличні кампанії",
        serviceWorkOrders: "Заказ наряди",
        serviceCheckLists: "Чек листи",
        serviceVehicles: "Автомобілі",
        serviceWorkshopInspection: "Листи огляду ремзони",

        security: "Охорона",
        securityPasses: "Перепустки",
          
        crm: "CRM",
        crmCounterparties: "Контрагенти",
        crmContracts: "Контракти",
        crmYouControl: "YouControl",
        crmLoyalty: "Лояльність",
        crmCustomerReturn: "Повернення клієнтів",
        crmTestDrive: "Test Drive",
        crmFeedbackCampaigns: "Feedback campaigns",

        fleet: "Fleet",
        fleetOverview: "Огляд",
        fleetLogistics: "Лоністика",
        fleetMaintenance: "Обслуговування",

        iam: "IAM",
        iamUnits: "Юніти",
        iamUsers: "Користувачі",
        iamAPIKeys: "API ключі",
        iamSessions: "Сесії",
    },
    ru: {
        title: 'Меню',

        catalog: "Каталог",
        catalogManufacturers: "Производители",
        catalogParts: "Запчасти",
        catalogLabours: "Работы",
        catalogCheckLists: "Чек листы",

        stock: "Склад",
        stockLeads: "Лиды",
        stockBalance: "На складе",
        stockReceipts: "Приходы",
        stockInventorization: "Инвентаризация",
        stockMovements: "Движения по складу",
        stockLabelPrinting: "Печать этикеток",

        ecommerce: "E-commerce",
        ecommerceDashboard: "Dashboard",
        ecommerceVehicles: "Атомобили",
        ecommerceParts: "Запчасти",
        ecommerceUsers: "Пользователи",

        service: "Сервис",
        serviceLeads: "Лиды",
        serviceRecallCampaigns: "Отзывные кампании",
        serviceWorkOrders: "Заказ наряды",
        serviceCheckLists: "Чек листы",
        serviceVehicles: "Атомобили",
        serviceWorkshopInspection: "Листы осмотра ремзони",

        security: "Охрана",
        securityPasses: "Пропуска",
          
        crm: "CRM",
        crmCounterparties: "Контрагенты",
        crmContracts: "Контракты",
        crmYouControl: "YouControl",
        crmMaintenanceReminder: "Напоминание о ТО",
        crmLoyalty: "Лояльность",
        
        crmCustomerReturn: "Возврат клиентов",
        crmTestDrive: "Test Drive",
        crmFeedbackCampaigns: "Feedback campaigns",

        fleet: "Fleet",
        fleetOverview: "Overview",
        fleetLogistics: "Логистика",
        fleetMaintenance: "Обслуживание",

        iam: "IAM",
        iamUnits: "Юниты",
        iamUsers: "Пользователи",
        iamAPIKeys: "API ключи",
        iamSessions: "Сессии",
    },
    en: {
        title: 'Menu',

        catalog: "Catalog",
        catalogManufacturers: "Manufacturers",
        catalogParts: "Parts",
        catalogLabours: "Labours",
        catalogCheckLists: "Check lists",

        stock: "Stock",
        stockLeads: "Leads",
        stockBalance: "On stock",
        stockReceipts: "Receipts",
        stockInventorization: "Inventorization",
        stockMovements: "Movements",
        stockLabelPrinting: "Label printing",

        ecommerce: "E-commerce",
        ecommerceDashboard: "Dashboard",
        ecommerceVehicles: "Vehicles",
        ecommerceParts: "Parts",
        ecommerceUsers: "Users",

        service: "Service",
        serviceLeads: "Leads",
        serviceRecallCampaigns: "Recall campaigns",
        serviceWorkOrders: "Work orders",
        serviceCheckLists: "Check lists",
        serviceVehicles: "Vehicles",
        serviceWorkshopInspection: "Workshop inspection",

        security: "Security",
        securityPasses: "Passes",
          
        crm: "CRM",
        crmCounterparties: "Counterparties",
        crmContracts: "Contracts",
        crmYouControl: "YouControl",
        crmLoyalty: "Loyalty",
        crmCustomerReturn: "Customer return",
        crmTestDrive: "Test Drive",
        crmFeedbackCampaigns: "Feedback campaigns",

        fleet: "Fleet",
        fleetOverview: "Overview",
        fleetLogistics: "Logistics",
        fleetMaintenance: "Maintenance",

        iam: "IAM",
        iamUnits: "Units",
        iamUsers: "Users",
        iamAPIKeys: "API keys",
        iamSessions: "Sessions",
    },
}

class Menu extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
        }
    }

    componentDidMount() {
        const {lang, setNavigationTitle} = this.context;
        setNavigationTitle(localization[lang].title);
    }

    render() {
        const {lang, scale, unitID} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div>
                <DocumentMeta {...meta} />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="container-fluid pl-0 pr-0">
                                <div className="row pt-4">
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <span className="display-4 text-muted" style={{fontSize: "2.2rem"}}><b>{localization[lang].stock}</b></span><br/><br/>
                                        <big className="text-muted">{localization[lang].stockLeads}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].stockBalance}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].stockReceipts}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].stockInventorization}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].stockMovements}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].stockLabelPrinting}</big><br/>
                                        <br/>
                                        <br/>
                                        <br/>

                                        <span className="display-4 text-muted" style={{fontSize: "2.2rem"}}><b>{localization[lang].catalog}</b></span><br/><br/>
                                        <big className="text-muted">{localization[lang].catalogManufacturers}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].catalogParts}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].catalogLabours}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].catalogCheckLists}</big><br/>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <span className="display-4 text-muted" style={{fontSize: "2.2rem"}}><b>{localization[lang].service}</b></span><br/><br/>
                                        <Link to={"/service/leads/registry"}><big>{localization[lang].serviceLeads}</big></Link><br/>
                                        {/* <big className="text-muted">{localization[lang].serviceLeads}</big><br/> */}
                                        <br/>
                                        {/* <Link to={"/service/recall/campaigns"}><big>{localization[lang].serviceRecallCampaigns}</big></Link><br/> */}
                                        <big className="text-muted">{localization[lang].serviceRecallCampaigns}</big><br/>
                                        <br/>
                                        {/* <Link to={"/service/work-orders"}><big>{localization[lang].serviceWorkOrders}</big></Link><br/> */}
                                        <big className="text-muted">{localization[lang].serviceWorkOrders}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].serviceCheckLists}</big><br/>
                                        <br/>
                                        {/* <Link to={"/service/vehicles"}><big>{localization[lang].serviceVehicles}</big></Link><br/> */}
                                        <big className="text-muted">{localization[lang].serviceVehicles}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].serviceWorkshopInspection}</big><br/>
                                        <br/>
                                        <br/>
                                        <br/>

                                        <span className="display-4 text-muted" style={{fontSize: "2.2rem"}}><b>{localization[lang].ecommerce}</b></span><br/><br/>
                                        {/* <big className="text-muted">{localization[lang].ecommerceDashboard}</big><br/> */}
                                        <big className="text-muted">{localization[lang].ecommerceVehicles}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].ecommerceParts}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].ecommerceUsers}</big><br/>
                                        <br/>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <span className="display-4 text-muted" style={{fontSize: "2.2rem"}}><b>{localization[lang].crm}</b></span><br/><br/>
                                        <Link to={"/crm/loyalty/dashboard"}><big>{localization[lang].crmLoyalty}</big></Link><br/>
                                        {/* <big className="text-muted">{localization[lang].crmLoyalty}</big><br/> */}
                                        <br/>
                                        {/* <Link to={"/crm/counterparties"}><big>{localization[lang].crmCounterparties}</big></Link><br/> */}
                                        <big className="text-muted">{localization[lang].crmCounterparties}</big><br/>
                                        <br/>
                                        {/* <Link to={"/crm/feedback/campaigns"}><big>{localization[lang].crmFeedbackCampaigns}</big></Link><br/> */}
                                        <big className="text-muted">{localization[lang].crmFeedbackCampaigns}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].crmContracts}</big><br/>
                                        <br/>
                                        {/* <Link to={"/crm/youcontrol/summary"}><big>{localization[lang].crmYouControl}</big></Link><br/> */}
                                        <big className="text-muted">{localization[lang].crmYouControl}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].crmCustomerReturn}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].crmTestDrive}</big><br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <span className="display-4 text-muted" style={{fontSize: "2.2rem"}}><b>{localization[lang].fleet}</b></span><br/><br/>
                                        <big className="text-muted">{localization[lang].fleetOverview}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].fleetLogistics}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].fleetMaintenance}</big><br/>
                                        <br/>
                                    </div>
                                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <span className="display-4 text-muted" style={{fontSize: "2.2rem"}}><b>{localization[lang].iam}</b></span><br/><br/>
                                        {/* <Link to={"/iam/units"}><big>{localization[lang].iamUnits}</big></Link><br/> */}
                                        <big className="text-muted">{localization[lang].iamUnits}</big><br/>
                                        <br/>
                                        {/* <Link to={"/iam/users"}><big>{localization[lang].iamUsers}</big></Link><br/> */}
                                        <big className="text-muted">{localization[lang].iamUsers}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].iamAPIKeys}</big><br/>
                                        <br/>
                                        <big className="text-muted">{localization[lang].iamSessions}</big><br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <span className="display-4 text-muted" style={{fontSize: "2.2rem"}}><b>System</b></span><br/><br/>
                                        {/* <Link to={"/system/logs"}><big>Logs</big></Link><br/> */}
                                        <big className="text-muted">Logs</big><br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <span className="display-4 text-muted" style={{fontSize: "2.2rem"}}><b>{localization[lang].security}</b></span><br/><br/>
                                        <big className="text-muted">{localization[lang].securityPasses}</big><br/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Menu;
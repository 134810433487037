import React from 'react';
import axios from 'axios';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import uuid from 'uuid';
import BarLoader from "react-spinners/BarLoader";

import firebase from "firebase/app";
import "firebase/messaging";

import AppContext from "./AppContext.js";

import Auth from './auth.js';
import Notification from './desktop/components/notification.js';

/*
    MOBILE
*/
import MobileNavigation from './mobile/components/navigation.js';
import MobileMenu from './mobile/menu.js';

// import MobileServiceVehicles from './mobile/service/vehicles/index.js';
// import MobileCrmCounterparties from './mobile/crm/counterparties/index.js';
// import MobileIamUsers from './mobile/iam/users/index.js';

// import MobileServiceCheckLists from './mobile/service/check-lists/index.js';

// import MobileServiceFieldMaintenance from './mobile/service/field-maintenance/index.js';

/*
    DESKTOP
*/
import DesktopNavigation from './desktop/components/navigation.js';
import DesktopMenu from './desktop/menu.js';

/*
    Catalog
*/

/*
    Service
*/
import DesktopServiceLeadsRegistry from './desktop/service/leads/registry.js';
import DesktopServiceLeadsAnalytics from './desktop/service/leads/analytics.js';
import DesktopServiceLeadsSettings from './desktop/service/leads/settings';
import DesktopServiceLeadsCreate from './desktop/service/leads/create.js';
import DesktopServiceLead from './desktop/service/leads/lead.js';

import DesktopServiceVehicles from './desktop/service/vehicles/index.js';
import DesktopServiceWorkOrders from './desktop/service/work-orders/index.js';
import DesktopServiceWorkOrderCreate from './desktop/service/work-orders/create.js';

import DesktopServiceRecallCampaigns from './desktop/service/recall/campaigns.js';
import DesktopServiceRecallCampaignRegistry from './desktop/service/recall/registry.js';

/*
    CRM
*/
import DesktopCrmCounterparties from './desktop/crm/counterparties/index.js';

import DesktopCrmYoucontrol from './desktop/crm/youcontrol/index.js';

import DesktopCrmLoyaltyDashboard from './desktop/crm/loyalty/dashboard.js';
import DesktopCrmLoyaltyLeads from './desktop/crm/loyalty/leads.js';
import DesktopCrmLoyaltySettings from './desktop/crm/loyalty/settings.js';

import DesktopCrmFeedbackCampaigns from './desktop/crm/feedback/campaigns.js';
import DesktopCrmFeedbackCampaignDashboard from './desktop/crm/feedback/dashboard.js';
import DesktopCrmFeedbackCampaignLeads from './desktop/crm/feedback/leads.js';
import DesktopCrmFeedbackCampaignSettings from './desktop/crm/feedback/settings.js';

import DesktopCrmPhone from './desktop/crm/phone.js';

/*
    IAM
*/
import DesktopIamUnits from './desktop/iam/units/index.js';
import DesktopIamUnitSettings from './desktop/iam/units/settings.js';

import DesktopIamUsers from './desktop/iam/users/index.js';
import DesktopIamUserSettings from './desktop/iam/users/settings';

import DesktopIamProfile  from './desktop/iam/profile.js';

/*
    Off
*/
//import CatalogGoodsList from './pages/catalog/goods/list.js';
// import CatalogGoodsSearch from './pages/catalog/goods/search.js';
// import CatalogGoodsNew from './pages/catalog/goods/new.js';

// import CatalogGoodsDashboard from './pages/catalog/goods/dashboard.js';
// import CatalogGoodsAnalytics from './pages/catalog/goods/analytics.js';
// import CatalogGoodsCard from './pages/catalog/goods/card.js';
// import CatalogGoodsManufacturersList from './pages/catalog/goods-manufacturers/list.js';

// import StockReceiptsRegistry from './pages/stock/receipts/registry.js';
// import StockReceiptsDocument from './pages/stock/receipts/document.js';

// import CheckLists from './pages/service/check-lists/index.js';
// import CheckList from './pages/service/check-lists/edit.js';

// import ServiceStamping from './pages/service/stamping/index.js';

// import WorkOrders from './pages/service/work-orders/index.js';

//General styles
import './css/bootstrap.min.css';
import './css/app.css';
import './css/agis.css';
import './css/agis/btn-agis-link.css';
import './css/agis/btn-agis-primary.css';
import './css/agis/btn-agis-light.css';
import './css/agis/buttons.css';
import './css/agis/links.css';
import './css/agis/card.css';
import './css/agis/table.css';
import './css/error.css';
import './css/menu.css';
import './css/position-component.css'

//Special styles
import './css/special/question-input.css';
import './css/special/question-button.css';
import './css/special/question-link.css';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isAppInit: false,
            isUpdate: false,

            token: null,
            username: null,
            lang: null,
            datetime: "day.month.year hour:minute:second",
            scale: null,
            height: null,
            width: null,
            online: null,

            unitID: null,
            units: [],

            backButton: null,

            firebaseToken: null,

            navigationTitle: '',
            notifications: [],

            authLogin: (token, username, lang) => {
                localStorage.setItem("token", token);
                localStorage.setItem("username", username);
                localStorage.setItem("lang", lang);

                // this.setState({
                //     token: token,
                //     username: username,
                //     lang: lang,
                // });

                window.location.reload()
            },

            authLogout: () => {
                localStorage.removeItem("token");
                localStorage.removeItem("username");
                localStorage.removeItem("lang");

                window.location.reload();
            },

            runNotification: () => {
                firebase.initializeApp({
                    apiKey: "AIzaSyCyUSavNXhTLauuAN8sbdtzvCi53Pj6I80",
                    authDomain: "mif-core-scania-ukraine-test.firebaseapp.com",
                    projectId: "mif-core-scania-ukraine-test",
                    storageBucket: "mif-core-scania-ukraine-test.appspot.com",
                    messagingSenderId: "1074720561611",
                    appId: "1:1074720561611:web:00cd1bce60e0097b463e4e"
                });
            
                const messaging = firebase.messaging();
            
                var firebasePromise =  messaging.getToken({vapidKey: "BHXyS0cFl_oMmUzIWbwkIa6uS7lclN14_JFHYog_x9JWl2AEkNke8YWJQPPcjCic8yBj3ovcIK8Ky2IbAzeWdP4"});

                axios.all([firebasePromise]).then(axios.spread((firebaseToken) => {
                    if (firebaseToken) {
                        // Send the token to your server and update the UI if necessary
                        // ...
                        console.log(firebaseToken);
                        this.setState({firebaseToken: firebaseToken});
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                })).catch(error => {
                    //addNotification(error.response.data, error.response.status);
                }).finally(() => {
                    //removeNotification(u);
                });
            },

            settingsLang: (lang) => {
                localStorage.setItem("lang", lang);
                this.setState({ lang: lang });
            },

            setNavigationTitle: (text) => {
                window.scrollTo(0, 0);
                this.setState({ navigationTitle: text });
            },

            addNotification: (text, type) => {
                if (type == 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("username");
                    localStorage.removeItem("lang");
    
                    this.setState({token: null, isAppInit: true});
                }

                var u = uuid();
                var object = { id: u, type: type, text: text };

                var newNotifications = this.state.notifications;
                newNotifications.push(object);
                this.setState({ notifications: newNotifications });
                return u;
            },

            removeNotification: (id) => {
                var newNotifications = [];
                for (var i = 0; i < this.state.notifications.length; i++) {
                    if (this.state.notifications[i].id !== id) {
                        newNotifications.push(this.state.notifications[i]);
                    }
                }
                this.setState({ notifications: newNotifications });
            },
        }
    }

    componentDidMount() {
        var token;

        var username;
        var lang;
        var scale;

        var height;
        var width;

        var units = [];

        var backButton;
        
        /*
            Token init
        */
        token = localStorage.getItem("token");
        
        /*
            Default language init
        */
        lang = localStorage.getItem("lang");
        if (lang == null) {
            lang = 'ua';
        }

        /*
            If user unauthorized - skip init part of logic
        */
        if (!token) {
            this.setState({
                token: token,
                lang: lang,
                isAppInit: true
            });
            return
        }

        /*
            User name init
        */
        username = localStorage.getItem("username");
        if (username == null) {
            username = '';
        }

        /*
            Screen size init
        */
        height = window.innerHeight;
        width = window.innerWidth;

        if (width >= 1200) {
            scale = 'xl';
        } else if (width > 993) {
            scale = 'lg';
        } else if (width > 768) {
            scale = 'md';
        } else if (width > 576) {
            scale = 'sm';
        } else {
            scale = 'xs';
        }

        /*
           Back button
        */
        backButton = localStorage.getItem("backButton");
        if (backButton == null) {
            backButton = 'false';
        }

        /*
            Window events
        */
            window.addEventListener("resize", this.updateDimensions.bind(this));
            window.addEventListener('message', this.receiveMessage.bind(this));
            window.addEventListener('online', this.updateOnlineStatus.bind(this));
            window.addEventListener('offline', this.updateOnlineStatus.bind(this));

        /*
            Init requests
        */
        if (process.env.NODE_ENV === 'production') {
            var unitsPromise = axios.post('/api/iam/units/list', {}, { headers: { Authorization: `Bearer ${token}` }});

            axios.all([unitsPromise]).then(axios.spread((u) => {
                this.setState({
                    token: token,
                    username: username,
                    lang: lang,
                    scale: scale,
        
                    height: height,
                    width: width,
        
                    units: u.data,
                    unitID: u.data[0].unitID,

                    backButton: backButton,
        
                    online: navigator.onLine,
                    isAppInit: true
                });
            })).catch((error) => {
                this.setState({
                    lang: lang,
                    scale: scale,
        
                    height: height,
                    width: width,
        
                    online: navigator.onLine,
                }, () => {
                    this.state.addNotification(error.response.data, error.response.status);
                });
            });
        } else {
            this.setState({
                token: token,
                username: username,
                lang: lang,
                scale: scale,
    
                height: height,
                width: width,
    
                units: [
                    {
                        unitID: 1,
                        name: "Scania Kyiv - Makariv"
                    },
                    {
                        unitID: 2,
                        name: "Scania Kyiv - Brovary"
                    },
                ],
                unitID: 1,
    
                online: navigator.onLine,
                isAppInit: true
            });
        }
    }

    receiveMessage(event) {
        switch (event.data) {
            case "NEW_VERSION":
                this.setState({isUpdate: true});
                break;
            default: 
                console.log("MESSAGE");
                break;
        }
    }

    updateOnlineStatus() {
        this.setState({ online: navigator.onLine });
    }

    updateDimensions() {
        var height = window.innerHeight;
        var width = window.innerWidth;
        var scale;
        
		if (width >= 1200) {
			scale = 'xl';
		} else if (width > 993) {
			scale = 'lg';
		} else if (width > 768) {
			scale = 'md';
		} else if (width > 576) {
			scale = 'sm';
		} else {
			scale = 'xs';
        }

        this.setState({scale: scale, height: height, width: width});
    }

    render() {
        // return (
        //     <div style={{display: "block", position: "fixed", left: "0px", top: "0px", right: "0px", bottom: "0px", backgroundColor: "#fff"}}>
        //         <div style={{height: "85%", width: "100%"}} className="d-flex align-items-center justify-content-center">
        //             <div className="text-center">
        //                 <lottie-player src="https://lottie.host/6239b7e5-4015-40ef-9c4b-7b92b41095e5/eTZJ8iQxs1.json" background="transparent" speed="1" style={{width: '300px', height: '300px'}} loop autoplay></lottie-player>
        //                 {/* <h6 className="text-muted mt-2"><b>{this.stete.lang == "en" ? "Loading..." : this.stete.lang == "ru" ? "Загрузка..." : "Завантаження..."}</b></h6> */}
        //             </div>
        //         </div>
        //     </div>
        // )

        if (this.state.isAppInit) {
            if (this.state.isUpdate) {
                return (
                    <div style={{display: "block", position: "fixed", left: "0px", top: "0px", right: "0px", bottom: "0px", backgroundColor: "#fff"}}>
                        <div style={{height: "85%", width: "100%"}} className="d-flex align-items-center justify-content-center">
                            <div className="text-center">
                                <lottie-player src="https://lottie.host/6239b7e5-4015-40ef-9c4b-7b92b41095e5/eTZJ8iQxs1.json" background="transparent" speed="1" style={{width: '300px', height: '300px'}} loop autoplay></lottie-player>
                            </div>
                        </div>
                    </div>
                )
            }

            //if token exist
            if (this.state.token != null) {

                /*
                    MOBILE VERSION
                */
                if (this.state.scale == "xs" || this.state.scale == "sm") {
                    return (
                        <AppContext.Provider value={{ ...this.state }}>
                            <BrowserRouter basename={"/"}>
                                <MobileNavigation>
                                    <Notification />
    
                                    <Switch>
                                        {/* <Route exact path={`/service/vehicles`} component={MobileServiceVehicles} />
                                        <Route exact path={`/crm/counterparties`} component={MobileCrmCounterparties} />
                                        <Route exact path={`/iam/users`} component={MobileIamUsers} />

                                        <Route exact path={`/service/check-lists`} component={MobileServiceCheckLists} />

                                        <Route strict path={`/service/field-maintenance/:subpage`} component={MobileServiceFieldMaintenance} />
                                        <Redirect from="/service/field-maintenance/" to="/service/field-maintenance/work-orders" /> */}

                                        {/* <Route exact path={`/iam/profile`} component={MobileIamProfile} /> */}
                                        <Route exact path={`/`} component={MobileMenu} />
    
                                        <Route render={() => <Redirect to="/" />} />
                                    </Switch>
                                </MobileNavigation>
                            </BrowserRouter>
                        </AppContext.Provider>
                    );
                }

                /*
                    DESKTOP VERSION
                */
                return (
                    <AppContext.Provider value={{ ...this.state }}>
                        <BrowserRouter basename={"/"}>
                            <DesktopNavigation>
                                <Notification />

                                <Switch>
                                    {/* <Route exact path={`/catalog/goods/search`} component={CatalogGoodsSearch} />
                                    <Route exact path={`/catalog/goods/new`} component={CatalogGoodsNew} /> */}

                                    {/* <Route exact path={`/catalog/goods/dashboard`} component={CatalogGoodsDashboard} /> */}
                                    
                                    {/* <Route exact path={`/catalog/goods/analytics`} component={CatalogGoodsAnalytics} /> */}
                                    {/* <Route strict path={`/catalog/goods/:goodsID/card`} component={CatalogGoodsCard} />

                                    <Route exact path={`/stock/receipts/registry`} component={StockReceiptsRegistry} />
                                    <Route exact path={`/stock/receipts/document/:receiptID`} component={StockReceiptsDocument} /> */}

                                    {/* <Route exact path={`/catalog/goods-manufacturers`} component={CatalogGoodsManufacturersList} /> */}
                                    
                                    {/* <Route exact path={`/service/work-orders/:subpage`} component={WorkOrders} />
                                    <Redirect from="/service/work-orders" to="/service/work-orders/registry" />

                                    <Route exact path={`/service/check-lists`} component={CheckLists} />
                                    <Route strict path={`/service/check-list/:id`} component={CheckList} />

                                    <Route exact path={`/service/stamping`} component={ServiceStamping} /> */}

                                    <Route exact path={`/service/leads/registry`} component={DesktopServiceLeadsRegistry} />
                                    <Route exact path={`/service/leads/analytics`} component={DesktopServiceLeadsAnalytics} />
                                    <Route exact path={`/service/leads/settings`} component={DesktopServiceLeadsSettings} />
                                    <Route exact path={`/service/leads/create`} component={DesktopServiceLeadsCreate} />
                                    
                                    <Route strict path={`/service/lead/:leadID`} component={DesktopServiceLead} />

                                    <Route exact path={`/service/work-orders`} component={DesktopServiceWorkOrders} />

                                    <Route exact path={`/service/vehicles`} component={DesktopServiceVehicles} />

                                    <Route exact path={`/service/work-order/create`} component={DesktopServiceWorkOrderCreate} />

                                    <Route exact path={`/crm/counterparties`} component={DesktopCrmCounterparties} />

                                    <Route strict path={`/crm/youcontrol/:subpage`} component={DesktopCrmYoucontrol} />
                                    <Redirect from="/crm/youcontrol" to="/crm/youcontrol/summary" />

                                    {/* <Route exact path={`/crm/service/maintenance-reminder/leads`} component={DesktopCrmServiceMaintenanceReminderLeads} />
                                    <Route exact path={`/crm/service/maintenance-reminder/create`} component={DesktopCrmServiceMaintenanceReminderCreate} />
                                    <Route strict path={`/crm/service/maintenance-reminder/lead/:leadID`} component={DesktopCrmServiceMaintenanceReminderLead} />
                                    <Route exact path={`/crm/service/maintenance-reminder/analytics`} component={DesktopCrmServiceMaintenanceReminderAnalytics} />
                                    <Route exact path={`/crm/service/maintenance-reminder/settings`} component={DesktopCrmServiceMaintenanceReminderSettings} /> */}

                                    <Route exact path={`/crm/loyalty/dashboard`} component={DesktopCrmLoyaltyDashboard} />
                                    <Route exact path={`/crm/loyalty/leads`} component={DesktopCrmLoyaltyLeads} />
                                    <Route exact path={`/crm/loyalty/settings`} component={DesktopCrmLoyaltySettings} />

                                    <Route exact path={`/crm/feedback/campaigns`} component={DesktopCrmFeedbackCampaigns} />
                                    <Route exact path={`/crm/feedback/campaign/:campaignID/dashboard`} component={DesktopCrmFeedbackCampaignDashboard} />
                                    <Route exact path={`/crm/feedback/campaign/:campaignID/leads`} component={DesktopCrmFeedbackCampaignLeads} />
                                    <Route exact path={`/crm/feedback/campaign/:campaignID/settings`} component={DesktopCrmFeedbackCampaignSettings} />

                                    <Route exact path={`/iam/units`} component={DesktopIamUnits} />
                                    <Route strict path={`/iam/unit/:unitID/settings`} component={DesktopIamUnitSettings} />

                                    <Route exact path={`/iam/users`} component={DesktopIamUsers} />
                                    <Route strict path={`/iam/user/:userID/settings`} component={DesktopIamUserSettings} />

                                    <Route exact path={`/iam/profile`} component={DesktopIamProfile} />

                                    <Route exact path={`/service/recall/campaigns`} component={DesktopServiceRecallCampaigns} />
                                    <Route exact path={`/service/recall/campaign/:campaignID/registry`} component={DesktopServiceRecallCampaignRegistry} />

                                    <Route exact path={`/crm/phone`} component={DesktopCrmPhone} />

                                    <Route exact path={`/`} render={() => (<Redirect to="/service/leads/registry" />)} />

                                    <Route render={() => <Redirect to="/" />} />
                                </Switch>
                            </DesktopNavigation>
                        </BrowserRouter>
                    </AppContext.Provider>
                );
            } else {
                return (
                    <AppContext.Provider value={{ ...this.state }}>
                        <BrowserRouter basename={"/"}>
                            <div style={{height: "100%"}}>
                                <Notification />

                                <Switch>
                                    <Route exact path={`/`} component={Auth} />
                                    <Route render={() => <Redirect to="/" />} />
                                </Switch>

                                <br /><br />
                            </div>
                        </BrowserRouter>
                    </AppContext.Provider>
                );
            }
        }

        /*
            LOADING SCREEN
        */
        return (
            <div style={{display: "block", position: "fixed", left: "0px", top: "0px", right: "0px", bottom: "0px", backgroundColor: "#fff"}}>
                <div style={{height: "85%", width: "100%"}} className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                        <lottie-player src="https://lottie.host/6239b7e5-4015-40ef-9c4b-7b92b41095e5/eTZJ8iQxs1.json" background="transparent" speed="1" style={{width: '300px', height: '300px'}} loop autoplay></lottie-player>
                    </div>
                </div>
            </div>
        )
    }
}

export default App;
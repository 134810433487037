import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faCog, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';
import Submenu from '../../components/submenu.js';

import { intToISODate } from '../../../utils/datetime.js';

import Summary from './subpages/summary.js';
import Requests from './subpages/requests.js';

const localization = {
    ua: {
        title: 'YouControl',
        submenu: {
            summary: 'Статистика',
            requests: 'Запити',
            alert: 'Попередлення про перевищення ліміту',
            used: 'платних запитів використано',
        },
    },
    ru: {
        title: 'YouControl',
        submenu: {
            summary: 'Статистика',
            requests: 'Запросы',
            alert: 'Оповещение о превыщении лимита',
            used: 'платных запросов использовано',
        },
    },
    en: {
        title: 'YouControl',
        submenu: {
            summary: 'Summary',
            requests: 'Requests',
            alert: 'Billing alert',
            used: 'paid requests used',
        },
    },
}

class Index extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,

            operationalFrom: null,
            operationalTo: null,

            commitedFrom: null,
            commitedTo: null,
        }

        this.fromChange = this.fromChange.bind(this);
        this.toChange   = this.toChange.bind(this);

        this.fromCommit = this.fromCommit.bind(this);
        this.toCommit   = this.toCommit.bind(this);

        this.fromKeyPress = this.fromKeyPress.bind(this);
        this.toKeyPress   = this.toKeyPress.bind(this);

        this.prevMonth = this.prevMonth.bind(this);
        this.nextMonth = this.nextMonth.bind(this);
    }

    componentDidMount() {
        const {lang, setNavigationTitle} = this.context;
        setNavigationTitle(localization[lang].title);

        this.init();
    }

    init() {
        const {lang, token, addNotification, removeNotification} = this.context;

        var date = new Date(), y = date.getFullYear(), m = date.getMonth();

        //var from = Math.floor(new Date(y, m - 1, 1).getTime() / 1000);  //first day of prev month
        //var to = Math.floor(new Date(y, m, 0).getTime() / 1000);  //last day of prev month

        var from = Math.floor(new Date(y, m, 1, 0, 0, 0).getTime() / 1000);  //first day of current month
        var to = Math.floor(new Date(y, m+1, 0, 23, 59, 59).getTime() / 1000);  //last day of current month

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/crm/youcontrol/quote', {}, {
                headers: { Authorization: `Bearer ${token}` }
            }).then((res) => {
                this.setState({
                    isLoading: false,

                    quote: res.data.total,
        
                    operationalFrom: intToISODate(from),
                    operationalTo: intToISODate(to),
        
                    commitedFrom: from,
                    commitedTo: to,
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,

                quote: 7780,
    
                operationalFrom: intToISODate(from),
                operationalTo: intToISODate(to),
    
                commitedFrom: from,
                commitedTo: to,
            });
        }
    }

    fromChange(event) {
        this.setState({operationalFrom: event.target.value});
    }

    toChange(event) {
        this.setState({operationalTo: event.target.value});
    }

    fromCommit() {
        this.setState({commitedFrom: Math.floor(new Date(this.state.operationalFrom).getTime() / 1000)});
    }

    toCommit() {
        this.setState({commitedTo: Math.floor(new Date(this.state.operationalTo).getTime() / 1000)});
    }

    fromKeyPress(e) {
        if (e.key === 'Enter') {
            this.fromCommit();
        }
    }

    toKeyPress(e) {
        if (e.key === 'Enter') {
            this.toCommit();
        }
    }

    prevMonth() {
        var date = new Date(this.state.operationalFrom), y = date.getFullYear(), m = date.getMonth();

        var from = Math.floor(new Date(y, m - 1, 1, 0, 0, 0).getTime() / 1000);  //first day of prev month
        var to = Math.floor(new Date(y, m, 0, 23, 59, 59).getTime() / 1000);  //last day of prev month

        this.setState({
            operationalFrom: intToISODate(from),
            operationalTo: intToISODate(to),

            commitedFrom: from,
            commitedTo: to,
        });
    }

    nextMonth() {
        var date = new Date(this.state.operationalTo), y = date.getFullYear(), m = date.getMonth();

        var from = Math.floor(new Date(y, m + 1, 1, 0, 0, 0).getTime() / 1000);  //first day of next month
        var to = Math.floor(new Date(y, m + 2, 0, 23, 59, 59).getTime() / 1000);  //last day of next month

        this.setState({
            operationalFrom: intToISODate(from),
            operationalTo: intToISODate(to),

            commitedFrom: from,
            commitedTo: to,
        });
    }
    
    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        var perc = Math.round(100 / 10000 * this.state.quote);

        return (
            <div>
                <DocumentMeta {...meta} />

                <Submenu>
                    <div style={{backgroundColor: '#fff'}}>
                        <div className="d-flex align-items-center justify-content-between pl-3 pr-3" style={{minHeight: '50px'}}>
                            <div>
                                <FontAwesomeIcon icon={faChevronLeft} className="mr-3" style={{fontSize: "14px", color: "#6b6b6b"}} onClick={this.prevMonth} />
                                <input type="date" className="input-agis-block mr-2" style={{width: "140px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.operationalFrom} onChange={this.fromChange} onBlur={this.fromCommit} onKeyPress={this.fromKeyPress} />
                                <input type="date" className="input-agis-block" style={{width: "140px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.operationalTo} onChange={this.toChange} onBlur={this.toCommit} onKeyPress={this.toKeyPress} />
                                <FontAwesomeIcon icon={faChevronRight} className="ml-3 mr-4" style={{fontSize: "14px", color: "#6b6b6b"}} onClick={this.nextMonth} />
                                
                                <NavLink to={"/crm/youcontrol/summary"} className="nav-agis-light-outline ml-4 mr-3" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.summary}</NavLink>
                                <NavLink to={"/crm/youcontrol/requests"} className="nav-agis-light-outline mr-3" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.requests}</NavLink>
                            </div>
                            
                            <div className="d-flex align-items-center" style={{height: "50px"}}>
                                <div className="d-flex align-items-center justify-content-between" style={{
                                    display: "inline-block",
                                    padding: ".25rem .5rem",
                                    height: "35px",
                                    color: "#464a4e",
                                    border: "1px solid #ebebeb",
                                    borderRadius: "6px",
                                    boxShadow: "0 1px 1px rgb(0 0 0 / 15%)",

                                    fontSize: "12px",
                                    backgroundColor: "rgb(242, 242, 242)",
                                    color: "rgb(107, 107, 107)",
                                }}>
                                    <div style={{fontSize: "14px"}}><b>{localization[lang].submenu.alert}:</b></div>
                                    <div className="ml-3">
                                        <div style={{fontSize: "11px"}}><b>{this.state.quote} / 10000</b> {localization[lang].submenu.used}</div>
                                        <div class="progress mt-1" style={{height: "3px", minWidth: "100px"}}>
                                            <div class="progress-bar" role="progressbar" style={{width: perc+"%", backgroundColor: perc <= 100 ? "#0cbb52" : "#dc3545"}} aria-valuenow={perc} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    {/* <FontAwesomeIcon icon={faCog} style={{fontSize: "16px"}} /> */}
                                </div>
                            </div>

                            {/* <NavLink to={"/crm/youcontrol/settings"} className="nav-agis-light-outline mr-3" style={{height: '28px', lineHeight: "18px", minWidth: "0px", fontSize: "14px"}}>Settings</NavLink> */}
                            {/* <NavLink to={"/catalog/goods/dashboard"} className="nav-agis-light-outline mr-3" style={{height: '28px', lineHeight: "18px", minWidth: "0px", fontSize: "14px"}}>Dashboard</NavLink> */}
                            {/* <NavLink to={"/catalog/goods/analytics"} className="nav-agis-light-outline mr-3" style={{height: '28px', lineHeight: "18px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].analytics}</NavLink> */}
                            {/* <NavLink to={"/catalog/goods/123/card"} className="nav-agis-light-outline mr-3" style={{height: '28px', lineHeight: "18px", minWidth: "0px", fontSize: "14px"}}>Card</NavLink> */}
                        </div>
                    </div>
                </Submenu>

                <Switch>
                    
                    <Route exact path={`/crm/youcontrol/summary`}           children={<Summary from={this.state.commitedFrom} to={this.state.commitedTo} />} />
                    <Route strict path={`/crm/youcontrol/requests/:unitID`} children={({ match }) => (<Requests from={this.state.commitedFrom} to={this.state.commitedTo} match={match} />)} />
                    <Route exact path={`/crm/youcontrol/requests`}          children={({ match }) => (<Requests from={this.state.commitedFrom} to={this.state.commitedTo} match={match} />)} />
                </Switch>

            </div>
        )
    }
}

export default Index;
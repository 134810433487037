import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faCog, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';

const localization = {
    ua: {
        title: 'Контрагенти',
        table: {
            name: 'Назва',
            stateRegistrationCode: 'ЄДРПОУ або ІПН',
            phone: 'Телефон',
            phoneAlternative: 'Телефон',
            email: 'Пошта',
            address: 'Адреса',
            details: 'Детальніше',
        },
    },
    ru: {
        title: 'Контрагенты',
        table: {
            name: 'Название',
            stateRegistrationCode: 'ЕДРПОУ или ИНН',
            phone: 'Телефон',
            phoneAlternative: 'Телефон',
            email: 'Почта',
            address: 'Адрес',
            details: 'Детальнее',
        },
    },
    en: {
        title: 'Counterparties',
        table: {
            name: 'Name',
            stateRegistrationCode: 'State registration code',
            phone: 'Phone',
            phoneAlternative: 'Phone alt.',
            email: 'Email',
            address: 'Address',
            details: 'Details',
        },
    },
}

class Index extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,

            counterparties: []
        }
    }

    componentDidMount() {
        const {lang, setNavigationTitle} = this.context;
        setNavigationTitle(localization[lang].title);

        this.init();
    }

    init() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/crm/counterparties/list', {}, {
                headers: { Authorization: `Bearer ${token}` }
            }).then((res) => {
                this.setState({
                    isLoading: false,
                    counterparties: res.data,
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                counterparties: [
                    {
                        counterpartyID: 1, 
                        company: false,
                        name: "Yevhenii Butenko",
                        nameFull: null,
                        stateRegistrationCode: "12345678",
                        phone: "380508673516",
                        phoneAlternative: null,
                        email: "yevhenii.butenko@agis.group",
                        address: "Киев. Пчёлки 4",
                    }
                ],
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />

                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-10 offset-1">
                            <table className="table table-bordered table-hover" style={{fontSize: "12px"}}>
                                <thead>
                                    <tr style={{height: "30px", backgroundColor: "#f4f4f4"}}>
                                        <th class="align-middle text-center">{localization[lang].table.name}</th>
                                        <th class="align-middle text-center" style={{width: "180px"}}>{localization[lang].table.stateRegistrationCode}</th>
                                        <th class="align-middle text-center" style={{width: "150px"}}>{localization[lang].table.phone}</th>
                                        <th class="align-middle text-center" style={{width: "150px"}}>{localization[lang].table.phoneAlternative}</th>
                                        <th class="align-middle text-center" style={{width: "200px"}}>{localization[lang].table.email}</th>
                                        <th class="align-middle text-center" style={{width: "200px"}}>{localization[lang].table.address}</th>
                                        <th style={{width: "100px"}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.counterparties.map((item) => {
                                        return (
                                            <tr style={{height: "46px"}} key={"row_" + item.counterpartyID}>
                                                <td class="align-middle pt-1 pb-1">{item.name}</td>
                                                <td class="align-middle pt-1 pb-1">{item.stateRegistrationCode}</td>
                                                <td class="align-middle pt-1 pb-1">{item.phone}</td>
                                                <td class="align-middle pt-1 pb-1">{item.phoneAlternative}</td>
                                                <td class="align-middle pt-1 pb-1">{item.email}</td>
                                                <td class="align-middle pt-1 pb-1">{item.address}</td>
                                                {/* <td class="align-middle text-center pt-1 pb-1"><Link to={"/crm/counterparty/" + item.counterpartyID + "/card"}>{localization[lang].table.details}</Link></td> */}
                                                <td class="align-middle text-center pt-1 pb-1">{localization[lang].table.details}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Index;
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import AppContext from '../../../AppContext.js';
import Submenu from '../../components/submenu.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faDownload, faEnvelope, faKey, faPhone, faPlus, faSearch, faUser } from '@fortawesome/free-solid-svg-icons'

import { intToDateTimeFormat, intToISODate } from '../../../utils/datetime.js';

const localization = {
    ua: {
        title: 'Сервісні ліди',
        submenu: {
            leads: 'Ліди',
            analytics : 'Аналітика',
            settings: 'Налаштування',
        },
        search: {
            units: "Всі юніти",
            types: "Всі типи",
            mine: "Тільни мої",
            responsibles: "Всі відповідальні",
        },
        table: {
            status: 'Статус',
            open: "Відкритий",
            closed: "Закритий",
            created: 'Створений',
            responsible: 'Відповідальний',
            title: 'Тема',
            description: 'Опис',
            representative: 'Клієнт',
            vehicle: 'Автообіль',
            details: "Детальніше",
            empty: "Немає результатів",
        }
    },
    ru: {
        title: 'Сервисные лиды',
        submenu: {
            leads: 'Лиды',
            analytics : 'Аналитика',
            settings: 'Настройки',
        },
        search: {
            units: "Все юниты",
            types: "Все типы",
            mine: "Только мои",
            responsibles: "Все ответственные",
        },
        table: {
            status: 'Статус',
            open: "Открытый",
            closed: "Закрытый",
            created: 'Создан',
            responsible: 'Ответственный',
            title: 'Тема',
            description: 'Описание',
            representative: 'Клиент',
            vehicle: 'Автомобиль',
            details: "Детальнее",
            empty: "Нет результатов",
        }
    },
    en: {
        title: 'Service leads',
        submenu: {
            leads: 'Leads',
            analytics : 'Analytics',
            settings: 'Settings',
        },
        search: {
            units: "All units",
            types: "All types",
            mine: "Mine",
            responsibles: "All responsibles",
        },
        table: {
            status: 'Status',
            open: "Open",
            closed: "Closed",
            created: 'Сreated',
            responsible: 'Responsible',
            title: 'Title',
            description: 'Description',
            representative: 'Client',
            vehicle: 'Vehicle',
            details: "Details",
            empty: "No result",
        }
    },
}

class Leads extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            redirect: null,

            units: null,
            unitID: null,

            types: [],
            typeID: null,

            results: [],

            responsibles: [],
            responsibleID: null,

            filterIsOpen: true,
            filterIsMine: true,

            list: [],
            missed: [],
            scheduled: [],
        }

        this.unitChange = this.unitChange.bind(this);
        this.typeChange = this.typeChange.bind(this);
        this.responsibleChange = this.responsibleChange.bind(this);

        this.filterIsOpenChange = this.filterIsOpenChange.bind(this);

        this.exportToFile = this.exportToFile.bind(this);
    }

    componentDidMount() {
        const {lang, token, addNotification, removeNotification, setNavigationTitle, units, unitID} = this.context;
        setNavigationTitle(localization[lang].title);

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            var typesPromise = axios.post('/api/service/lead/types', {}, { headers: { Authorization: `Bearer ${token}` }})
            var resultsPromise = axios.post('/api/service/lead/results', {}, { headers: { Authorization: `Bearer ${token}` }})
            var responsiblesPromise = axios.post('/api/service/lead/responsibles', {}, { headers: { Authorization: `Bearer ${token}` }})
            
            axios.all([typesPromise, resultsPromise, responsiblesPromise]).then(axios.spread((types, results, responsibles) => {
                this.setState({
                    units: units, 
                    unitID: 0,
                    types: types.data,
                    typeID: 0,
                    results: results.data,
                    responsibles: responsibles.data,
                    responsibleID: 0,
                }, () => {
                    this.list();
                });
            })).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                units: units, 
                unitID: 0,
                types: [
                    {
                        typeID: 1,
                        name: "Нагадування про ТО"
                    },
                    {
                        typeID: 2,
                        name: "Відкличні кампанії"
                    },
                ],
                typeID: 0,
                results: [
                    {
                        resultID: 100,
                        item: 1,
                        name: "Запис на сервіс",
                        active: true,
                    },
                    {
                        resultID: 200,
                        item: 1,
                        name: "Продав авто",
                        active: true,
                    },
                    {
                        resultID: 201,
                        item: 2,
                        name: "Неприйнятлива ціна",
                        active: true,
                    },
                    {
                        resultID: 202,
                        item: 3,
                        name: "Клієнт іншого дилера",
                        active: true,
                    },
                    {
                        resultID: 203,
                        item: 4,
                        name: "Причина невідома",
                        active: true,
                    },
                    {
                        resultID: 204,
                        item: 5,
                        name: "Клієнт відмовив онлайн",
                        active: true,
                    },
                    {
                        resultID: 300,
                        item: 1,
                        name: "Зателефонувати пізніше",
                        active: true,
                    },
                ],
                responsibles: [
                    {
                        userID: 1,
                        name: "Yevhenii Butenko",
                        typeID: 1,
                    },
                    {
                        userID: 2,
                        name: "Anhelina Butenko",
                        typeID: 2,
                    },
                ],
                responsibleID: 0,
            }, () => {
                this.list();
            });
        }
    }

    list() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/service/leads', {
                unitID: this.state.unitID,
                typeID: this.state.typeID,
                responsibleID: this.state.responsibleID,
                open: this.state.filterIsOpen,
                mine: this.state.filterIsMine,
            }, { headers: { Authorization: `Bearer ${token}` }}).then((res) => {
                this.setState({
                    isLoading: false,
                    list: res.data,
                    missed: res.data.filter(l => l.missedCalls > 0)
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,

                list: [
                    {
                        leadID: 1,
                        unitID: 1,
                        created: 1624448528,
                        responsible: "Вася Пупкин",
                        typeID: 1,
                        typeName: "Нагадування про ТО",
                        description: "Вид: По пробігу, Дата: 15.11.2021, через 14 днів, графік ТО: M на пробігу 75000 км.",
                        unitName: "Scania Kyiv - Makariv",
                        counterpartyID: 1,
                        counterpartyName: "Бутенко Евгений Олегович",
                        counterpartyPhone: "+380508673516",
                        vehicleID: 1,
                        manufacturer: "Honda",
                        model: "Accord",
                        year: 2018,
                        missedCalls: 0,
                        scheduledCalls: 1,
                        smsSent: null,
                        closed: null,
                        resultID: null,
                        resultRemark: null,
                    },
                    {
                        leadID: 2,
                        unitID: 2,
                        created: 1624448528,
                        responsible: "Вася Пупкин",
                        typeID: 1,
                        typeName: "Нагадування про ТО",
                        description: "Вид: По пробігу, Дата: 15.11.2021, через 14 днів, графік ТО: M на пробігу 75000 км.",
                        unitName: "Scania Kyiv - Makariv",
                        counterpartyID: 1,
                        counterpartyName: "Бутенко Евгений Олегович",
                        counterpartyPhone: "+380508673516",
                        vehicleID: 1,
                        manufacturer: "Honda",
                        model: "Accord",
                        year: 2018,
                        missedCalls: 0,
                        scheduledCalls: 0,
                        smsSent: 1624448528,
                        closed: null,
                        resultID: null,
                        resultRemark: null,
                    },
                    {
                        leadID: 3,
                        unitID: 2,
                        created: 1624448528,
                        responsible: "Вася Пупкин",
                        typeID: 1,
                        typeName: "Нагадування про ТО",
                        description: "Вид: По пробігу, Дата: 15.11.2021, через 14 днів, графік ТО: M на пробігу 75000 км.",
                        unitName: "Scania Kyiv - Makariv",
                        counterpartyID: 1,
                        counterpartyName: "Бутенко Евгений Олегович",
                        counterpartyPhone: "+380508673516",
                        vehicleID: 1,
                        manufacturer: "Honda",
                        model: "Accord",
                        year: 2018,
                        missedCalls: 0,
                        scheduledCalls: 0,
                        smsSent: 1624448528,
                        closed: 1624448528,
                        resultID: 100,
                        resultRemark: "Good.",
                    },
                    {
                        leadID: 4,
                        unitID: 2,
                        created: 1624448528,
                        responsible: "Вася Пупкин",
                        typeID: 1,
                        typeName: "Нагадування про ТО",
                        description: "Вид: По пробігу, Дата: 15.11.2021, через 14 днів, графік ТО: M на пробігу 75000 км.",
                        unitName: "Scania Kyiv - Makariv",
                        counterpartyID: 1,
                        counterpartyName: "Бутенко Евгений Олегович",
                        counterpartyPhone: "+380508673516",
                        vehicleID: 1,
                        manufacturer: "Honda",
                        model: "Accord",
                        year: 2018,
                        missedCalls: 0,
                        scheduledCalls: 0,
                        smsSent: 1624448528,
                        closed: 1624948528,
                        resultID: 204,
                        resultRemark: "Bad",
                    },
                ],

                missed: [
                    {
                        leadID: 1,
                        unitID: 1,
                        created: 1624448528,
                        responsible: "Вася Пупкин",
                        typeID: 1,
                        typeName: "Нагадування про ТО",
                        description: "Вид: По пробігу, Дата: 15.11.2021, через 14 днів, графік ТО: M на пробігу 75000 км.",
                        unitName: "Scania Kyiv - Makariv",
                        counterpartyID: 1,
                        counterpartyName: "Бутенко Евгений Олегович",
                        counterpartyPhone: "+380508673516",
                        vehicleID: 1,
                        manufacturer: "Honda",
                        model: "Accord",
                        year: 2018,
                        missedCalls: 2,
                        scheduledCalls: 2,
                        smsSent: 1624448528,
                        closed: null,
                        resultID: null,
                        resultRemark: null,
                    },
                ]
            });
        }
    }

    unitChange(event) {
        this.setState({unitID: Number(event.target.value)}, () => this.list());
    }

    typeChange(event) {
        this.setState({typeID: Number(event.target.value)}, () => this.list());
    }

    responsibleChange(event) {
        this.setState({responsibleID: Number(event.target.value)}, () => this.list());
    }

    filterIsOpenChange(event) {
        this.setState({filterIsOpen: event.target.checked}, () => this.list());
    }

    exportToFile() {
        const {lang, datetime} = this.context;
        
        let csvContent = "data:text/csv;charset=utf-8," 
            + this.state.list.map(item => [
                item.resultID ? localization[lang].table.closed : localization[lang].table.open,
                item.resultID ? this.state.results.find(x => x.resultID == item.resultID).name : "",
                item.resultRemark ? item.resultRemark : "",
                item.resultID ? intToDateTimeFormat(item.closed, datetime) : intToDateTimeFormat(item.created, datetime),
                item.typeName,
                item.description ? item.description : "",
                item.counterpartyName,
                item.counterpartyPhone,
                (item.manufacturer ? item.manufacturer + " " : "") + (item.model ? item.model + " " : "") + (item.year ? item.year : ""),
            ].join(";")).join("\n");

        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }

    renderMissed() {
        const {lang, datetime} = this.context;

        if (this.state.missed.length === 0) {
            return (
                <div className="row">
                    <div className="col-10 offset-1">
                        <div className="card-agis pt-2 pb-2" style={{fontSize: "12px"}}>
                            <div className="d-flex align-items-top justify-content-between">
                                <h6 className="text-muted font-weight-light mb-1">{localization[lang].table.empty}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.missed.map((item, i) => {
                var result = this.state.results.find(r => r.resultID == item.resultID)
                if (!result) {
                    result = localization[lang].table.closed
                }

                return (
                    <div className="row" key={"row_" + item.leadID}>
                        <div className="col-10 offset-1">
                            <div className="card-agis position-component pt-2 pb-2" style={{fontSize: "12px"}}>
                                <div className="row">
                                    <div className="col-2 d-flex align-items-center justify-content-between">
                                        <div>
                                            {item.resultID ? item.resultID < 200 ? 
                                                <button className="question-button" style={{height: "22px", width: "100%", lineHeight:"18px", fontSize: "12px", fontWeight: "400", backgroundColor: "#d4edda", color: "#155724"}}>
                                                    <b>{result.name}</b>
                                                </button>
                                                :  
                                                <button className="question-button" style={{height: "22px", width: "100%", lineHeight:"18px", fontSize: "12px", fontWeight: "400", backgroundColor: "#f8d7da", color: "#721c24"}}>
                                                    <b><span style={{fontSize: "12px"}}>{result.name}</span></b>
                                                </button>
                                                : 
                                                <button className="question-button" style={{height: "22px", width: "100%", lineHeight:"18px", fontSize: "12px", fontWeight: "400", backgroundColor: "#f3f3f3"}}>
                                                    <b>{localization[lang].table.open}</b>
                                                </button>
                                            }
                                            <div className="mt-1">{item.resultID ? intToDateTimeFormat(item.closed, datetime) : intToDateTimeFormat(item.created, datetime)}</div>
                                        </div>
                                        <div className="px-3 py-1" style={{borderRadius: "6px", backgroundColor: "#f8f9fa", color: "#646464", fontSize: ".8rem"}} title={item.responsible}>
                                            <FontAwesomeIcon icon={faUser} style={{fontSize: "12px"}} />
                                        </div>
                                    </div>
                                    <div className="col-5 d-flex align-items-start justify-content-between">
                                        <div>
                                            <div>{item.typeName}</div>
                                            <div>{item.description ? item.description : ""}</div>
                                        </div>
                                    </div>
                                    <div className="col-3 d-flex align-items-start justify-content-between">
                                        <div>
                                            <div>{item.counterpartyName}</div>
                                            <div>{(item.manufacturer ? item.manufacturer + " " : "") + (item.model ? item.model + " " : "") + (item.year ? item.year : "")}</div>
                                        </div>
                                    </div>
                                    <div className="col-2 d-flex align-items-center justify-content-between">
                                        <div className="px-3 py-1" style={{borderRadius: "6px", backgroundColor: "#edb14d", color: "#fff", fontSize: ".8rem"}}>
                                            <FontAwesomeIcon icon={faPhone} style={{fontSize: "12px"}} />
                                        </div>
                                        <Link to={"/service/lead/" + item.leadID}>{localization[lang].table.details}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    renderList() {
        const {lang, datetime} = this.context;

        if (this.state.list.length === 0) {
            return (
                <div className="row">
                    <div className="col-10 offset-1">
                        <div className="card-agis pt-2 pb-2" style={{fontSize: "12px"}}>
                            <div className="d-flex align-items-top justify-content-between">
                                <h6 className="text-muted font-weight-light mb-1">{localization[lang].table.empty}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return this.state.list.map((item, i) => {
                var result = this.state.results.find(r => r.resultID == item.resultID)
                if (!result) {
                    result = localization[lang].table.closed
                }

                return (
                    <div className="row" key={"row_" + item.leadID}>
                        <div className="col-10 offset-1">
                            <div className="card-agis position-component pt-2 pb-2" style={{fontSize: "12px"}}>
                                <div className="row">
                                    <div className="col-2 d-flex align-items-center justify-content-between">
                                        <div>
                                            {item.resultID ? item.resultID < 200 ? 
                                                <button className="question-button" style={{height: "22px", width: "100%", lineHeight:"18px", fontSize: "12px", fontWeight: "400", backgroundColor: "#d4edda", color: "#155724"}}>
                                                    <b>{result.name}</b>
                                                </button>
                                                :  
                                                <button className="question-button" style={{height: "22px", width: "100%", lineHeight:"18px", fontSize: "12px", fontWeight: "400", backgroundColor: "#f8d7da", color: "#721c24"}}>
                                                    <b><span style={{fontSize: "12px"}}>{result.name}</span></b>
                                                </button>
                                                : 
                                                <button className="question-button" style={{height: "22px", width: "100%", lineHeight:"18px", fontSize: "12px", fontWeight: "400", backgroundColor: "#f3f3f3"}}>
                                                    <b>{localization[lang].table.open}</b>
                                                </button>
                                            }
                                            <div className="mt-1">{item.resultID ? intToDateTimeFormat(item.closed, datetime) : intToDateTimeFormat(item.created, datetime)}</div>
                                        </div>
                                        <div className="px-3 py-1" style={{borderRadius: "6px", backgroundColor: "#f8f9fa", color: "#646464", fontSize: ".8rem"}} title={item.responsible}>
                                            <FontAwesomeIcon icon={faUser} style={{fontSize: "12px"}} />
                                        </div>
                                    </div>
                                    <div className="col-5 d-flex align-items-start justify-content-between">
                                        <div>
                                            <div>{item.typeName}</div>
                                            <div>{item.description ? item.description : ""}</div>
                                        </div>
                                    </div>
                                    <div className="col-3 d-flex align-items-start justify-content-between">
                                        <div>
                                            <div>{item.counterpartyName}</div>
                                            <div>{(item.manufacturer ? item.manufacturer + " " : "") + (item.model ? item.model + " " : "") + (item.year ? item.year : "")}</div>
                                        </div>
                                    </div>
                                    <div className="col-2 d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center justify-content-start">
                                            {item.smsSent && (
                                                <div className="px-3 py-1" style={{borderRadius: "6px", backgroundColor: "#f8f9fa", color: "#646464", fontSize: ".8rem"}}>
                                                    <FontAwesomeIcon icon={faEnvelope} style={{fontSize: "12px"}} />
                                                </div>
                                            )}
                                            {item.scheduledCalls > 0 && (
                                                <div className="px-3 py-1" style={{borderRadius: "6px", backgroundColor: "#f8f9fa", color: "#646464", fontSize: ".8rem"}}>
                                                    <FontAwesomeIcon icon={faPhone} style={{fontSize: "12px"}} />
                                                </div>
                                            )}
                                        </div>
                                        <Link to={"/service/lead/" + item.leadID}>{localization[lang].table.details}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        if (this.state.redirect != null) {
            return <Redirect push to={this.state.redirect} />
        }

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                
                {/* <Submenu>
                    <div style={{backgroundColor: '#fff'}}>
                        <div className="d-flex align-items-center justify-content-between" style={{minHeight: '50px'}}>
                            <div>
                                <NavLink to={"/service/leads/registry"} className="nav-agis-light-outline ml-4 mr-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.leads}</NavLink>
                                <NavLink to={"/service/leads/analytics"} className="nav-agis-light-outline ml-4 mr-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.analytics}</NavLink>
                                <NavLink to={"/service/leads/settings"} className="nav-agis-light-outline ml-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.settings}</NavLink>
                            </div>
                        </div>
                    </div>
                </Submenu> */}

                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-10 offset-1">
                            <div className="d-flex align-items-center justify-content-between mb-4" style={{minHeight: '50px'}}>
                                <div>
                                    <select className="input-agis-block mr-2" style={{width: "200px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.unitID.toString()} onChange={this.unitChange}>
                                        <option value="0">{localization[lang].search.units}</option>
                                        {this.state.units.map((item) => {
                                            return (
                                                <option value={item.unitID}>{item.name}</option>
                                            )
                                        })}
                                    </select>

                                    <select className="input-agis-block mr-2" style={{width: "200px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.typeID.toString()} onChange={this.typeChange}>
                                        <option value="0">{localization[lang].search.types}</option>
                                        {this.state.types.map((item) => {
                                            return (
                                                <option value={item.typeID}>{item.name}</option>
                                            )
                                        })}
                                    </select>

                                    <select className="input-agis-block mr-2" style={{width: "200px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.responsibleID.toString()} onChange={this.responsibleChange}>
                                        <option value="-1">{localization[lang].search.responsibles}</option>
                                        <option value="0">{localization[lang].search.mine}</option>
                                        {this.state.responsibles.map((item) => {
                                            return (
                                                <option value={item.userID}>{item.name}</option>
                                            )
                                        })}
                                    </select>

                                    <label className="ml-3">
                                        <input type="checkbox" checked={this.state.filterIsOpen} onChange={this.filterIsOpenChange} />
                                        <span className="ml-1" style={{fontSize: "12px", color: "#6b6b6b"}}>Тільки відкриті</span>
                                    </label>
                                </div>
                                <div>
                                    <button onClick={this.exportToFile} className="question-button w-100" style={{height: "35px", maxWidth: "100%", fontSize: "14px", fontWeight: "300", backgroundColor: "#f3f3f3"}}>
                                        <FontAwesomeIcon icon={faDownload} className="ml-2 mr-2" style={{fontSize: "14px", color: "#a0a0a0"}} />
                                        <span className="mr-2">Експорт</span>
                                    </button>

                                    {/* <button onClick={() => this.setState({redirect: "/service/leads/create"})} className="question-button w-100" style={{height: "35px", maxWidth: "100%", fontSize: "14px", fontWeight: "300", backgroundColor: "#f3f3f3"}}>
                                        <FontAwesomeIcon icon={faPlus} className="ml-2 mr-2" style={{fontSize: "14px", color: "#a0a0a0"}} />
                                        <span className="mr-2">Створити лід</span>
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {this.state.missed.length > 0 && (
                        <div>
                            <div className="row">
                                <div className="col-10 offset-1">
                                    <h5 className="text-muted font-weight-light mb-1">Очікують дзвінка</h5>
                                </div>
                            </div>
                            {this.renderMissed()}
                            <br/>
                            <br/>
                        </div>
                    )}

                    <div className="row">
                        <div className="col-10 offset-1">
                            <h5 className="text-muted font-weight-light mb-1">Ліди</h5>
                        </div>
                    </div>
                    {this.renderList()}
                </div>
            </div>
        )
    }
}

export default Leads;
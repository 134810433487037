import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import AppContext from '../../../AppContext.js';
import Submenu from '../../components/submenu.js';
import DatePicker from '../../components/datePicker.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faKey, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

import { intToDateTimeFormat, intToISODate } from '../../../utils/datetime.js';

const localization = {
    ua: {
        title: 'Loyalty - Leads',
        submenu: {
            dashboard: 'Dashboard',
            leads: 'Leads',
            settings: 'Settings',
        },
        table: {
            status: 'Status',
            open: 'Open',
            closed: 'Closed',
            created: 'Created',
            unit: 'Юніт',
            respondent: 'Respondent',
            phone: 'Phone',
            anonym: 'Anonym',
            nps: 'NPS',
            csi: 'CSI',
            remark: 'Remark',
            empty: "No result",
        }
    },
    ru: {
        title: 'Loyalty - Leads',
        submenu: {
            dashboard: 'Dashboard',
            leads: 'Leads',
            settings: 'Settings',
        },
        table: {
            status: 'Status',
            open: 'Open',
            closed: 'Closed',
            created: 'Created',
            unit: 'Юніт',
            respondent: 'Respondent',
            phone: 'Phone',
            anonym: 'Anonym',
            nps: 'NPS',
            csi: 'CSI',
            remark: 'Remark',
            empty: "No result",
        }
    },
    en: {
        title: 'Loyalty - Leads',
        submenu: {
            dashboard: 'Dashboard',
            leads: 'Leads',
            settings: 'Settings',
        },
        table: {
            status: 'Status',
            open: 'Open',
            closed: 'Closed',
            created: 'Created',
            unit: 'Юніт',
            respondent: 'Respondent',
            phone: 'Phone',
            anonym: 'Anonym',
            nps: 'NPS',
            csi: 'CSI',
            remark: 'Remark',
            empty: "No result",
        }
    },
}

class Leads extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            redirect: null,

            units: null,
            unitID: null,

            operationalFrom: null,
            operationalTo: null,

            commitedFrom: null,
            commitedTo: null,

            list: [],
        }

        this.unitChange = this.unitChange.bind(this);

        this.fromChange = this.fromChange.bind(this);
        this.toChange   = this.toChange.bind(this);

        this.fromCommit = this.fromCommit.bind(this);
        this.toCommit   = this.toCommit.bind(this);

        this.fromKeyPress = this.fromKeyPress.bind(this);
        this.toKeyPress   = this.toKeyPress.bind(this);

        this.prevMonth = this.prevMonth.bind(this);
        this.nextMonth = this.nextMonth.bind(this);
    }

    componentDidMount() {
        const {lang, setNavigationTitle, token, addNotification, removeNotification, units, unitID} = this.context;
        setNavigationTitle(localization[lang].title);

        var date = new Date(), y = date.getFullYear(), m = date.getMonth();

        //var from = Math.floor(new Date(y, m - 1, 1).getTime() / 1000);  //first day of prev month
        //var to = Math.floor(new Date(y, m, 0).getTime() / 1000);  //last day of prev month

        var from = Math.floor(new Date(y, m, 1, 0, 0, 0).getTime() / 1000);  //first day of current month
        var to = Math.floor(new Date(y, m+1, 0, 23, 59, 59).getTime() / 1000);  //last day of current month

        this.setState({
            //isLoading: false,

            units: units,
            unitID: 0,

            operationalFrom: intToISODate(from),
            operationalTo: intToISODate(to),

            commitedFrom: from,
            commitedTo: to,
        }, () => this.list());
    }

    list() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/crm/loyalty/leads', {
                unitID: this.state.unitID,
                from: this.state.commitedFrom,
                to: this.state.commitedTo,
                open: false
            }, { headers: { Authorization: `Bearer ${token}` }}).then((res) => {
                this.setState({
                    isLoading: false,
                    list: res.data
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,

                list: [
                    {
                        leadID: 1,
                        unitID: 1,
                        unitName: "Scania Kyiv Makariv",
                        created: Math.floor(new Date().getTime() / 1000),
                        respondent: "Yevhenii",
                        phone: "380508673516",
                        nps: null,
                        csi: null,
                        remark: null,
                        closed: null,
                    },
                ]
            });
        }
    }

    unitChange(event) {
        this.setState({unitID: Number(event.target.value)}, () => this.list());
    }

    fromChange(event) {
        this.setState({operationalFrom: event.target.value});
    }

    toChange(event) {
        this.setState({operationalTo: event.target.value});
    }

    fromKeyPress(e) {
        if (e.key === 'Enter') {
            this.fromCommit();
        }
    }

    toKeyPress(e) {
        if (e.key === 'Enter') {
            this.toCommit();
        }
    }

    fromCommit() {
        this.setState({commitedFrom: Math.floor(new Date(this.state.operationalFrom).getTime() / 1000)}, () => this.list());
    }

    toCommit() {
        this.setState({commitedTo: Math.floor(new Date(this.state.operationalTo).getTime() / 1000)}, () => this.list());
    }

    prevMonth() {
        var date = new Date(this.state.operationalFrom), y = date.getFullYear(), m = date.getMonth();

        var from = Math.floor(new Date(y, m - 1, 1, 0, 0, 0).getTime() / 1000);  //first day of prev month
        var to = Math.floor(new Date(y, m, 0, 23, 59, 59).getTime() / 1000);  //last day of prev month

        this.setState({
            operationalFrom: intToISODate(from),
            operationalTo: intToISODate(to),

            commitedFrom: from,
            commitedTo: to,
        }, () => this.list());
    }

    nextMonth() {
        var date = new Date(this.state.operationalTo), y = date.getFullYear(), m = date.getMonth();

        var from = Math.floor(new Date(y, m + 1, 1, 0, 0, 0).getTime() / 1000);  //first day of next month
        var to = Math.floor(new Date(y, m + 2, 0, 23, 59, 59).getTime() / 1000);  //last day of next month

        this.setState({
            operationalFrom: intToISODate(from),
            operationalTo: intToISODate(to),

            commitedFrom: from,
            commitedTo: to,
        }, () => this.list());
    }

    renderTable() {
        const {lang, datetime} = this.context;

        if (this.state.list.length === 0) {
            return (
                <tr>
                    <td colSpan="8">{localization[lang].table.empty}</td>
                </tr>
            );
        } else {
            return this.state.list.map((item, i) => {
                return (
                    <tr style={{height: "46px"}} key={"row_" + item.leadID}>
                        <td class="align-middle text-center pt-1 pb-1"><b>{item.closed ? localization[lang].table.closed : localization[lang].table.open}</b></td>
                        <td class="align-middle text-center pt-1 pb-1">{intToDateTimeFormat(item.created, datetime)}</td>
                        <td class="align-middle pt-1 pb-1">{item.unitName}</td>
                        <td class="align-middle pt-1 pb-1">{item.respondent ? item.respondent : <span className="text-muted">{localization[lang].table.anonym}</span>}</td>
                        <td class="align-middle pt-1 pb-1">{item.phone ? item.phone : <span className="text-muted">{localization[lang].table.anonym}</span>}</td>
                        <td class="align-middle text-center pt-1 pb-1"><b>{item.nps ? item.nps : "-"}</b></td>
                        <td class="align-middle text-center pt-1 pb-1"><b>{item.csi ? item.csi : "-"}</b></td>
                        <td class="align-middle pt-1 pb-1">{item.remark}</td>
                    </tr>
                );
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                
                <Submenu>
                    <div style={{backgroundColor: '#fff'}}>
                        <div className="d-flex align-items-center justify-content-between" style={{minHeight: '50px'}}>
                            <div>
                                <NavLink to={"/crm/loyalty/dashboard"} className="nav-agis-light-outline ml-4 mr-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.dashboard}</NavLink>
                                <NavLink to={"/crm/loyalty/leads"} className="nav-agis-light-outline ml-4 mr-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.leads}</NavLink>
                                {/* <NavLink to={"/crm/loyalty/settings"} className="nav-agis-light-outline ml-4" style={{height: '32px', lineHeight: "24px", minWidth: "0px", fontSize: "14px"}}>{localization[lang].submenu.settings}</NavLink> */}
                            </div>
                        </div>
                    </div>
                </Submenu>

                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-10 offset-1">
                            <div className="d-flex align-items-center justify-content-between mb-4" style={{minHeight: '50px'}}>
                                <div>
                                    <select className="input-agis-block mr-2" style={{width: "200px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.unitID} onChange={this.unitChange}>
                                        <option value="0">All units</option>
                                        {this.state.units.map((item) => {
                                            return (
                                                <option value={item.unitID}>{item.name}</option>
                                            )
                                        })}
                                    </select>

                                    <FontAwesomeIcon icon={faChevronLeft} className="ml-4 mr-3" style={{fontSize: "14px", color: "#6b6b6b"}} onClick={this.prevMonth} />
                                    <input type="date" className="input-agis-block mr-2" style={{width: "140px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.operationalFrom} onChange={this.fromChange} onBlur={this.fromCommit} onKeyPress={this.fromKeyPress} />
                                    <input type="date" className="input-agis-block" style={{width: "140px", fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={this.state.operationalTo} onChange={this.toChange} onBlur={this.toCommit} onKeyPress={this.toKeyPress} />
                                    <FontAwesomeIcon icon={faChevronRight} className="ml-3 mr-4" style={{fontSize: "14px", color: "#6b6b6b"}} onClick={this.nextMonth} />
                                </div>
                            </div>
                            
                            <table className="table table-bordered table-hover" style={{fontSize: "12px"}}>
                                <thead>
                                    <tr style={{height: "30px", backgroundColor: "#f4f4f4"}}>
                                        <th class="align-middle text-center" style={{width: "100px"}}>{localization[lang].table.status}</th>
                                        <th class="align-middle text-center" style={{width: "180px"}}>{localization[lang].table.created}</th>
                                        <th class="align-middle text-center">{localization[lang].table.unit}</th>
                                        <th class="align-middle text-center" style={{width: "300px"}}>{localization[lang].table.respondent}</th>
                                        <th class="align-middle text-center" style={{width: "300px"}}>{localization[lang].table.phone}</th>
                                        <th class="align-middle text-center" style={{width: "60px"}}>{localization[lang].table.nps}</th>
                                        <th class="align-middle text-center" style={{width: "60px"}}>{localization[lang].table.csi}</th>
                                        <th class="align-middle text-center" style={{width: "400px"}}>{localization[lang].table.remark}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderTable()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Leads;
import React from 'react';
import { Link } from 'react-router-dom'
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBasket, faHome, faPhone, faThList, faBriefcase } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext';

class datePicker extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        }

        this.onDateChange = this.onDateChange.bind(this);
    }

    componentDidMount() {
    }

    onDateChange(event){
        console.log(event.target.value);
        //this.setState({price: floatvalue});
        //this.props.onChange(floatvalue);
    }

    render() {
        const { scale } = this.context;

        if (this.state.isLoading) {
            return (
                <div></div>
            );
        }

        var value = this.props.value
        //var value = this.props.value / 100;

        return (
            <div>
                <input type="date" className={this.props.className} style={this.props.style} valueAsNumber={value} onChange={this.props.onChange} />
            </div>
        )
    }
}

export default datePicker;
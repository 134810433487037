import React from 'react';
import { Link, NavLink } from 'react-router-dom'
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faCog, faCopy, faUpload, faDownload, faFileMedical, faChevronDown, faBuilding, faSuitcase, faGamepad } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../AppContext';

const localization = {
    ua: {
        title: "Логістика грузової техніки",
        myActs: "Мої акти",
        registry: "Реєстр",
        settings: "Налаштування",
    },
    ru: {
        title: "Логистика грузовой техники",
        myActs: "Мои акты",
        registry: "Реестр",
        settings: "Настройки",
    },
    pl: {
        title: "Logistyka samochodów ciężarowych",
        myActs: "Moje akty",
        registry: "Rejestr",
        settings: "Ustawienia",
    },
    en: {
        title: "Trucks logistics",
        myActs: "My acts",
        registry: "Registry",
        settings: "Settings",
    },
}

class Navigation extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: false,

            unitToogleShowed: false,
        }
        
        this.unitToogleButton = React.createRef();
        this.unitToogleExpand = React.createRef();

        // this.serviceToogleButton = React.createRef();
        // this.serviceToogleExpand = React.createRef();
        
        this.unitToogleOutside = this.unitToogleOutside.bind(this);

        this.unitChange = this.unitChange.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.unitToogleOutside);

        let coords = this.unitToogleButton.current.getBoundingClientRect();
        this.unitToogleExpand.current.style.left = coords.left + "px";
        this.unitToogleExpand.current.style.top = coords.bottom - 3 + "px";

        // coords = this.serviceToogleButton.current.getBoundingClientRect();
        // this.serviceToogleExpand.current.style.left = coords.left + "px";
        // this.serviceToogleExpand.current.style.top = coords.bottom - 3 + "px";
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.unitToogleOutside);
    }

    unitToogleOutside(event) {
        if ((!this.unitToogleExpand.current.contains(event.target)) && (this.unitToogleButton && !this.unitToogleButton.current.contains(event.target))) {
            this.setState({
                unitToogleShowed: false,
                serviceToogleShowed: false,
            });
        }

        // if ((!this.serviceToogleExpand.current.contains(event.target)) && (this.serviceToogleButton && !this.serviceToogleButton.current.contains(event.target))) {
        //     this.setState({
        //         unitToogleShowed: false,
        //         serviceToogleShowed: false,
        //     });
        // }
    }

    unitChange(unitID) {
        const {lang, token, addNotification, removeNotification} = this.context;

        window.location.reload();

        // if (process.env.NODE_ENV === 'production') {
        //     console.log('production');

        //     var u = addNotification();

        //     axios.post('/api/crm/service/maintenance-reminder/leads', {
        //         unitID: unitID,
        //     }, { headers: { Authorization: `Bearer ${token}` }}).then((res) => {
        //         window.location.reload();
        //     }).catch((error) => {
        //         addNotification(error.response.data, error.response.status);
        //     }).finally(() => {
        //         removeNotification(u);
        //     });
        // } else {
        //     window.location.reload();
        // }
    }

    render() {
        const {lang, username, scale, navigationTitle, units, unitID} = this.context;

        if (this.state.isLoading) {
            return <div></div>
        }

        if (scale == "xs" || scale == "sm") {
            return (
                <div style={{height: "100%"}}>

                    {this.props.children}

                    <div style={{
                        height: "30px",
                    }}></div>

                    <div style={{position: "fixed", bottom: "20px", right: "5px", height: '50px', width: "50px", backgroundColor: '#fff'}}>
                        <div className="d-flex align-items-center justify-content-center">
                            <Link to={"/"} className="" style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                                userSelect: "none",
                                cursor: "pointer",
                                fontSize: "14px",
                                lineHeight: "48px",
                                color: "#5f6368",
                                backgroundColor: "white", 
                            }}>
                                <svg focusable="false" viewBox="0 0 24 24" style={{height:"26px", color: "#5f6368"}}><path fill="currentColor" d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path></svg>
                            </Link>
                        </div>
                    </div>

                </div>
            )
        }

        return (
            <div style={{height: "100%"}}>
                <div className="fixed-top main-menu d-flex justify-content-between" style={{height: '50px', lineHeight: '50px', backgroundColor: '#fff', borderTop: "1px solid rgba(0,0,0,.0)", boxShadow: "0 3px 6px 0 rgba(32,33,36,0.28)"}}>
                    <div className="d-flex justify-content-start" style={{width: "33%"}}>
                        {/* <Link to={"/"} className="pl-3 pr-3" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            cursor: "pointer",
                            fontSize: "14px",
                            lineHeight: "48px",
                            color: "#5f6368",
                            backgroundColor: "white", 
                        }}>
                            <svg focusable="false" viewBox="0 0 24 24" style={{height:"26px", color: "#5f6368"}}><path fill="currentColor" d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path></svg>
                        </Link> */}

                        <div className="pl-3 pr-2" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            fontSize: "14px",
                            lineHeight: "48px",
                            color: "#6b6b6b",
                            backgroundColor: "white", 
                        }}>
                            <span class="navbar-brand mb-0 h1">AGIS</span>
                        </div>

                        <div ref={this.unitToogleButton} className="pl-2 pr-3" onClick={() => this.setState({unitToogleShowed: !this.state.unitToogleShowed})} style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            cursor: "pointer",
                            lineHeight: "48px",
                            color: "#6b6b6b",
                            backgroundColor: "white", 
                        }}>
                            <img src="/serve/configuration/favicon.ico" style={{height: "24px", filter: "grayscale(5%) opacity(77%)"}} />
                            <span className="ml-2 mr-2" style={{fontSize: "14px"}}>{units.find(u => u.unitID == unitID).name}</span>
                            <FontAwesomeIcon icon={faChevronDown} style={{fontSize: "12px"}} />
                        </div>

                        {/* <div className="pl-3" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            cursor: "pointer",
                            fontSize: "14px",
                            lineHeight: "48px",
                            color: "#6b6b6b",
                            backgroundColor: "white", 
                        }}>
                            <span className="mr-1">Главный склад</span> <FontAwesomeIcon icon={faChevronDown} style={{fontSize: "12px"}} />
                        </div> */}
                    </div>
                    
                    <div className="d-flex justify-content-center" style={{width: "33%", fontSize: "14px", fontWeight: "500"}}>
                        <Link to={"/"} className="pl-3 pr-4" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            cursor: "pointer",
                            fontSize: "14px",
                            lineHeight: "48px",
                            color: "#6b6b6b",
                            backgroundColor: "white", 
                        }}>
                            Сервіс
                        </Link>
                        {/* <div ref={this.serviceToogleButton} className="pl-2 pr-3" onClick={() => this.setState({serviceToogleShowed: !this.state.serviceToogleShowed})} style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            cursor: "pointer",
                            lineHeight: "48px",
                            color: "#6b6b6b",
                            backgroundColor: "white", 
                        }}>
                            <span className="ml-2 mr-2" style={{fontSize: "14px"}}>Сервіс</span>
                        </div> */}
                        <div className="pl-3 pr-4" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            fontSize: "14px",
                            lineHeight: "48px",
                            color: "#b5b3b5",
                            backgroundColor: "white", 
                        }}>
                            Продаж
                        </div>
                        <div className="pl-3 pr-4" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            fontSize: "14px",
                            lineHeight: "48px",
                            color: "#b5b3b5",
                            backgroundColor: "white", 
                        }}>
                            Склад
                        </div>
                        <div className="pl-3 pr-4" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            fontSize: "14px",
                            lineHeight: "48px",
                            color: "#b5b3b5",
                            backgroundColor: "white", 
                        }}>
                            Каталог
                        </div>
                        <Link to={"/service/leads/analytics"} className="pl-3 pr-4" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            cursor: "pointer",
                            fontSize: "14px",
                            lineHeight: "48px",
                            color: "#6b6b6b",
                            backgroundColor: "white", 
                        }}>
                            Аналітика
                        </Link>
                        <Link to={"/service/leads/settings"} className="pl-3 pr-4" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            cursor: "pointer",
                            fontSize: "14px",
                            lineHeight: "48px",
                            color: "#6b6b6b",
                            backgroundColor: "white", 
                        }}>
                            Налаштування
                        </Link>
                    </div>

                    <div className="d-flex justify-content-end" style={{width: "33%"}}>
                        <Link to={"/iam/profile"} className="pl-3 pr-4" style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            userSelect: "none",
                            cursor: "pointer",
                            fontSize: "14px",
                            lineHeight: "48px",
                            color: "#6b6b6b",
                            backgroundColor: "white", 
                        }}>
                            {username}
                        </Link>
                    </div>
                </div>

                <div ref={this.unitToogleExpand} className="card-agis" style={{
                    zIndex: "150",
                    position: "absolute",
                    top: "32px",
                    left: "116px",
                    display: this.state.unitToogleShowed ? "flex" : "none",

                    width: "360px",
                    minWidth: "400px",
                    minHeight: "240px",

                    padding: "20px 15px",

                    background: "#fff",
                    borderRadius: "3px",
                    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 40%)",

                    cursor: "default",
                }}>
                    <div style={{position: "relative", width: "100%", overflow: "auto"}}>
                        <h6 className="text-muted font-weight-light mb-2">The units you have access to:</h6>


                        {units.map((item) => {
                            return (
                                <div onClick={() => this.unitChange(item.unitID)} className="question-link px-2" style={{display: "flex", justifyContent: "flex-start", alignItems: "center", height: "50px", width: "100%", fontSize: "14px", color: "#212121"}}>
                                    <img src="/serve/configuration/favicon.ico" style={{height: "26px", filter: "grayscale(5%) opacity(77%)"}} />
                                    <span className="ml-2">{item.name}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div ref={this.serviceToogleExpand} className="card-agis" style={{
                    zIndex: "150",
                    position: "absolute",
                    top: "32px",
                    left: "116px",
                    display: this.state.serviceToogleShowed ? "flex" : "none",

                    width: "360px",
                    minWidth: "400px",
                    minHeight: "240px",

                    padding: "20px 15px",

                    background: "#fff",
                    borderRadius: "3px",
                    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 40%)",

                    cursor: "default",
                }}>
                    <div style={{position: "relative", width: "100%", overflow: "auto"}}>
                        <h6 className="text-muted font-weight-light mb-2">The units you have access to:</h6>

                    </div>
                </div>

                <div style={{height: "80px"}}></div>

                {this.props.children}

                <div style={{height: "50px"}}></div>
            </div>
        )
    }
}

export default Navigation;
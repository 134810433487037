import React from 'react'
import { Link, NavLink, Redirect } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronLeft, faChevronRight, faKey, faPlus, faSave, faSearch } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';

import CounterpartyPicker from '../../components/counterpartyPicker.js';
import VehiclePicker from '../../components/vehiclePicker.js';

const localization = {
    ua: {
        title: 'Сервісні ліди',
        submenu: {
            dashboard: 'Dashboard',
            leads: 'Leads',
            settings: 'Settings',
        },
    },
    ru: {
        title: 'Сервисные лиды',
        submenu: {
            dashboard: 'Dashboard',
            leads: 'Leads',
            settings: 'Settings',
        },
    },
    en: {
        title: 'Service leads',
        submenu: {
            dashboard: 'Dashboard',
            leads: 'Leads',
            settings: 'Settings',
        },
    },
}

class Create extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            redirect: null,

            title:       null,
            description: null,

            counterpartyID: null,
            name:           null,
            phone:          null,

            vehicleID:          null,
            manufacturer:       null,
            model:              null,
            registrationNumber: null,
        }

        this.leadTitleChange = this.leadTitleChange.bind(this);
        this.leadTitleSet    = this.leadTitleSet.bind(this);

        this.leadDescriptionChange = this.leadDescriptionChange.bind(this);

        this.counterpartyFound       = this.counterpartyFound.bind(this);
        this.counterpartyNameChange  = this.counterpartyNameChange.bind(this);
        this.counterpartyPhoneChange = this.counterpartyPhoneChange.bind(this);
        
        this.vehicleFound                    = this.vehicleFound.bind(this);
        this.vehicleManufacturerChange       = this.vehicleManufacturerChange.bind(this);
        this.vehicleModelChange              = this.vehicleModelChange.bind(this);
        this.vehicleRegistrationNumberChange = this.vehicleRegistrationNumberChange.bind(this);

        this.leadCreate = this.leadCreate.bind(this);
    }

    componentDidMount() {
        const {lang, setNavigationTitle, unitID} = this.context;
        setNavigationTitle(localization[lang].title);

        this.setState({isLoading: false, user: "User - " + unitID})
    }

    leadTitleChange(event) {
        this.setState({title: event.target.value ? event.target.value : null});
    }

    leadTitleSet(value) {
        this.setState({title: value});
    }

    leadDescriptionChange(event) {
        this.setState({description: event.target.value ? event.target.value : null});
    }

    counterpartyNameChange(event) {
        this.setState({name: event.target.value ? event.target.value : null});
    }

    counterpartyPhoneChange(event) {
        this.setState({phone: event.target.value ? event.target.value : null});
    }

    counterpartyFound(item) {
        this.setState({
            counterpartyID: item.counterpartyID,
            name:           item.name,
            phone:          item.phone,
        });
    }

    vehicleManufacturerChange(event) {
        this.setState({manufacturer: event.target.value ? event.target.value : null});
    }

    vehicleModelChange(event) {
        this.setState({model: event.target.value ? event.target.value : null});
    }

    vehicleRegistrationNumberChange(event) {
        this.setState({registrationNumber: event.target.value ? event.target.value : null});
    }

    vehicleFound(item) {
        this.setState({
            vehicleID:          item.vehicleID,
            manufacturer:       item.manufacturer,
            model:              item.model,
            registrationNumber: item.registrationNumber,
        });
    }

    leadCreate() {
        const {lang, unitID, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/service/lead/create', { 
                unitID: unitID,
            
                title:       this.state.title,
                description: this.state.description,

                counterpartyID: this.state.counterpartyID,
                name:           this.state.name,
                phone:          this.state.phone,

                vehicleID:          this.state.vehicleID,
                manufacturer:       this.state.manufacturer,
                model:              this.state.model,
                registrationNumber: this.state.registrationNumber,
            }, { headers: { Authorization: `Bearer ${token}` }}).then((res) => {
                this.setState({redirect: "/service/lead/" + res.data.leadID});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({redirect: "/service/lead/1"});
        }
    }

    render() {
        const {lang} = this.context;

        if (this.state.redirect != null) {
            return <Redirect push to={this.state.redirect} />
        }

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
                
                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-4 offset-4">
                            <div className="card-agis pl-2 pt-3 pb-3 pr-2">
                                <input type="text" autoComplete={false} className="transparent-input text-muted" style={{width: "100%", fontSize: "2rem"}} value={this.state.title ? this.state.title : ""} placeholder="Тема..." onChange={this.leadTitleChange} />
                                <div className="d-flex align-items-center justify-content-start">
                                    <button className="question-button link mr-3" style={{fontSize: "80%"}} onClick={() => this.leadTitleSet("Запис на сервіс")}>Запис на сервіс</button>
                                    <button className="question-button link mr-3" style={{fontSize: "80%"}} onClick={() => this.leadTitleSet("Запис на ТО")}>Запис на ТО</button>
                                    <button className="question-button link mr-3" style={{fontSize: "80%"}} onClick={() => this.leadTitleSet("Гарантійний ремонт")}>Гарантійний ремонт</button>
                                </div>

                                <br/>

                                <input type="text" autoComplete={false} className="transparent-input text-muted" style={{width: "100%", fontSize: "1rem"}} value={this.state.description ? this.state.description : ""} placeholder="Опис..." onChange={this.leadDescriptionChange} />

                                <br/>

                                <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="text-muted font-weight-light mb-1">Контрагент</h5>
                                    <CounterpartyPicker className="transparent-input underline" placeholder={"Пошук..."} action={this.counterpartyFound} />
                                </div>

                                <div className="row">
                                    <div className="col-8">
                                        <small class="form-text text-muted pl-2">Назва компанії / П.І.Б.</small>
                                        <input type="text" class="question-input" value={this.state.name ? this.state.name : ""} onChange={this.counterpartyNameChange} />
                                    </div>
                                    <div className="col-4">
                                        <small class="form-text text-muted pl-1">Телефон</small>
                                        <input type="text" class="question-input" value={this.state.phone ? this.state.phone : ""} onChange={this.counterpartyPhoneChange} />
                                    </div>
                                </div>

                                <br/>
                                <br/>

                                <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="text-muted font-weight-light mb-1">Авто</h5>
                                    <VehiclePicker className="transparent-input underline" placeholder={"Пошук..."} action={this.vehicleFound} />
                                </div>

                                <div className="row">
                                    <div className="col-4">
                                        <small class="form-text text-muted pl-2">Виробник</small>
                                        <input type="text" class="question-input" value={this.state.manufacturer ? this.state.manufacturer : ""} onChange={this.vehicleManufacturerChange} />
                                    </div>
                                    <div className="col-4">
                                        <small class="form-text text-muted pl-1">Модель</small>
                                        <input type="text" class="question-input" value={this.state.model ? this.state.model : ""} onChange={this.vehicleModelChange} />
                                    </div>
                                    <div className="col-4">
                                        <small class="form-text text-muted pl-1">Реєстраційний номер</small>
                                        <input type="text" class="question-input" value={this.state.registrationNumber ? this.state.registrationNumber : ""} onChange={this.vehicleRegistrationNumberChange} />
                                    </div>
                                </div>

                                <br/>

                                <div className="row">
                                    <div className="col-12 text-right">
                                        <button onClick={this.leadCreate} className="question-button w-25" style={{height: "35px", maxWidth: "100%", fontSize: "14px", fontWeight: "300", backgroundColor: "#f3f3f3"}}>
                                            <FontAwesomeIcon icon={faCheck} className="ml-2 mr-2" style={{fontSize: "14px", color: "#a0a0a0"}} />
                                            <span className="mr-2">Створити лід</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Create;
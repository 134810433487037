import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faClock, faCog, faComment, faEnvelope, faHammer, faMinus, faPhone, faPlus, faSearch, faThumbsDown, faThumbsUp, faUserEdit, faWrench } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';

import { intToDateTimeFormat, intToISODate } from '../../../utils/datetime.js';

import CounterpartyPicker from '../../components/counterpartyPicker.js';
import VehiclePicker from '../../components/vehiclePicker.js';

import Preview from '../../components/preview.js';

const localization = {
    ua: {
        title: 'Сервісні ліди',
    },
    ru: {
        title: 'Сервисные лиды',
    },
    en: {
        title: 'Service leads',
    },
}

class Index extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
            isCounterpartyLoading: true,
            isVehicleLoading: true,
            isWorkOrdersLoading: true,
            isWorkOrderLoading: true,

            /*
                Lead
            */
            leadID:             null,
            externalID:         null,
            unitID:             null,
            created:            null,
            typeID:             null,
            typeName:           null,
            offerURL:           null,
            description:        null,
            counterpartyID:     null,
            name:               null,
            phone:              null,
            vehicleID:          null,
            manufacturer:       null,
            model:              null,
            registrationNumber: null,
            smsSent:            null,
            emailSent:          null,
            closed:             null,
            resultID:           null,
            resultRemark:       null,

            /*
                Counterparty
            */
            company:               null,
            name:                  null,
            nameFull:              null,
            stateRegistrationCode: null,
            phone:                 null,
            phoneAlternative:      null,
            email:                 null,
            address:               null,

            /*
                Vehicle
            */
            vin:                null,
            serialNumber:       null,
            manufacturer:       null,
            model:              null,
            year:               null,
            mileage:            null,
            registrationNumber: null,

            /*
                History
            */
            loyalty: [],
            recommendations: [],
            workOrders: [],
            calls: [],

            /*
                Work order
            */
            workOrderID: null,
            workOrderPlannedDate: Math.floor(Date.now() / 1000),
            workOrderJobs: [],

            /*
                Call
            */
            isCreateCallOpened: false,
            callDate:           Math.floor(Date.now() / 1000),
            callRemark:         "",

            /*
                Lead
            */
            leadRemark: "",
            results: [],
        }

        /*
            Lead
        */
        this.changeLeadDescription = this.changeLeadDescription.bind(this);
        this.blurLeadDescription   = this.blurLeadDescription.bind(this);
        this.pressLeadDescription  = this.pressLeadDescription.bind(this);
        this.saveLeadDescription   = this.saveLeadDescription.bind(this);

        this.leadOfferSMS   = this.leadOfferSMS.bind(this);
        this.leadOfferEmail = this.leadOfferEmail.bind(this);
        
        /*
            Counterparty
        */
        this.counterpartyFound = this.counterpartyFound.bind(this);
            
        // this.changePhone                 = this.changePhone.bind(this);
        // this.changePhoneAlternative      = this.changePhoneAlternative.bind(this);
        // this.changeEmail                 = this.changeEmail.bind(this);
        // this.changeAddress               = this.changeAddress.bind(this);
        // this.counterpartySave            = this.counterpartySave.bind(this);

        /*
            Vehicle
        */
        this.vehicleFound = this.vehicleFound.bind(this);

        this.vehicleMileageChange    = this.vehicleMileageChange.bind(this);
        this.vehicleMileageBlur      = this.vehicleMileageBlur.bind(this);
        this.vehicleMileageKeyPress  = this.vehicleMileageKeyPress.bind(this);
        this.vehicleMileageSave      = this.vehicleMileageSave.bind(this);

        /*
            Work order
        */
        this.workOrderPlannedDateChange = this.workOrderPlannedDateChange.bind(this);
        this.workOrderJobAdd            = this.workOrderJobAdd.bind(this);
        this.workOrderJobChange         = this.workOrderJobChange.bind(this);
        this.workOrderJobRemove         = this.workOrderJobRemove.bind(this);
        this.workOrderCreate            = this.workOrderCreate.bind(this);

        /*
            Calls
        */
        this.callDateChange   = this.callDateChange.bind(this);
        this.callDateSet      = this.callDateSet.bind(this);
        this.callRemarkChange = this.callRemarkChange.bind(this);
        this.callCreate       = this.callCreate.bind(this);

        /*
            Lead cancel
        */
        this.leadRemarkChange = this.leadRemarkChange.bind(this);
        this.leadClose = this.leadClose.bind(this);
    }

    componentDidMount() {
        const {lang, setNavigationTitle} = this.context;
        setNavigationTitle(localization[lang].title);

        this.init();
    }

    init() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var leadID = Number(this.props.match.params.leadID);

            var u = addNotification();

            var leadPromise      = axios.post('/api/service/lead/card',            { leadID: leadID }, { headers: { Authorization: `Bearer ${token}` }});
            var workOrderPromise = axios.post('/api/service/lead/workOrder',       { leadID: leadID }, { headers: { Authorization: `Bearer ${token}` }});
            var callsPromise     = axios.post('/api/service/lead/calls',           { leadID: leadID }, { headers: { Authorization: `Bearer ${token}` }});
            var resultsPromise   = axios.post('/api/service/lead/result/byLeadID', { leadID: leadID }, { headers: { Authorization: `Bearer ${token}` }});

            axios.all([leadPromise, workOrderPromise, callsPromise, resultsPromise]).then(axios.spread((lead, workOrder, calls, results) => {
                this.setState({
                    isLoading: false,

                    leadID: leadID,
                    externalID: lead.data.externalID,
                    unitID: lead.data.unitID,
                    created: lead.data.created,
                    typeID: lead.data.typeID,
                    typeName: lead.data.typeName,
                    offerURL: lead.data.offerURL,
                    description: lead.data.description,
                    counterpartyID: lead.data.counterpartyID,
                    name: lead.data.name,
                    phone: lead.data.phone,
                    vehicleID: lead.data.vehicleID,
                    manufacturer: lead.data.manufacturer,
                    model: lead.data.model,
                    registrationNumber: lead.data.registrationNumber,
                    smsSent: lead.data.smsSent,
                    emailSent: lead.data.emailSent,
                    closed: lead.data.closed,
                    resultID: lead.data.resultID,
                    resultRemark: lead.data.resultRemark,

                    workOrderID: workOrder.data.workOrderID,
                    //workOrderPlannedDate: workOrder.plannedDate,
                    //workOrderJobs: workOrder.jobs,

                    calls: calls.data,
                    results: results.data,

                    // Recommendations
                    recommendations: [
                        // {
                        //     id: 1,
                        //     name: "ТЕСТ. Швидко закінчився кондиціонер",
                        //     description: "Підчас попередньої заправки кондиціонера, разом с фріоном, була заправлена маркерна фарба для виявлення пошкоджень в системі кондиціювання. Необхідно дослідити систему на факт протікання.",
                        //     files: ["https://howcarworks.ru/sites/default/files/field/image/2015-04-06_222959.jpg"]
                        // },
                    ],

                    // Work orders
                    workOrders: [
                        // {
                        //     id: 1,
                        //     name: "ТЕСТ. ЗН 10355",
                        //     jobs: [
                        //         {
                        //             id: 1,
                        //             name: "ТО 45000",
                        //         },
                        //         {
                        //             id: 2,
                        //             name: "Заправка кондиціонера",
                        //         },
                        //     ]
                        // }
                    ],

                    loyalty: [
                        // {
                        //     id: 1,
                        //     nps: 3,
                        //     csi: 3,
                        //     remark: "ТЕСТ. Гірка кава",
                        // }
                    ]
                }, () => {
                    this.counterpartyLoad();
                    this.vehicleLoad();
                    this.workOrderLoad();
                    this.workOrdersLoad();
                });
            })).catch(error => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });     
        } else {
            this.setState({
                isLoading: false,

                // Lead
                leadID: 1,
                externalID: "cc7788de-c93e-11eb-becc-6e6b2cbec74b",
                unitID: 2,
                created: 1,

                typeID: 2,
                typeName: "Нагадування про ТО",
                offerURL: "https://agis.yume-honda.com.ua/public/service/lead/" + this.state.externalID,
                description: null,

                counterpartyID: 1,
                name: "Бутенко Евгений Олегович",
                phone: "380508673516",

                vehicleID: 1,
                manufacturer: "Honda",
                model: "Accord",
                registrationNumber: "AA2231KA",

                smsSent: 1624277372,
                emailSent: null,
                closed: 1,
                resultID: null,
                resultRemark: null,

                workOrderID: null,
                workOrderPlannedDate: null,

                // Calls
                calls: [
                    {
                        callID: 1,
                        created: 1638352800,
                        planned: 1638352800,
                        done: 1,
                        remark: "Какой-то не уверенный клиент. Скорее всего не запишется.",
                    },
                    {
                        callID: 2,
                        created: 1638871200,
                        planned: 1638871200,
                        done: null,
                        remark: null,
                    },
                ],

                results: [
                    {
                        resultID: 100,
                        item: 1,
                        name: "Запис на сервіс",
                        active: true,
                    },
                    {
                        resultID: 200,
                        item: 1,
                        name: "Продав авто",
                        active: true,
                    },
                    {
                        resultID: 201,
                        item: 2,
                        name: "Неприйнятлива ціна",
                        active: true,
                    },
                    {
                        resultID: 202,
                        item: 3,
                        name: "Клієнт іншого дилера",
                        active: true,
                    },
                    {
                        resultID: 203,
                        item: 4,
                        name: "Причина невідома",
                        active: true,
                    },
                    {
                        resultID: 204,
                        item: 5,
                        name: "Клієнт відмовив онлайн",
                        active: true,
                    },
                    {
                        resultID: 300,
                        item: 1,
                        name: "Зателефонувати пізніше",
                        active: true,
                    },
                ],

                // Counterparty
                // company: false,
                // name: "Бутенко Евгений Олегович",
                // nameFull: "Бутенко Евгений Олегович",
                // stateRegistrationCode: null,
                // phone: "380508673516",
                // phoneAlternative: null,
                // email: "evgeniybutenko@gmail.com",
                // address: "м.Київ, вул. Луценка Дмитра, 15, блок 17, кім. 15",

                // Vehicle
                // vin: "12345678901234567",
                // serialNumber: "FG45120",
                // manufacturer: "Honda",
                // model: "Accord",
                // year: 2018,
                // mileage: 59750,
                // registrationNumber: "AA2231KA",

                // Recommendations
                recommendations: [
                    // {
                    //     id: 1,
                    //     name: "ТЕСТ. Швидко закінчився кондиціонер",
                    //     description: "Підчас попередньої заправки кондиціонера, разом с фріоном, була заправлена маркерна фарба для виявлення пошкоджень в системі кондиціювання. Необхідно дослідити систему на факт протікання.",
                    //     files: ["https://howcarworks.ru/sites/default/files/field/image/2015-04-06_222959.jpg"]
                    // },
                ],

                // Work orders
                workOrders: [
                    {
                        unitID: 1,
                        workOrderID: 499,
                        created: 1632155107,
                        plannedDate: 1598360100,
                        leadID: null,
                        counterpartyID: 32,
                        vehicleID: 1020,
                        jobs: [
                            {
                                workOrderJobID: 1,
                                name: "ТО 45000",
                                labours: [
                                    {
                                        workOrderLabourID: 12168,
                                        code: "YM000240",
                                        name: "Мастило ДВЗ. Заміна",
                                        duration: 30,
                                    },
                                ],
                                parts: [
                                    {
                                        workOrderPartID: 11370,
                                        code: "08232-P99C4LHE",
                                        name: "ОЛИВА МОТОРНА HONDA HFS-E 5W-30, 4Л",
                                        quantity: 4,
                                    },
                                ],
                            },
                            {
                                workOrderJobID: 2,
                                name: "Заправка кондиціонера",
                                labours: [
                                    {
                                        workOrderLabourID: 12168,
                                        code: "YM000240",
                                        name: "Мастило ДВЗ. Заміна",
                                        duration: 30,
                                    },
                                ],
                                parts: [
                                    {
                                        workOrderPartID: 11370,
                                        code: "08232-P99C4LHE",
                                        name: "ОЛИВА МОТОРНА HONDA HFS-E 5W-30, 4Л",
                                        quantity: 4,
                                    },
                                ],
                            },
                        ]
                    },
                    {
                        unitID: 1,
                        workOrderID: 499,
                        created: 1632155107,
                        plannedDate: 1598360100,
                        leadID: null,
                        counterpartyID: 32,
                        vehicleID: 1020,
                        jobs: [
                            {
                                workOrderJobID: 1,
                                name: "ТО 45000",
                                parts: [
                                    {
                                        workOrderPartID: 11370,
                                        code: "08232-P99C4LHE",
                                        name: "ОЛИВА МОТОРНА HONDA HFS-E 5W-30, 4Л",
                                        quantity: 4,
                                    },
                                ],
                                labours: [
                                    {
                                        workOrderLabourID: 12168,
                                        code: "YM000240",
                                        name: "Мастило ДВЗ. Заміна",
                                        duration: 30,
                                    },
                                ]
                            },
                            {
                                workOrderJobID: 2,
                                name: "Заправка кондиціонера",
                            },
                        ]
                    },
                ],

                loyalty: [
                    // {
                    //     id: 1,
                    //     nps: 3,
                    //     csi: 3,
                    //     remark: "ТЕСТ. Гірка кава",
                    // }
                ]
            });
        }
    }

    /*
        Lead
    */
    changeLeadDescription(event) {
        //event.target.style.width = event.target.value.length - 2 + "ch";
        //this.descriptionContainer.current.style.width = event.target.value.length - 2 + "ch";
        this.setState({description: event.target.value});
    }

    blurLeadDescription() {
        this.saveLeadDescription();
    }

    pressLeadDescription(e) {
        if (e.key === 'Enter') {
            e.target.blur();
            //this.commitLeadDescription();
        }
    }

    saveLeadDescription() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/service/lead/updateDescription', { leadID: this.state.leadID, description: this.state.description }, { headers: { Authorization: `Bearer ${token}` }}).then((res) => {
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
        }
    }

    leadOfferSMS() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/service/lead/offer/sms', { leadID: this.state.leadID }, { headers: { Authorization: `Bearer ${token}` }}).then((res) => {
                this.setState({smsSent: Math.floor(Date.now() / 1000)});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({smsSent: Math.floor(Date.now() / 1000)});
        }
    }

    leadOfferEmail() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/service/lead/offer/email', { leadID: this.state.leadID }, { headers: { Authorization: `Bearer ${token}` }}).then((res) => {
                this.setState({emailSent: Math.floor(Date.now() / 1000)});
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({emailSent: Math.floor(Date.now() / 1000)});
        }
    }

    /* 
        Counterparty
    */
    counterpartyLoad() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (this.state.counterpartyID) {
            var counterpartyPromise = axios.post('/api/crm/counterparty/card/get', { counterpartyID: this.state.counterpartyID }, { headers: { Authorization: `Bearer ${token}` }});
            //var loyaltyPromise = axios.post('/api/service/lead/results', {}, { headers: { Authorization: `Bearer ${token}` }});

            axios.all([counterpartyPromise]).then(axios.spread((counterparty) => {
                this.setState({
                    isCounterpartyLoading: false,

                    company: counterparty.data.company,
                    name: counterparty.data.name,
                    nameFull: counterparty.data.nameFull,
                    stateRegistrationCode: counterparty.data.stateRegistrationCode,
                    phone: counterparty.data.phone,
                    phoneAlternative: counterparty.data.phoneAlternative,
                    email: counterparty.data.email,
                    address: counterparty.data.address,
                });
            })).catch(errors => {
                
            });
        } else {
            this.setState({
                isCounterpartyLoading: false,
            });
        }
    }

    counterpartyFound(item) {
        this.setState({
            counterpartyID: item.counterpartyID,
            company: item.company,
            name: item.name,
            nameFull: item.nameFull,
            stateRegistrationCode: item.stateRegistrationCode,
            phone: item.phone,
            phoneAlternative: item.phoneAlternative,
            email: item.email,
            address: item.address,
        });
    }

    /*
        Vehicle
    */
    vehicleLoad() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (this.state.vehicleID) {
            var vehiclePromise = axios.post('/api/service/vehicle/card/get', { vehicleID: this.state.vehicleID }, { headers: { Authorization: `Bearer ${token}` }});
            //var loyaltyPromise = axios.post('/api/service/lead/results', {}, { headers: { Authorization: `Bearer ${token}` }});

            axios.all([vehiclePromise]).then(axios.spread((vehicle) => {
                this.setState({
                    isVehicleLoading: false,

                    vin: vehicle.data.vin,
                    serialNumber: vehicle.data.serialNumber,
                    manufacturer: vehicle.data.manufacturer,
                    model: vehicle.data.model,
                    year: vehicle.data.year,
                    mileage: vehicle.data.mileage,
                    registrationNumber: vehicle.data.registrationNumber,
                });
            })).catch(errors => {
                
            });
        } else {
            this.setState({
                isVehicleLoading: false,
            });
        }
    }

    vehicleFound(item) {
        this.setState({
            vehicleID: item.vehicleID,
            vin: item.vin,
            serialNumber: item.serialNumber,
            manufacturer: item.manufacturer,
            model: item.model,
            year: item.year,
            mileage: item.mileage,
            registrationNumber: item.registrationNumber,
        });
    }

    vehicleMileageChange(event) {
        this.setState({mileage: Number(event.target.value)});
    }

    vehicleMileageBlur() {
        this.vehicleMileageSave();
    }

    vehicleMileageKeyPress(e) {
        if (e.key === 'Enter') {
            e.target.blur();
        }
    }

    vehicleMileageSave() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/service/lead/updateDescription', { leadID: this.state.leadID, description: this.state.description }, { headers: { Authorization: `Bearer ${token}` }}).then((res) => {
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
        }
    }

    /*
        Recommendations
    */
    renderRecommendations() {
        const {lang} = this.context;

        return this.state.recommendations.map((item, i) => {
            return (
                <div className="row mb-1" key={"row_" + item.id}>
                    <div className="col">
                        <div className="d-flex justify-content-between">
                            <span><b>{item.name}</b></span>
                            <small className="text-muted">20.05.2021</small>
                        </div>
                        <span>{item.description}</span>
                        <div className="row">
                            {item.files.map((file, i) => {
                                return (
                                    <div className="col-3">
                                        <Preview src={file} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            );
        });
    }

    /*
        Work orders history
    */
    workOrdersLoad() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (this.state.vehicleID) {
            var workOrdersPromise = axios.post('/api/service/work-order/search', { vehicleID: this.state.vehicleID }, { headers: { Authorization: `Bearer ${token}` }});
            //var loyaltyPromise = axios.post('/api/service/lead/results', {}, { headers: { Authorization: `Bearer ${token}` }});

            axios.all([workOrdersPromise]).then(axios.spread((workOrders) => {
                this.setState({
                    isWorkOrdersLoading: false,
                    workOrders: workOrders.data,
                });
            })).catch(errors => {
                
            });
        } else {
            this.setState({
                isWorkOrdersLoading: false,
            });
        }
    }

    renderWorkOrders() {
        const {lang, datetime} = this.context;

        return this.state.workOrders.map((item) => {
            return (
                <div className="row mb-3" key={"row_" + item.id}>
                    <div className="col">
                        <div className="d-flex justify-content-between">
                            <div>
                                {item.jobs && item.jobs.map((job) => {
                                    return (
                                        <div>
                                            <p className="mb-0" key={"job_"+job.workOrderJobID}><b>{job.name}</b></p>
                                            {job.labours && job.labours.map((labour) => {
                                                return (
                                                    <p className="mb-0" key={"labour_"+labour.workOrderLabourID}><FontAwesomeIcon icon={faWrench} style={{color: "#adb5bd"}} /> {labour.name}</p>
                                                )
                                            })}
                                            {job.parts && job.parts.map((part) => {
                                                return (
                                                    <p className="mb-0" key={"part_"+part.workOrderPartID}><FontAwesomeIcon icon={faCog} style={{color: "#adb5bd"}} /> {part.name + " (" + part.code + ")"}</p>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                            <small className="text-muted">{intToDateTimeFormat(item.plannedDate, datetime).split(' ')[0]}</small>
                        </div>
                    </div>
                </div>
            );
        });
    }

    /*
        Loyalty
    */
    renderLoyalty() {
        const {lang} = this.context;

        return this.state.loyalty.map((item, i) => {
            return (
                <div className="row mb-1" key={"row_" + item.id}>
                    <div className="col">
                        <div className="d-flex justify-content-between">
                            <span className="w-100">NPS - <b>{item.nps}</b>, CSI - <b>{item.csi}</b> <span className="ml-3">{item.remark}</span></span>
                            <small className="text-muted">20.05.2021</small>
                        </div>
                    </div>
                </div>
            );
        });
    }

    /*
        Work order
    */
    workOrderLoad() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (this.state.workOrderID) {
            var workOrderPromise = axios.post('/api/service/work-order/getByID', { workOrderID: this.state.workOrderID }, { headers: { Authorization: `Bearer ${token}` }});
            //var loyaltyPromise = axios.post('/api/service/lead/results', {}, { headers: { Authorization: `Bearer ${token}` }});

            axios.all([workOrderPromise]).then(axios.spread((workOrder) => {
                this.setState({
                    isWorkOrderLoading: false,

                    workOrderID: workOrder.data.workOrderID,
                    workOrderPlannedDate: workOrder.data.plannedDate,
                    workOrderJobs: workOrder.data.jobs,
                });
            })).catch(errors => {
                
            });
        } else {
            this.setState({
                isWorkOrderLoading: false,
            });
        }
    }

    workOrderPlannedDateChange(event) {
        this.setState({workOrderPlannedDate: Math.floor(new Date(event.target.value).getTime() / 1000)});
    }

    workOrderJobAdd() {
        var a = this.state.workOrderJobs.concat("");
        this.setState({workOrderJobs: a});
    }

    workOrderJobChange(i, event) {
        var a = this.state.workOrderJobs;
        a[i] = event.target.value;
        this.setState({workOrderJobs: a});
    }

    workOrderJobRemove(i) {
        var a = this.state.workOrderJobs;
        a.splice(i, 1);
        this.setState({workOrderJobs: a});
    }

    workOrderCreate() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/service/work-order/createFromLead', { 
                unitID: this.state.unitID, 
                leadID: this.state.leadID, 
                plannedDate: this.state.workOrderPlannedDate,
            }, { 
                headers: { 
                    Authorization: `Bearer ${token}` 
                }
            }).then((res) => {
                this.setState({
                    workOrderID: res.data.workOrderID,
                    closed: Math.floor(Date.now() / 1000),
                }, () => {
                    var jobPromises = [];

                    this.state.workOrderJobs.forEach((item) => {
                        var p = axios.post('/api/service/work-order/job/create', { 
                            workOrderID: this.state.workOrderID,
                            name: item,
                        }, { 
                            headers: { 
                                Authorization: `Bearer ${token}` 
                            }
                        });
    
                        jobPromises.push(p);
                    });

                    Promise.all(jobPromises).then((results) => {
                        // results.forEach((response) => {
                        //     response.data.workOrderJobID;
                        // });

                        //this.leadClose(100);
                    }).catch((error) => {
                        addNotification(error.response.data, error.response.status);
                    }).finally(() => {
                        removeNotification(u);
                    });
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            var data = {
                unitID: this.state.unitID, 
                leadID: this.state.leadID, 
                plannedDate: this.state.workOrderPlannedDate,
                // counterpartyID: this.state.counterpartyID,
                // vehicleID: this.state.vehicleID,
                // mileage: this.state.mileage,
            };

            console.log(data);

            this.setState({
                workOrderID: 11111,
            });
        }
    }

    /*
        Calls
    */
    renderCalls() {
        const {lang, datetime} = this.context;

        return this.state.calls.sort((a, b) => {
            return (b.done != null ? b.done : b.planned) - (a.done != null ? a.done : a.planned);
        }).reverse().map((item, i) => {
            return <Call
                key={"call_id_" + item.callID}
                data={item}
                results={this.state.results}

                callDate={this.state.callDate}
                callRemark={this.state.callRemark}
                callDateChange={this.callDateChange}
                callDateSet={this.callDateSet}
                callRemarkChange={this.callRemarkChange}
                callCreate={this.callCreate}

                leadRemark={this.state.leadRemark}
                leadRemarkChange={this.leadRemarkChange}
                leadClose={this.leadClose}
            />
        });
    }

    callDateChange(event) {
        this.setState({callDate: Math.floor(new Date(event.target.value).getTime() / 1000)});
    }

    callDateSet(time) {
        this.setState({callDate: Math.floor(Date.now() / 1000) + time});
    }

    callRemarkChange(event) {
        this.setState({callRemark: event.target.value});
    }

    callCreate(planned, remark) {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/service/lead/call/create', { leadID: this.state.leadID, planned: planned, remark, remark, notify: false }, { headers: { Authorization: `Bearer ${token}` }}).then((res) => {
                this.setState({
                    isCreateCallOpened: false,
                    callDate: Math.floor(Date.now() / 1000),
                    callRemark: "",
                    calls: this.state.calls.concat({
                        callID: res.data.callID, 
                        created: Math.floor(Date.now() / 1000), 
                        planned: planned, 
                        done: null, 
                        remark: remark
                    }).sort((a, b) => {
                        return (b.done != null ? b.done : b.planned) - (a.done != null ? a.done : a.planned);
                    })
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                isCreateCallOpened: false,
                callDate: Math.floor(Date.now() / 1000),
                callRemark: "",
                calls: this.state.calls.concat({
                    callID: 3, 
                    created: Math.floor(Date.now() / 1000), 
                    planned: planned, 
                    done: null, 
                    remark: remark
                }).sort((a, b) => {
                    return (b.done != null ? b.done : b.planned) - (a.done != null ? a.done : a.planned);
                })
            });
        }
    }

    renderResult() {
        const {lang, datetime} = this.context;

        if (!this.state.resultID) {
            return (
                <button className="question-button" style={{height: "40px", minWidth: "200px", maxWidth: "100%", fontSize: "18px", fontWeight: "300", backgroundColor: "#f3f3f3", cursor: "default"}}>
                    Відкритий
                </button>
            );
        }
        
        switch (this.state.resultID) {
            case 100:
                return (
                    <button className="question-button" style={{height: "40px", minWidth: "200px", maxWidth: "100%", fontSize: "18px", fontWeight: "300", backgroundColor: "#d4edda", color: "#155724", cursor: "default"}}>
                        Успіх
                    </button>
                );
            default:
                var result = this.state.results.find(x => x.resultID == this.state.resultID).name + ".";

                return (
                    <button className="question-button" style={{height: "40px", minWidth: "200px", lineHeight:"15px", fontSize: "14px", maxWidth: "100%", fontSize: "18px", fontWeight: "300", backgroundColor: "#f8d7da", color: "#721c24", cursor: "default"}}>
                        Невдача<br/><span style={{fontSize: "12px"}}>{result}</span>{this.state.resultRemark && (<span className="ml-1" style={{fontSize: "12px"}}>{this.state.resultRemark}</span>)}
                    </button>
                    // <div className="card-agis pt-4 pb-4">
                    //     <div className="d-flex align-items-center justify-content-between">
                    //         <div style={{width: "38px"}}>
                    //             <FontAwesomeIcon icon={faThumbsDown} style={{fontSize: "24px", color: "#e89192"}} />
                    //         </div>
                    //         <div className="w-100">
                    //             <div className="d-flex justify-content-between">
                    //                 <big><b>Відміна візиту</b></big>
                    //                 <small className="text-muted">{intToDateTimeFormat(this.state.closed, datetime)}</small>
                    //             </div>
                    //             <span>{result}</span>
                    //         </div>
                    //     </div>
                    // </div>
                );
         }
    }

    /*
        Lead
    */
    leadRemarkChange(event) {
        this.setState({leadRemark: event.target.value});
    }

    leadClose(status) {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/service/lead/close', { leadID: this.state.leadID, resultID: status, resultRemark: this.state.leadRemark }, { headers: { Authorization: `Bearer ${token}` }}).then((res) => {
                this.setState({
                    calls: this.state.calls.filter(x => x.done),
                    closed: Math.floor(Date.now() / 1000), 
                    resultID: status,
                    resultRemark: this.state.leadRemark,
                    leadRemark: "",
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({resultID: status});
        }
    }

    render() {
        const {lang, datetime} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />

                <div className="container-fluid mb-4">
                    <div className="row mb-3">
                        <div className="col-md-5 offset-md-1">
                            <div class="">
                                <h1 style={{fontSize: "2rem", color: "#868e96"}}>{this.state.typeName}</h1>
                                <input type="text" autoComplete={false} className="transparent-input text-muted mt-2" style={{width: "100%", fontSize: "1rem"}} value={this.state.description} placeholder="Опис..." onChange={this.changeLeadDescription} onKeyPress={this.pressLeadDescription} onBlur={this.blurLeadDescription} disabled={this.state.resultID} />

                                {/* <button className="question-button" title="Редагувати" style={{paddingTop: "2px", backgroundColor: "inherit"}}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.5592 1.35352C12.9734 0.767737 12.0237 0.767739 11.4379 1.35352L1.64502 11.1464C1.57133 11.2201 1.5225 11.315 1.50537 11.4178L1.02839 14.2797C0.960706 14.6858 1.31277 15.0378 1.71886 14.9702L4.58077 14.4932C4.68356 14.476 4.77843 14.4272 4.85212 14.3535L14.645 4.56063C15.2308 3.97484 15.2308 3.0251 14.645 2.43931L13.5592 1.35352ZM12.145 2.06063C12.3403 1.86537 12.6569 1.86537 12.8521 2.06063L13.9379 3.14642C14.1332 3.34168 14.1332 3.65826 13.9379 3.85352L12.1057 5.68576L10.3128 3.89287L12.145 2.06063ZM9.60568 4.59997L2.46542 11.7402L2.10685 13.8917L4.25832 13.5331L11.3986 6.39287L9.60568 4.59997Z" fill="#4F4F4F"></path></svg>
                                </button>
                                <button className="question-button ml-3" title="Скопіювати посилання" style={{backgroundColor: "inherit"}}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.14647 2.14646C9.72245 0.570484 12.2776 0.570484 13.8536 2.14646C15.4296 3.72243 15.4296 6.27759 13.8536 7.85357L11.8536 9.85357L11.1465 9.14646L13.1465 7.14646C14.3319 5.96101 14.3319 4.03902 13.1465 2.85357C11.961 1.66812 10.039 1.66811 8.85358 2.85357L6.85358 4.85357L6.14647 4.14646L8.14647 2.14646Z" fill="#6B6B6B"></path><path d="M7.85356 13.8536C6.27759 15.4295 3.72243 15.4295 2.14646 13.8536C0.570483 12.2776 0.570484 9.72243 2.14646 8.14646L4.14646 6.14646L4.85356 6.85357L2.85357 8.85357C1.66812 10.039 1.66811 11.961 2.85357 13.1465C4.03902 14.3319 5.96101 14.3319 7.14646 13.1465L9.14646 11.1465L9.85356 11.8536L7.85356 13.8536Z" fill="#6B6B6B"></path><path d="M5.35358 11.3536L11.3536 5.35356L10.6465 4.64646L4.64647 10.6465L5.35358 11.3536Z" fill="#6B6B6B"></path></svg>
                                </button> */}
                            </div>
                        </div>
                        
                        <div className="col-md-5 text-right">
                            {this.renderResult()}

                            {/* {this.state.resultID ? (
                                <button className="question-button" style={{height: "40px", maxWidth: "100%", fontSize: "18px", fontWeight: "300", backgroundColor: "#f3f3f3"}} onClick={() => this.setState({isCreateCallOpened: true})}>
                                    <svg style={{marginRight: "5px", fontSize: "18px", color: "#a0a0a0"}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-phone-plus fa-w-16 fa-2x"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4zM16 144h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16h-64V16c0-8.8-7.2-16-16-16H96c-8.8 0-16 7.2-16 16v64H16C7.2 80 0 87.2 0 96v32c0 8.8 7.2 16 16 16z" class=""></path></svg>
                                    Запланувати дзвінок
                                </button>
                            ) : (
                                <button className="question-button" style={{height: "40px", maxWidth: "100%", fontSize: "18px", fontWeight: "300", backgroundColor: "#f3f3f3"}} onClick={() => this.setState({isCreateCallOpened: true})}>
                                    <svg style={{marginRight: "5px", fontSize: "18px", color: "#a0a0a0"}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-phone-plus fa-w-16 fa-2x"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4zM16 144h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16h-64V16c0-8.8-7.2-16-16-16H96c-8.8 0-16 7.2-16 16v64H16C7.2 80 0 87.2 0 96v32c0 8.8 7.2 16 16 16z" class=""></path></svg>
                                    Запланувати дзвінок
                                </button>
                            )} */}
                        </div>
                        

                        {/* <div className="w-100">
                        
                        </div> */}
                    </div>

                    {/* <div className="row mb-4">
                        <div className="col-md-10 offset-md-1">
                            <small class="form-text text-muted">Тип ліда</small>

                            <div className="card-agis clickable pt-2 pb-2" style={{width: "200px"}}>
                                <h6 className="text-muted">Нагадування про ТО</h6>
                            </div>
                        </div>
                    </div> */}
               
                    <div className="row">
                        <div className="col-md-5 offset-md-1">
                            {this.state.counterpartyID ? (
                                <div className="card-agis pt-2 pb-2">
                                    <div className="d-flex align-items-top justify-content-between mb-2">
                                        <h5 className="text-muted font-weight-light">Контрагент</h5>
                                        {/* <Link to={"/"}><small className="">Детальніше</small></Link> */}
                                        {/* <small className="">Детальніше</small> */}
                                    </div>

                                    <div className="row mb-1">
                                        <div className="col-6">
                                            <small class="form-text text-muted pl-2">Назва компанії / П.І.Б.</small>
                                            <input type="text" autocomplete="off" class="question-input" value={this.state.name} disabled={true} />
                                        </div>
                                    </div>

                                    <div className="row mb-1">
                                        <div className="col-12">
                                            <small class="form-text text-muted pl-2">Адреса</small>
                                            <textarea rows="1" autocomplete="off" class="question-input" style={{resize: "none"}} value={this.state.address} disabled={true} />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Телефон</small>
                                            <input type="text" autocomplete="off" class="question-input" value={this.state.phone} onChange={this.changePhone} disabled={true} />
                                        </div>
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Телефон</small>
                                            <input type="text" autocomplete="off" class="question-input" value={this.state.phoneAlternative} onChange={this.changePhoneAlternative} disabled={true} />
                                        </div>
                                        <div className="col-6">
                                            <small class="form-text text-muted pl-1">Електронна адреса</small>
                                            <input type="text" autocomplete="off" class="question-input" value={this.state.email} onChange={this.changeEmail} disabled={true} />
                                        </div>
                                    </div>

                                    {this.state.loyalty.length !== 0 && (
                                        <div>
                                            <hr className="w-100 mt-3 mb-2"/>
                                            <h6 className="text-muted font-weight-light mb-1">Попередні відгуки</h6>
                                            {this.renderLoyalty()}
                                        </div>
                                    )}

                                    {/* {this.state.isCounterpartyChanged && (
                                        <div className="row mt-2">
                                            <div className="col text-right">
                                                <small className="text-muted mr-2">Бажаєте зберегти зміни?</small>
                                                <button onClick={this.counterpartySave} className="question-button" style={{width: "100px"}}>Зберегти</button>
                                            </div>
                                        </div>
                                    )} */}
                                </div>
                            ) : (
                                <div className="card-agis pt-2 pb-2">
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <h5 className="text-muted font-weight-light">Контрагент не ідентифікований</h5>
                                        <CounterpartyPicker className="transparent-input underline" placeholder={"Пошук..."} action={this.counterpartyFound} />
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Телефон</small>
                                            <input type="text" autocomplete="off" class="question-input" value={this.state.phone} onChange={this.changePhone} />
                                        </div>
                                        <div className="col-6">
                                            <small class="form-text text-muted pl-2">Назва компанії / П.І.Б.</small>
                                            <input type="text" autocomplete="off" class="question-input" value={this.state.name} />
                                        </div>
                                        
                                        <div className="col-3">
                                        </div>
                                    </div>
                                </div>
                            )}

                            <br/>

                            {this.state.vehicleID ? (
                                <div className="card-agis pt-2 pb-2">
                                    <div className="d-flex align-items-top justify-content-between mb-2">
                                        <h5 className="text-muted font-weight-light">Авто</h5>
                                        {/* <Link to={"/"}><small className="">Детальніше</small></Link> */}
                                        {/* <small className="">Детальніше</small> */}
                                    </div>

                                    <div className="row mb-1">
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Реєстраційний номер</small>
                                            <input type="test" class="question-input" value={this.state.registrationNumber} disabled={true} />
                                        </div>
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Марка</small>
                                            <input type="text" class="question-input" value={this.state.manufacturer} disabled={true} />
                                        </div>
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Модель</small>
                                            <input type="text" class="question-input" value={this.state.model} disabled={true} />
                                        </div>
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Рік випуску</small>
                                            <input type="number" step="1" min="1980" class="question-input" value={this.state.year} disabled={true} />
                                        </div>
                                        
                                    </div>

                                    <div className="row">
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Пробіг</small>
                                            <input type="number" step="1" min="0" class="question-input" value={this.state.mileage} onChange={this.vehicleMileageChange} onBlur={this.vehicleMileageBlur} onKeyPress={this.vehicleMileageKeyPress} disabled={true} />
                                        </div>
                                        <div className="col-6">
                                            <small class="form-text text-muted pl-1">VIN</small>
                                            <input type="text" class="question-input" value={this.state.vin} disabled={true} />
                                        </div>
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Серійний номер</small>
                                            <input type="text" class="question-input" value={this.state.serialNumber} disabled={true} />
                                        </div>
                                    </div>
                                    
                                    {this.state.recommendations.length !== 0 && (
                                        <div>
                                            <hr className="w-100 mt-3 mb-2"/>
                                            <h6 className="text-muted font-weight-light mb-1">Рекомендації по авто</h6>
                                            {this.renderRecommendations()}
                                        </div>
                                    )}

                                    {this.state.workOrders.length !== 0 && (
                                        <div>
                                            <hr className="w-100 mt-3 mb-2"/>
                                            <h6 className="text-muted font-weight-light mb-1">Попередні візити авто</h6>
                                            {this.renderWorkOrders()}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="card-agis pt-2 pb-2">
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <h5 className="text-muted font-weight-light mb-1">Авто не ідентифіковане</h5>
                                        <VehiclePicker className="transparent-input underline" placeholder={"Пошук..."} action={this.vehicleFound} />
                                    </div>

                                    <div className="row mb-1">
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Реєстраційний номер</small>
                                            <input type="test" class="question-input" value={this.state.registrationNumber} />
                                        </div>
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Марка</small>
                                            <input type="text" class="question-input" value={this.state.manufacturer} />
                                        </div>
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Модель</small>
                                            <input type="text" class="question-input" value={this.state.model} />
                                        </div>
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Рік випуску</small>
                                            <input type="number" step="1" min="1980" class="question-input" value={this.state.year} />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Пробіг</small>
                                            <input type="number" step="1" min="0" class="question-input" value={this.state.mileage} onChange={this.vehicleMileageChange} onBlur={this.vehicleMileageBlur} onKeyPress={this.vehicleMileageKeyPress} />
                                        </div>
                                        <div className="col-6">
                                            <small class="form-text text-muted pl-1">VIN</small>
                                            <input type="text" class="question-input" value={this.state.vin} />
                                        </div>
                                        <div className="col-3">
                                            <small class="form-text text-muted pl-1">Серійний номер</small>
                                            <input type="text" class="question-input" value={this.state.serialNumber} />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* <br/>

                            <div className="card-agis pt-2 pb-2">
                                <h5 className="text-muted font-weight-light mb-1">Рекомендації по авто</h5>
                                {this.renderRecommendations()}
                            </div>
                            
                            <br/>

                            <div className="card-agis pt-2 pb-2">
                                <h5 className="text-muted font-weight-light mb-1">Попередні візити</h5>
                                {this.renderWorkOrders()}
                            </div>

                            <br/>

                            <div className="card-agis pt-2 pb-2">
                                <h5 className="text-muted font-weight-light mb-1">Попередні відгуки</h5>
                                {this.renderLoyalty()}
                            </div> */}
                        </div>
                        <div className="col-md-4 offset-md-1">
                            {!this.state.resultID ? (
                                this.state.workOrderID ? (
                                    /*
                                        Lead open and work order exist
                                    */
                                    <div className="card-agis pt-2 pb-2">
                                        <div className="d-flex align-items-top justify-content-between">
                                            <h5 className="text-muted font-weight-light mb-1">Заказ наряд на основі ліда</h5>
                                            {/* <Link to={"/"}><small className="">Детальніше</small></Link> */}
                                            {/* <small className="">Детальніше</small> */}
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <small class="form-text text-muted pl-1">Планована дата візиту</small>
                                                <input type="datetime-local" className="question-input w-100" style={{}} value={intToDateTimeFormat(this.state.workOrderPlannedDate, "year-month-dayThour:minute")} disabled={true} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <small class="form-text text-muted pl-1">Блоки робіт</small>
                                                {this.state.workOrderJobs.map((item, i) => {
                                                    return (
                                                        <div key={"job_" + item.workOrderJobID} className="mb-2">
                                                            <input type="text" class="question-input w-100" value={item.name} disabled={true} />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    /*
                                        Lead open and work order doesn't exist
                                    */
                                    <div className="card-agis pt-2 pb-2">
                                        <div className="d-flex align-items-top justify-content-between">
                                            <h5 className="text-muted font-weight-light mb-1">Заказ наряд на основі ліда</h5>
                                            {/* <Link to={"/"}><small className="">Детальніше</small></Link> */}
                                            {/* <small className="">Детальніше</small> */}
                                        </div>
    
                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <small class="form-text text-muted pl-1">Планована дата візиту</small>
                                                <input type="datetime-local" className="question-input w-100" style={{}} value={intToDateTimeFormat(this.state.workOrderPlannedDate, "year-month-dayThour:minute")} onChange={this.workOrderPlannedDateChange} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <small class="form-text text-muted pl-1">Блоки робіт</small>
    
                                                {this.state.workOrderJobs.map((item, i) => {
                                                    return (
                                                        <div key={"job_" + i} className="mb-2">
                                                            <input type="text" class="question-input w-75 mr-2" value={item} onChange={(e) => this.workOrderJobChange(i, e)} autoFocus={true} />
                                                            <Link onClick={() => this.workOrderJobRemove(i)}><small className="">Видалити</small></Link>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
    
                                        <div className="row">
                                            <div className="col">
                                                <Link onClick={() => this.workOrderJobAdd()}><small className="">Додати блок робіт</small></Link>
                                            </div>
                                            <div className="col text-right">
                                                <button className="question-button" onClick={this.workOrderCreate}>Створити</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ) : (
                                this.state.workOrderID ? (
                                    /*
                                        Lead closed and work order exist
                                    */
                                    <div className="card-agis pt-2 pb-2">
                                        <div className="d-flex align-items-top justify-content-between">
                                            <h5 className="text-muted font-weight-light mb-1">Заказ наряд на основі ліда</h5>
                                            {/* <Link to={"/"}><small className="">Детальніше</small></Link> */}
                                            {/* <small className="">Детальніше</small> */}
                                        </div>

                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <small class="form-text text-muted pl-1">Планована дата візиту</small>
                                                <input type="datetime-local" className="question-input w-100" style={{}} value={intToDateTimeFormat(this.state.workOrderPlannedDate, "year-month-dayThour:minute")} disabled={true} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <small class="form-text text-muted pl-1">Блоки робіт</small>
                                                {this.state.workOrderJobs.map((item, i) => {
                                                    return (
                                                        <div key={"job_" + item.workOrderJobID} className="mb-2">
                                                            <input type="text" class="question-input w-100" value={item.name} disabled={true} />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    /*
                                        Lead closed and work order doesn't exist
                                    */
                                    <div className="card-agis pt-2 pb-2">
                                        <div className="d-flex align-items-top justify-content-between">
                                            <h5 className="text-muted font-weight-light mb-1">Заказ наряд на основі ліда не створено</h5>
                                        </div>
                                    </div>
                                )
                            )}

                            <br/>
                            <br/>
                            <br/>

                            {!this.state.resultID &&
                                <div className="card-agis pt-2 pb-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5 className="text-muted font-weight-light mb-1">Лід-оферта</h5>
                                        <a href={this.state.offerURL + this.state.leadID} target="_blank"><small className="">Відкрити</small></a>
                                    </div>
                                    <div className="d-flex align-items-top justify-content-start">
                                        <div className="d-flex align-items-top justify-content-between w-50">
                                            <div style={{width: "38px"}}>
                                                <FontAwesomeIcon icon={faComment} style={{fontSize: "24px", color: "#c1c1c1"}} />
                                            </div>
                                            <div className="w-100">
                                                <div style={{lineHeight: "18px"}}>
                                                    <span><b>SMS</b></span>
                                                </div>
                                                <div style={{lineHeight: "14px"}}>
                                                    <small className="text-muted">{this.state.smsSent ? "Відправлено " + intToDateTimeFormat(this.state.smsSent, datetime) : "Не відправлено"}</small>
                                                </div>
                                                {!this.state.smsSent && (
                                                    <button className="question-button mt-1" onClick={this.leadOfferSMS}>Відправити</button>
                                                )}
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-top justify-content-between w-50">
                                            <div className="text-center" style={{width: "38px"}}>
                                                <FontAwesomeIcon icon={faEnvelope} style={{fontSize: "24px", color: "#c1c1c1"}} />
                                            </div>
                                            <div className="w-100">
                                                <div style={{lineHeight: "18px"}}>
                                                    <span><b>Email</b></span>
                                                </div>
                                                <div style={{lineHeight: "14px"}}>
                                                    <small className="text-muted">{this.state.emailSent ? "Відправлено " + intToDateTimeFormat(this.state.emailSent, datetime) : "Не відправлено"}</small>
                                                </div>
                                                {!this.state.emailSent && (
                                                    <button className="question-button mt-1" onClick={this.leadOfferEmail}>Відправити</button>
                                                )}                                            
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <br/>
                            
                            {this.renderCalls()}

                            {/* {this.callCreateRender()} */}

                            {!this.state.resultID && (
                                !this.state.isCreateCallOpened ? (
                                    <div className="w-100">
                                        <button className="question-button w-100" style={{height: "40px", maxWidth: "100%", fontSize: "18px", fontWeight: "300", backgroundColor: "#f3f3f3"}} onClick={() => this.setState({isCreateCallOpened: true})}>
                                            <svg style={{marginRight: "5px", fontSize: "18px", color: "#a0a0a0"}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-phone-plus fa-w-16 fa-2x"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4zM16 144h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16h-64V16c0-8.8-7.2-16-16-16H96c-8.8 0-16 7.2-16 16v64H16C7.2 80 0 87.2 0 96v32c0 8.8 7.2 16 16 16z" class=""></path></svg>
                                            Запланувати дзвінок
                                        </button>
                                    </div>
                                ) : (
                                <div className="card-agis pt-2 pb-2 mb-4">
                                    <div className="d-flex align-items-start justify-content-between">
                                        <div style={{width: "38px"}}>
                                            <svg style={{fontSize: "24px", color: "#f5bd79"}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-phone-plus fa-w-16 fa-2x"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4zM16 144h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16h-64V16c0-8.8-7.2-16-16-16H96c-8.8 0-16 7.2-16 16v64H16C7.2 80 0 87.2 0 96v32c0 8.8 7.2 16 16 16z" class=""></path></svg>
                                        </div>
                                        <div className="w-100">
                                            <span><b>Запланувати дзвінок</b></span>
                                            <div className="row mb-2">
                                                <div className="col-6">
                                                    <small class="form-text text-muted pl-1">Планована дата дзвінку</small>
                                                    <input type="datetime-local" className="input-agis-block w-100" style={{fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={intToDateTimeFormat(this.state.callDate, "year-month-dayThour:minute")} onChange={this.callDateChange} />
                                                </div>
                                                <div className="col-6">
                                                    <small class="form-text text-muted pl-1">Коментар</small>
                                                    <input type="text" class="question-input" value={this.state.callRemark} onChange={this.callRemarkChange} />
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div className="w-75">
                                                    <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.callDateSet(900)}>За 15 хв</button>
                                                    <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.callDateSet(3600)}>За 1 год</button>
                                                    <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.callDateSet(86400)}>Завтра</button>
                                                    <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.callDateSet(259200)}>За 3 дні</button>
                                                    <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.callDateSet(604800)}>За тиждень</button>
                                                    <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.callDateSet(1209600)}>За 2 тижні</button>
                                                    <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.callDateSet(2419200)}>За місяць</button>
                                                </div>
                                                <button className="question-button" onClick={() => this.callCreate(this.state.callDate, this.state.callRemark)}>Створти</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <br/>
                            <br/>
                            <br/>

                            {!this.state.resultID && (
                                <div className="card-agis pt-2 pb-2">
                                    <div className="d-flex align-items-top justify-content-between">
                                        <h5 className="text-muted font-weight-light mb-1">Скрипт розмови</h5>
                                    </div>
                                    <div className="row">
                                        {this.state.typeID == 1 && (
                                            <div className="col-12">
                                                <p>Доброго дня шановний (-на) <b>{this.state.name}</b></p>
                                                <p>Хочемо звернути Вашу увагу що, можливо надійшов час чергового технічного обслуговування вашого автомобіля <b>{(this.state.manufacturer ? this.state.manufacturer + " " : "") + (this.state.model ? this.state.model + " " : "") + (this.state.year ? this.state.year + " " : "") + (this.state.registrationNumber ? this.state.registrationNumber : "")}</b></p>
                                            
                                                <p>Запрошуемо Вас відвідати Дилерський центр «Юме Авто Центр».</p>

                                                <p>До Вашого відома:</p>

                                                <p>1. Для виконання робіт по ТО  ___ км  Необхідно, приблизно ___ годин</p>

                                                <p>2. Оріентовна вартість робіт складатиме приблизно ___грн.___коп.</p>

                                                <p>3. Під час розмови з консультантом відділу приймання автомобілів будь ласка, повідомите про додаткові роботи які Ви бажали виконати під час візиту до Сервісного центру.</p>

                                                <p>4. Нагадуемо, що cервісний центр «Юме Авто Центр» знаходиться за адресою: 08134, Київська область, Києво-Святошинський район, <b>м. Вишневе, вул. Лесі Українки, буд. 73.</b></p>

                                                <p>Сервісний центр « Юме Авто Центр» працює:<br/>
                                                ПН-ПТ 09:00 - 18:00<br/>
                                                СБ вихідний<br/>
                                                НД вихідний</p>
                                                
                                                <p>Телефон відділу приймання автомобілів: <b>(044) 590 58 58</b></p>
                                            </div>
                                        )}
                                        {this.state.typeID == 2 && (
                                            <div className="col-12">
                                                 <p>Доброго дня шановний (-на) <b>{this.state.name}</b></p>
                                                 <p>Хочемо звернути Вашу увагу, що Ваше авто <b>{(this.state.manufacturer ? this.state.manufacturer + " " : "") + (this.state.model ? this.state.model + " " : "") + (this.state.year ? this.state.year + " " : "") + (this.state.registrationNumber ? this.state.registrationNumber : "")}</b> підпадає під відкличну компанію</p>

                                                 <p>Запрошуємо Вас звернутися до Дилерського центру «Юме Авто Центр», для запису на заміну необхідних компонентів.</p>

                                                 <p>Всі роботи в рамках відкличної компанії виконуються авторизованими дилерами безкоштовно.</p>

                                                <p>Нагадуемо, що cервісний центр «Юме Авто Центр» знаходиться за адресою: 08134, Київська область, Києво-Святошинський район, <b>м. Вишневе, вул. Лесі Українки, буд. 73.</b></p>

                                                <p>Сервісний центр « Юме Авто Центр» працює:<br/>
                                                ПН-ПТ 09:00 - 18:00<br/>
                                                СБ вихідний<br/>
                                                НД вихідний</p>

                                                <p>Телефон відділу приймання автомобілів: <b>(044) 590 58 58</b></p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class Call extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            resultView: null,
            resultSubView: null,

            callID: this.props.data.callID,
            created: this.props.data.created,
            planned: this.props.data.planned,
            done: this.props.data.done,
            remark: this.props.data.remark,
        }

        this.callUpdate = this.callUpdate.bind(this);
    }

    callUpdate(resultID, callback) {
        const {lang, token, addNotification, removeNotification} = this.context;

        var remark = (resultID < 200 ? "" : resultID >= 200 && resultID < 300 ? "Невдача. " : "Без результату. ") + this.props.results.find(x => x.resultID == resultID).name + ".";

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/service/lead/call/update', { 
                callID: this.state.callID,
                remark: remark,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then((res) => {
                this.setState({
                    done: Math.floor(Date.now() / 1000),
                    remark: remark,
                }, () => {
                    callback(resultID);
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                done: Math.floor(Date.now() / 1000),
                remark: remark,
            }, () => {
                callback(resultID);
            });
        }
    }

    render() {
        const {lang, datetime} = this.context;

        if (this.state.done) {
            return (
                <div className="card-agis pt-2 pb-2 mb-4">
                    <div className="d-flex align-items-start justify-content-between">
                        <div style={{width: "38px"}}>
                            <FontAwesomeIcon icon={faPhone} style={{fontSize: "24px", color: "#c1c1c1"}} />
                        </div>
                        <div className="w-100">
                            <div className="d-flex align-items-center justify-content-between">
                                <span><b>Проведений дзвінок</b></span>
                                <small className="">{intToDateTimeFormat(this.state.done, datetime)}</small>
                            </div>
                            {this.state.remark && (
                                <span>{this.state.remark}</span>
                            )}
                        </div>
                    </div>
                </div>
            );
        } 

        return (
            <div className="card-agis pt-2 pb-2 mb-4">
                <div className="d-flex align-items-start justify-content-between">
                    <div style={{width: "38px"}}>
                        <FontAwesomeIcon icon={faClock} style={{fontSize: "24px", color: "#f5bd79"}} />
                    </div>
                    <div className="w-100">
                        <div className="d-flex justify-content-between">
                            <span><b>Запланований дзвінок</b></span>
                            <small className="">{intToDateTimeFormat(this.state.planned, datetime)}</small>
                        </div>
                        {this.state.remark && (
                            <span>{this.state.remark}</span>
                        )}

                        <small class="form-text text-muted">Результат дзвінку</small>
                        <button className="question-button w-25 mr-2" style={this.state.resultView == "success" ? {backgroundColor: "#dcdcdc"} : {}} onClick={() => this.setState({resultView: "success", resultSubView: null})}>Успіх</button>
                        <button className="question-button w-25 mr-2" style={this.state.resultView == "fail" ? {backgroundColor: "#dcdcdc"} : {}} onClick={() => this.setState({resultView: "fail", resultSubView: null})}>Невдача</button>
                        <button className="question-button w-25" style={this.state.resultView == "delay" ? {backgroundColor: "#dcdcdc"} : {}} onClick={() => this.setState({resultView: "delay", resultSubView: null})}>Без результату</button>

                        {this.state.resultView == "success" && (
                            <div className="mt-2">
                                <div className="row">
                                    <div className="col">
                                        <small class="form-text text-muted pl-1">Підтверддення</small>
                                        <button className="question-button mr-2" style={{maxWidth: "400px"}} onClick={() => this.callUpdate(100, () => this.props.leadClose(100))}>Підтвердити та закрити лід</button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.resultView == "fail" && (
                            <div className="mt-2">
                                <div className="row">
                                    <div className="col-12">
                                        <small class="form-text text-muted pl-1">Коментар</small>
                                        <input type="text" class="question-input" value={this.props.leadRemark} onChange={this.props.leadRemarkChange} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <small class="form-text text-muted pl-1">Причина відмови</small>
                                        <button className="question-button mr-2 mb-2" onClick={() => this.callUpdate(200, () => this.props.leadClose(200))}>Клієнт продав авто</button>
                                        <button className="question-button mr-2" onClick={() => this.callUpdate(201, () => this.props.leadClose(201))}>Неприйнятлива ціна</button>
                                        <button className="question-button mr-2" onClick={() => this.callUpdate(202, () => this.props.leadClose(202))}>Клієнт іншого дилера</button>
                                        <button className="question-button mr-2" onClick={() => this.callUpdate(203, () => this.props.leadClose(203))}>Причина невідома</button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {this.state.resultView == "delay" && (
                            <div className="mt-2">
                                <div className="row">
                                    <div className="col">
                                        <small class="form-text text-muted pl-1">Причина</small>
                                        <button className="question-button mr-2" style={this.state.resultSubView == "delayCallLater" ? {backgroundColor: "#dcdcdc"} : {}} onClick={() => this.setState({resultView: "delay", resultSubView: "delayCallLater"})}>Зателефонувати пізніше</button>
                                        <button className="question-button mr-2" onClick={() => this.callUpdate(301, () => {})}>Відмінити нагадування</button>
                                    </div>
                                </div>
                                {this.state.resultSubView == "delayCallLater" && (
                                    <div>
                                        <div className="row mt-1 mb-2">
                                            <div className="col-6">
                                                <small class="form-text text-muted pl-1">Планована дата дзвінку</small>
                                                <input type="datetime-local" className="input-agis-block w-100" style={{fontSize: "12px", backgroundColor: "#f2f2f2", color: "#6b6b6b"}} value={intToDateTimeFormat(this.props.callDate, "year-month-dayThour:minute")} onChange={this.props.callDateChange} />
                                            </div>
                                            <div className="col-6">
                                                <small class="form-text text-muted pl-1">Коментар</small>
                                                <input type="text" class="question-input" value={this.props.callRemark} onChange={this.props.callRemarkChange} />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div className="w-75">
                                                <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.props.callDateSet(900)}>За 15 хв</button>
                                                <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.props.callDateSet(3600)}>За 1 год</button>
                                                <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.props.callDateSet(86400)}>Завтра</button>
                                                <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.props.callDateSet(259200)}>За 3 дні</button>
                                                <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.props.callDateSet(604800)}>За тиждень</button>
                                                <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.props.callDateSet(1209600)}>За 2 тижні</button>
                                                <button className="question-button link mr-2" style={{fontSize: "80%"}} onClick={() => this.props.callDateSet(2419200)}>За місяць</button>
                                            </div>
                                            <button className="question-button" onClick={() => this.callUpdate(300, () => this.props.callCreate(this.props.callDate, this.props.callRemark))}>Відкласти</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
import React from 'react';
import AppContext from '../../AppContext.js';

class Submenu extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
        }
        
        this.children = React.createRef();
    }

    componentDidMount() {
        this.setState({isLoading: false});
    }

    render() {
        return (
            <div>
                <div ref={(e) => this.children = e} className="fixed-top" style={{top: "49px", zIndex: "2", borderTop: "1px solid rgba(0,0,0,.0)", boxShadow: "0 3px 6px 0 rgba(32,33,36,0.28)"}}>
                    {this.props.children}
                </div>

                <div style={{height: this.children.clientHeight}}>
                </div>
            </div>
        )
    }
}

export default Submenu;
import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faKey, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../../AppContext.js';

const localization = {
    ua: {
        table: {
            unit: 'Юніт',
            total: 'Всього запитів',
            requested: 'Платних запитів',
            details: 'Детальніше',
        }
    },
    ru: {
        table: {
            unit: 'Юнит',
            total: 'Всего запросов',
            requested: 'Платных запросов',
            details: 'Детальнее',
        }
    },
    en: {
        table: {
            unit: 'Unit',
            total: 'Total',
            requested: 'Paid requests',
            details: 'Details',
        }
    },
}

class Summary extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: false,
            redirect: null,

            list: [],
        }

        this.exportToCSV = this.exportToCSV.bind(this);
    }

    componentDidMount() {
        this.list();
    }

    componentDidUpdate(prevProps) {
        if (this.props.from !== prevProps.from || this.props.to !== prevProps.to) {
            this.list();
        }
    }

    list() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/crm/youcontrol/summary', {
                from: this.props.from,
                to: this.props.to,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then((res) => {
                this.setState({
                    isLoading: false,
                    list: res.data
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,

                list: [
                    {
                        unitID: 1,
                        name: "Scania Kyiv Makariv",
                        total: 10,
                        requested: 2,
                    },
                    {
                        unitID: 2,
                        name: "Scania Kyiv Brovary",
                        total: 10,
                        requested: null,
                    },
                    {
                        unitID: 3,
                        name: "Scania Lviv",
                        total: 10,
                        requested: 1,
                    },
                    {
                        unitID: 4,
                        name: "Scania Odesa",
                        total: 10,
                        requested: null,
                    },
                ]
            });
        }
    }

    exportToCSV() {
        const {lang} = this.context;

        var s = ";"
        var encodedUri = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(localization[lang].table.unit + s + localization[lang].table.total + s + localization[lang].table.requested + s + "\n" + this.state.list.map(i => i.name + s + i.total + s + (i.requested ? i.requested : "") + s).join("\n"));

        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "summary.csv");
        link.click();
    }

    renderTable() {
        const {lang} = this.context;

        if (this.state.list.length === 0) {
            return (
                <tr>
                    <td colSpan="4">{localization[lang].noData}</td>
                </tr>
            );
        } else {
            return this.state.list.map((item, i) => {
                return (
                    <tr style={{height: "46px"}} key={"row_" + item.unitID}>
                        <td class="align-middle pt-1 pb-1">{item.name}</td>
                        <td class="align-middle text-center pt-1 pb-1">{item.total}</td>
                        <td class="align-middle text-center pt-1 pb-1">{item.requested && (<b>{item.requested}</b>)}</td>
                        <td class="align-middle text-center pt-1 pb-1">
                            <Link to={"/crm/youcontrol/requests/" + item.unitID}>{localization[lang].table.details}</Link>
                        </td>
                    </tr>
                );
            }).concat((
                <tr style={{height: "46px", backgroundColor: "#f4f4f4"}} key={"row_summary"}>
                    <td class="align-middle pt-1 pb-1"></td>
                    <td class="align-middle text-center pt-1 pb-1"><b>{this.state.list.reduce((prev, cur) => prev + Number(cur.total), 0)}</b></td>
                    <td class="align-middle text-center pt-1 pb-1"><b>{this.state.list.reduce((prev, cur) => prev + Number(cur.requested), 0)}</b></td>
                    <td class="align-middle text-center pt-1 pb-1"></td>
                </tr>
            ));
        }
    }

    render() {
        const {lang} = this.context;

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-10 offset-1">
                            <div className="d-flex align-items-center justify-content-end mb-4">
                                <button onClick={this.exportToCSV} style={{
                                    userSelect: "none",
                                    border: "1px solid transparent",
                                    cursor: "pointer",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    maxWidth: "200px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: "center",
                                    textDecoration: "none",
                                    touchAction: "manipulation",
                                    whiteSpace: "nowrap",

                                    height: "32px",
                                    borderRadius: "3px",
                                    color: "#666",
                                    backgroundColor: "#ebebeb",
                                }}>Export to CSV</button>
                            </div>
                            
                            <table className="table table-bordered table-hover" style={{fontSize: "12px"}}>
                                <thead>
                                    <tr style={{height: "30px", backgroundColor: "#f4f4f4"}}>
                                        <th class="align-middle text-center">{localization[lang].table.unit}</th>
                                        <th class="align-middle text-center" style={{width: "150px"}}>{localization[lang].table.total}</th>
                                        <th class="align-middle text-center" style={{width: "150px"}}>{localization[lang].table.requested}</th>
                                        <th class="align-middle text-center" style={{width: "100px"}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderTable()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Summary;
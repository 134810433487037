import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faCog, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';

const localization = {
    ua: {
        title: 'Unit settings',
    },
    ru: {
        title: 'Unit settings',
    },
    en: {
        title: 'Unit settings',
    },
}

class Index extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
        }
    }

    componentDidMount() {
        const {lang, setNavigationTitle} = this.context;
        setNavigationTitle(localization[lang].title);

        this.init();
    }

    init() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/iam/units/list', {}, {
                headers: { Authorization: `Bearer ${token}` }
            }).then((res) => {
                this.setState({
                    isLoading: false,
                    units: res.data,
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                units: [{unitID: 1, name: "SCANIA Kiev"}],
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />
            </div>
        )
    }
}

export default Index;
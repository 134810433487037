import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
//import ReactPlayer from 'react-player'

import { MapInteraction, MapInteractionCSS } from 'react-map-interaction';

import AppContext from '../../AppContext.js';

const localization = {
    ua: {
        attachmentDelete: 'Видалити',
    },
    ru: {
        attachmentDelete: 'Удалить',
    },
    en: {
        attachmentDelete: 'Delete',
    },
    pl: {
        attachmentDelete: 'Usuń',
    },
}

class Preview extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            ext: null,

            value: {
                scale: 1,
                translation: { x: 0, y: 0 }
            },

            fixed: true,
        }

        this.img = React.createRef();
        this.fix = this.fix.bind(this);
    }

    componentDidMount() {
        var ext = this.props.src.split(".")[1];

        this.setState({
            isLoading: false, 
            ext: ext,
        });
    }

    imgLoaded() {
        const { scale, height, width } = this.context;

        var x = (width - this.img.current.width) / 2;
        var y = (height - this.img.current.height) / 2;

        this.setState({
            value: {
                scale: 1,
                translation: { 
                    x: scale != "xs" ? x : 0, 
                    y: scale != "xs" ? y > 0 ? y : 0 : y             
                }
            }
        }, () => {
            console.log(this.img.current.height);
            console.log(this.img.current.width);

            console.log(this.state.value.translation.x);
            console.log(this.state.value.translation.y);
        });
    }

    fix() {
        this.setState({fixed: !this.state.fixed});
    }

    centerize() {
        alert("kek");
        this.setState({
            value: {
                scale: 1,
                translation: { 
                    x: this.state.value.translation.x, 
                    y: this.state.value.translation.y             
                }
            }
        });
    }

    render() {
        const { lang, scale } = this.context;
        return (
            <div class="card" style={{cursor: "pointer"}}>
                <div className={this.state.fixed ? "d-none" : "fixed-bottom d-flex align-items-center justify-content-center"} style={{zIndex: 2000, height: "100%", backgroundColor: "#000000d4"}}>
                    <div className="fixed-top d-flex align-items-center justify-content-end pt-3 pr-3" style={{zIndex: "4"}}>
                        <span onClick={this.fix} style={{fontSize: "1rem", color: "#dcdcdc"}}>
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-times fa-w-10 fa-2x"><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" class=""></path></svg>
                        </span>
                    </div>

                    <MapInteractionCSS className="kek" value={this.state.value} onChange={(value) => this.setState({value})} onDoubleClick={this.centerize.bind(this)}>
                        <img ref={scale != "xs" && this.img} src={this.props.src} onLoad={this.imgLoaded.bind(this)}  className="card-img" alt="..." />
                    </MapInteractionCSS>
                </div>

                <img ref={scale == "xs" && this.img} onClick={this.fix} src={this.props.src} class="card-img" alt="..."/>

                {this.props.removeFile != null && (
                    <div style={{zIndex: "1", position: "absolute", left: "0px", top: "0px", right: "0px", paddingTop: "5px", paddingRight: "5px", height: "50px", textAlign: "right"}}>
                        <button className="btn-agis-hover" style={{height: "20px", fontSize: "10px"}} onClick={this.props.removeFile}>{localization[lang].attachmentDelete} <FontAwesomeIcon icon={faTimes} className="ml-1" style={{fontSize: "8px"}}/></button>
                    </div>
                )}
            </div>
        )
    }
}

export default Preview;
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, Link, NavLink } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faCog, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'

import AppContext from '../../../AppContext.js';

const localization = {
    ua: {
        title: 'Користувачі',
        table: {
            name: 'Назва',
            phone: 'Телефон',
            email: 'Пошта',
            recoveryEmail: 'Пошта',
            details: 'Детальніше',
        },
    },
    ru: {
        title: 'Пользователи',
        table: {
            name: 'Название',
            phone: 'Телефон',
            email: 'Почта',
            recoveryEmail: 'Почта',
            details: 'Детальнее',

        },
    },
    en: {
        title: 'Users',
        table: {
            name: 'Name',
            phone: 'Phone',
            email: 'Email',
            recoveryEmail: 'Recovery email',
            details: 'Details',
        },
    },
}

class Index extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            isLoading: true,
        }
    }

    componentDidMount() {
        const {lang, setNavigationTitle} = this.context;
        setNavigationTitle(localization[lang].title);

        this.init();
    }

    init() {
        const {lang, token, addNotification, removeNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/iam/users/list', {}, {
                headers: { Authorization: `Bearer ${token}` }
            }).then((res) => {
                this.setState({
                    isLoading: false,
                    users: res.data,
                });
            }).catch((error) => {
                addNotification(error.response.data, error.response.status);
            }).finally(() => {
                removeNotification(u);
            });
        } else {
            this.setState({
                isLoading: false,
                users: [
                    {
                        userID: 1,
                        name: "Admin",
                        phone: "380508673516",
                        email: "evgeniybutenko@gmail.com",
                        recoveryEmail: null,
                    }
                ],
            });
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        if (this.state.isLoading) {
            return (
                <div>Loading...</div>
            );
        }

        return (
            <div>
                <DocumentMeta {...meta} />

                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-10 offset-1">
                            <table className="table table-bordered table-hover" style={{fontSize: "12px"}}>
                                <thead>
                                    <tr style={{height: "30px", backgroundColor: "#f4f4f4"}}>
                                        <th class="align-middle text-center">{localization[lang].table.name}</th>
                                        <th class="align-middle text-center" style={{width: "160px"}}>{localization[lang].table.phone}</th>
                                        <th class="align-middle text-center" style={{width: "250px"}}>{localization[lang].table.email}</th>
                                        <th class="align-middle text-center" style={{width: "250px"}}>{localization[lang].table.recoveryEmail}</th>
                                        <th style={{width: "100px"}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.users.map((item) => {
                                        return (
                                            <tr style={{height: "46px"}} key={"row_" + item.userID}>
                                                <td class="align-middle pt-1 pb-1">{item.name}</td>
                                                <td class="align-middle pt-1 pb-1">{item.phone}</td>
                                                <td class="align-middle pt-1 pb-1">{item.email}</td>
                                                <td class="align-middle pt-1 pb-1">{item.recoveryEmail}</td>
                                                {/* <td class="align-middle text-center pt-1 pb-1"><Link to={"/iam/user/" + item.userID + "/settings"}>{localization[lang].table.details}</Link></td> */}
                                                <td class="align-middle text-center pt-1 pb-1">{localization[lang].table.details}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Index;
import React from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import DocumentMeta from 'react-document-meta';

import AppContext from './AppContext';

const localization = {
    ua: {
        title: 'Авторизацiя',
        loading: "Завантаження...",
        login: 'E-mail (логiн)',
        password: 'Пароль',
        buttonText: 'Увiйти'
    },
    ru: {
        title: 'Авторизация',
        loading: "Загрузка...",
        login: 'E-mail (логин)',
        password: 'Пароль',
        buttonText: 'Войти'
    },
    pl: {
        title: 'Logowanie',
        loading: "Ładowanie...",
        login: 'E-mail (login)',
        password: 'Haslo',
        buttonText: 'Zaloguj'
    },
    en: {
        title: 'Login',
        loading: "Loading...",
        login: 'E-mail (login)',
        password: 'Password',
        buttonText: 'Login'
    },
}

class Login extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
		
		this.state = {
            username: "",
            password: "",
        }
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount() {
        const {lang} = this.context;
        //setMenuExpandText(localization[lang].title);
    }

    handleInputChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.login();
        }
    }

    loginClick() {
        this.login();
    }

    login() {
        const {addNotification, removeNotification, authLogin, runNotification} = this.context;

        if (process.env.NODE_ENV === 'production') {
            var u = addNotification();

            axios.post('/api/iam/auth', { 
                login: this.state.username,
                password: this.state.password
            }).then((res) => {
                authLogin(res.data.token, res.data.name, "ua");
                //runNotification();
            }).catch((error) => {
                //addNotification(error.response.data, error.response.status);
                console.log(error);
            }).finally(function () {
                removeNotification(u);
            });
        } else {
            authLogin("token", "Test build", "en");
            //runNotification();
        }
    }

    render() {
        const {lang} = this.context;

        const meta = {
			title: localization[lang].title,
			description: localization[lang].title,
			meta: {
			  	charset: 'utf-8'
			}
        };

        return (
            <div className="h-100 w-100" style={{position: "absolute", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#f1f3f4",
                //background: "url(https://media.scania.com/fotoweb/cache/5016/Mediaprovider_A/2016/161/16142/16142-035.t57bd9bba.m800.tif.pv.xJKhTcA7f.jpg) no-repeat center center fixed",
                backgroundSize: "cover"
            }}
            >
                <DocumentMeta {...meta} />
            
                <div className="container-fluid" style={{paddingBottom: "10%"}}>
                    <div className="row mt-4">
                        <div className="col-12 col-sm-8 col-md-4 offset-md-4">
                            <div className="card agis-card p-2 pl-3 pr-3">
                                <div className="row mt-2 mb-2">
                                    <div className="col">
                                        <img src="/serve/configuration/logo.png" style={{height: "30px"}} alt="logo" />
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col">
                                        <input name="username" type="text" autocomplete="username" className="input-agis-block" style={{width: '100%'}} placeholder={localization[lang].login} value={this.state.username} onChange={this.handleInputChange} onKeyPress={this.handleKeyPress} />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col">
                                        <input name="password" type="password" className="input-agis-block" style={{width: '100%'}} placeholder={localization[lang].password} value={this.state.password} onChange={this.handleInputChange} onKeyPress={this.handleKeyPress} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col d-flex justify-content-between">
                                        {/* <Link to={"/restore"} class="btn btn-agis-link pl-0">{localization[lang].linkText}</Link> */}
                                        <span></span>
                                        <button className="btn-agis-primary" onClick={this.loginClick.bind(this)}>{localization[lang].buttonText}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;